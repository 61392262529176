import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  FormInput,
  FormGroup,
  FormCheckbox,
  FormSelect,
  Button,
} from 'shards-react'

const PlanningTime = ({ onChangeCustom, field, data }) => {
  const { t } = useTranslation()
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  const [noHour, setNoHour] = useState(false)
  const [defaultNoHour, setDefaultNoHour] = useState(false)
  const [isFirstChange, setIsFirstChange] = useState(false)

  useEffect(() => {
    if (data[field.id]?.timeStartH === '00' && data[field.id]?.timeStartM === '00' && data[field.id]?.timeEndH === '00' && data[field.id]?.timeEndM === '00' && !defaultNoHour) {
      setNoHour(true)
      setDefaultNoHour(true)
    }
  }, [data])

  useEffect(() => {
    if (noHour && !isFirstChange && defaultNoHour) {
      setNoHour(false)
      setIsFirstChange(true)
    }
  }, [data])

  const ieme = (day) => {
    switch (day) {
      case 1:
        return t('planning.repeat.ieme.first')
      case 2:
        return t('planning.repeat.ieme.second')
      case 3:
        return t('planning.repeat.ieme.third')
      default:
        return t('planning.repeat.ieme.default')
    }
  }

  return (
    <div className="category-container">
      {data.availabilityMode !== 'PERMANENT' && (
        <FormGroup>
          <label htmlFor="feStart">
            {t('planning.timeSlot.title')}
            <span> *</span>
          </label>
          {(!data[field.id]?.timeSlot ||
            data[field.id]?.timeSlot.length === 0 ||
            data[field.id]?.timeSlot.filter(
              (slot) => slot.availabilityMode === data.availabilityMode,
            ).length === 0
          ) ? (
            <p>{t('planning.timeSlot.empty')}</p>
          ) : (
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">{t('planning.timeSlot.start')}</th>
                  <th scope="col" className="border-0">{t('planning.timeSlot.end')}</th>
                  {data.availabilityMode === 'PERIODICAL' && (
                    <th scope="col" className="border-0">{t('planning.timeSlot.repeat')}</th>
                  )}
                  <th scope="col" className="border-0">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data[field.id]?.timeSlot.map((time, index) => {
                  if (time.type === 'removed' || time.availabilityMode !== data.availabilityMode) {
                    return (<></>)
                  }
                  return (
                    <tr key={`time${index}`}>
                      <td>{dayjs(time.start).format('DD/MM/YYYY HH:mm')}</td>
                      <td>{dayjs(time.end).format('DD/MM/YYYY HH:mm')}</td>
                      {data.availabilityMode === 'PERIODICAL' && (
                        <td>
                          {time.repeat_unit === 'day' && (
                            <span>
                              {time.repeat_value}
                              {t(time.repeat_value > 1 ? 'planning.repeat.table.days' : 'planning.repeat.table.day')}
                            </span>
                          )}
                          {time.repeat_unit === 'week' && (
                            <span>
                              {time.repeat_value}
                              {t(time.repeat_value > 1 ? 'planning.repeat.table.weeks' : 'planning.repeat.table.week')}
                              <br />
                              {Array.from(time.repeat, (sem) => t(`planning.repeat.table.${sem}`)).join(', ')}
                            </span>
                          )}
                          {time.repeat_unit === 'month' && (
                            <span>
                              {time.repeat_value}
                              {t(time.repeat_value > 1 ? 'planning.repeat.table.months' : 'planning.repeat.table.month')}
                              <br />
                              {time.repeat_parameter_value}
                              {ieme(time.repeat_parameter_value)}
                              {' '}
                              {t(`planning.repeat.weeks.${time.repeat_parameter}`)}
                            </span>
                          )}
                          {time.repeat_unit === 'year' && (
                            <span>
                              {time.repeat_value}
                              {t(time.repeat_value > 1 ? 'planning.repeat.table.years' : 'planning.repeat.table.year')}
                            </span>
                          )}
                        </td>
                      )}
                      <td style={{ width: 'calc(100px + 1.5rem)' }}>
                        <Button
                          style={{ width: '100px', backgroundColor: '#32A0E6', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none' }}
                          onClick={() => {
                            const { timeSlot } = data[field.id]
                            timeSlot[index].type = 'removed'

                            onChangeCustom(field.id, {
                              ...data[field.id],
                              timeSlot: [
                                ...timeSlot,
                              ],
                            })
                          }}
                        >
                          {t('planning.label.removeTime')}
                        </Button>
                      </td>
                    </tr>
                  )
              })}
              </tbody>
            </table>
          )}
        </FormGroup>
      )}
      {data.availabilityMode === 'PERIODICAL' && (
        <>
          <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
            <label
              htmlFor="feRepeatValue"
              style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '10px' }}
            >
              {t('planning.repeat.title')}
              <span> *</span>
            </label>
            <FormInput
              id="feRepeatValue"
              type="text"
              value={data[field.id]?.repeat_value}
              valid={data[field.id] && data[field.id].repeat_value && data[field.id].repeat_value !== ''}
              invalid={data[field.id] && data[field.id]?.repeat_value === ''}
              onChange={((event) => {
                onChangeCustom(field.id, {
                  ...data[field.id],
                  repeat_value: event.target.value,
                })
              })}
              style={{ marginRight: '10px' }}
            />
            <FormSelect
              id="feRepeatUnit"
              value={data[field.id]?.repeat_unit}
              onChange={(event) => {
                onChangeCustom(field.id, {
                  ...data[field.id],
                  repeat_unit: event.target.value,
                })
              }}
            >
              <option value="day">{t('planning.repeat.day')}</option>
              <option value="week">{t('planning.repeat.week')}</option>
              <option value="month">{t('planning.repeat.month')}</option>
              <option value="year">{t('planning.repeat.year')}</option>
            </FormSelect>
          </div>
          {data[field.id]?.repeat_unit === 'week' && (
            <FormGroup>
              <label htmlFor="dede">
                {t('planning.repeat.weekDay')}
                <span> *</span>
              </label>
              <div style={{ display: 'flex' }}>
                {days.map((day, index) => (
                  <Button
                    key={`day${index}`}
                    style={{
                      backgroundColor: data[field.id] && data[field.id].repeat && data[field.id].repeat.indexOf(day) !== -1 ? '#32A0E6' : 'grey',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: 'none',
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      marginRight: '5px',
                    }}
                    className={field.className}
                    onClick={() => {
                      const repeat = data[field.id]?.repeat || []
                      const idx = repeat.indexOf(day)
                      if (idx === -1) {
                        repeat.push(day)
                      } else {
                        repeat.splice(idx, 1)
                      }
                      onChangeCustom(field.id, {
                        ...data[field.id],
                        repeat: [...repeat],
                      })
                    }}
                  >
                    {t(`planning.repeat.days.${day}`)}
                  </Button>
                ))}
              </div>
            </FormGroup>
          )}
          {data[field.id]?.repeat_unit === 'month' && (
            <FormGroup>
              <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
                <label
                  htmlFor="feRepeatParameterValue"
                  style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '10px' }}
                >
                  {t('planning.repeat.monthInfo')}
                  <span> *</span>
                </label>
                <FormSelect
                  id="feRepeatParameterValue"
                  value={data[field.id]?.repeat_parameter_value}
                  onChange={(event) => {
                    onChangeCustom(field.id, {
                      ...data[field.id],
                      repeat_parameter_value: event.target.value,
                    })
                  }}
                >
                  {Array.from(Array((!data[field.id].repeat_parameter || data[field.id]?.repeat_parameter === 'day') ? 31 : 5))
                    .map((d, index) => (
                      <option key={`monthInfoCount${index + 1}`} value={index + 1}>
                        {index + 1}
                        {ieme(index + 1)}
                      </option>
                    ))}
                </FormSelect>
                <FormSelect
                  id="feRepeatParameterValue"
                  value={data[field.id]?.repeat_parameter}
                  onChange={(event) => {
                    onChangeCustom(field.id, {
                      ...data[field.id],
                      repeat_parameter: event.target.value,
                    })
                  }}
                >
                  <option value="day">{t('planning.repeat.weeks.day')}</option>
                  {days.map((day, index) => (
                    <option key={`monthInfo${index}`} value={day}>{t(`planning.repeat.weeks.${day}`)}</option>
                  ))}
                </FormSelect>
              </div>
            </FormGroup>
          )}
        </>
      )}
      <FormGroup>
        <label htmlFor="feStart">
          {t('planning.label.start')}
          <span> *</span>
        </label>
        <DatePicker
          selected={data[field.id]?.start}
          timeInputLabel={t('ui.timeInputLabel')}
          dateFormat={data.availabilityMode === 'TIMERANGE' ? t('ui.dateTimeFormat') : t('ui.dateFormat')}
          showTimeInput={data.availabilityMode === 'TIMERANGE'}
          className={`form-control ${data[field.id] && data[field.id]?.start === '' ? 'is-invalid' : ''}`}
          placeholderText={t('planning.placeholder.start')}
          onChange={(sd) => {
            onChangeCustom(field.id, {
              ...data[field.id],
              start: sd || '',
            })
          }}
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="feEnd">
          {t('planning.label.end')}
          <span> *</span>
        </label>
        <DatePicker
          selected={data[field.id]?.end}
          timeInputLabel={t('ui.timeInputLabel')}
          dateFormat={data.availabilityMode === 'TIMERANGE' ? t('ui.dateTimeFormat') : t('ui.dateFormat')}
          showTimeInput={data.availabilityMode === 'TIMERANGE'}
          className={`form-control ${data[field.id] && data[field.id]?.end === '' ? 'is-invalid' : ''}`}
          placeholderText={t('planning.placeholder.end')}
          onChange={(sd) => {
            onChangeCustom(field.id, {
              ...data[field.id],
              end: sd || '',
            })
          }}
        />
      </FormGroup>
      {data.availabilityMode !== 'TIMERANGE' && (
        <FormGroup>
          <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
            <div style={{ whiteSpace: 'nowrap', marginRight: '50px', zIndex: 0, marginTop: 'auto' }}>
              <FormCheckbox
                checked={noHour}
                value={noHour}
                onChange={(() => {
                  setNoHour(!noHour)
                  onChangeCustom(field.id, {
                    ...data[field.id],
                    noHour: !noHour,
                  })
                })}
              >
                {t('planning.repeat.time.noHour')}
              </FormCheckbox>
            </div>

            {!noHour && (
              <>
                <label
                  htmlFor="feTimeStartHour"
                  style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '10px' }}
                >
                  {t('planning.repeat.time.startH')}
                </label>
                <FormInput
                  id="feTimeStartHour"
                  type="text"
                  value={data[field.id]?.timeStartH}
                  valid={(data[field.id] && data[field.id].timeStartH && data[field.id].timeStartH !== '') || noHour}
                  invalid={data[field.id] && data[field.id]?.timeStartH === '' && !noHour}
                  onChange={((event) => {
                    onChangeCustom(field.id, {
                      ...data[field.id],
                      timeStartH: event.target.value,
                    })
                  })}
                  style={{ marginRight: '10px', width: '100px' }}
                />
                <label
                  htmlFor="feTimeStartMin"
                  style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '10px' }}
                >
                  {t('planning.repeat.time.startM')}
                </label>
                <FormInput
                  id="feTimeStartMin"
                  type="text"
                  value={data[field.id]?.timeStartM}
                  valid={(data[field.id] && data[field.id].timeStartM && data[field.id].timeStartM !== '') || noHour}
                  invalid={data[field.id] && data[field.id]?.timeStartM === '' && !noHour}
                  onChange={((event) => {
                    onChangeCustom(field.id, {
                      ...data[field.id],
                      timeStartM: event.target.value,
                    })
                  })}
                  style={{ marginRight: '10px', width: '100px' }}
                />
                <label
                  htmlFor="feTimeEndHour"
                  style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '35px', marginLeft: '30px' }}
                >
                  {t('planning.repeat.time.endH')}
                </label>
                <FormInput
                  id="feTimeEndHour"
                  type="text"
                  value={data[field.id]?.timeEndH}
                  valid={(data[field.id] && data[field.id].timeEndH && data[field.id].timeEndH !== '') || noHour}
                  invalid={data[field.id] && data[field.id]?.timeEndH === '' && !noHour}
                  onChange={((event) => {
                    onChangeCustom(field.id, {
                      ...data[field.id],
                      timeEndH: event.target.value,
                    })
                  })}
                  style={{ marginRight: '10px', width: '100px' }}
                />
                <label
                  htmlFor="feTimeEndMin"
                  style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '10px' }}
                >
                  {t('planning.repeat.time.endM')}
                </label>
                <FormInput
                  id="feTimeEndMin"
                  type="text"
                  value={data[field.id]?.timeEndM}
                  valid={(data[field.id] && data[field.id].timeEndM && data[field.id].timeEndM !== '') || noHour}
                  invalid={data[field.id] && data[field.id]?.timeEndM === '' && !noHour}
                  onChange={((event) => {
                    onChangeCustom(field.id, {
                      ...data[field.id],
                      timeEndM: event.target.value,
                    })
                  })}
                  style={{ marginRight: '10px', width: '100px' }}
                />

              </>

            )}
          </div>
          {!data[field.id]?.noHour && data[field.id] && (
            !data[field.id].timeStartH ||
            !data[field.id].timeStartM ||
            !data[field.id].timeEndH ||
            !data[field.id].timeEndM)
          && (
          <p style={{ fontSize: '80%', color: '#c4183c' }}>
            {t('planning.error.errorTimeSelection')}
          </p>
          )}

        </FormGroup>
      )}
      {data.availabilityMode !== 'PERMANENT' && (
        <FormGroup>
          <Button
            style={{ width: '175px', backgroundColor: '#32A0E6', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none' }}
            disabled={!(data[field.id] && data[field.id].start && data[field.id].start !== '' && data[field.id].end && data[field.id].end !== '')}
            className={field.className}
            onClick={() => {
              const timeSlot = data[field.id].timeSlot ? data[field.id].timeSlot : []

              let { start, end } = data[field.id]
              if (data.availabilityMode !== 'TIMERANGE') {
                start = `${dayjs(data[field.id].start).format('YYYY-MM-DD')} ${data[field.id].timeStartH}:${data[field.id].timeStartM}:00+01:00`
                end = `${dayjs(data[field.id].end).format('YYYY-MM-DD')} ${data[field.id].timeEndH}:${data[field.id].timeEndM}:00+01:00`
              }

              onChangeCustom(field.id, {
                ...data[field.id],
                timeSlot: [
                  ...timeSlot,
                  {
                    start,
                    end,
                    repeat_value: data[field.id].repeat_value,
                    repeat_unit: data[field.id].repeat_unit || 'day',
                    repeat: data[field.id].repeat,
                    repeat_parameter_value: data[field.id].repeat_parameter_value || 1,
                    repeat_parameter: data[field.id].repeat_parameter || 'day',
                    type: 'added',
                    availabilityMode: data.availabilityMode,
                  },
                ],
                start: null,
                end: null,
                repeat_value: '',
                repeat_unit: '',
                repeat: '',
                repeat_parameter_value: '',
                repeat_parameter: '',
                timeStartH: '',
                timeStartM: '',
                timeEndH: '',
                timeEndM: '',
              })
            }}
          >
            {t('planning.label.addTime')}
          </Button>
        </FormGroup>
      )}
    </div>
  )
}

PlanningTime.propTypes = {
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
}

PlanningTime.defaultProps = {
  onChangeCustom: () => {},
}

export default PlanningTime
