import React, { useState, useEffect } from 'react'
import { FormInput, FormSelect, Row, Col } from 'shards-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Residences } from '../../../gql'
import config from '../../../config'
import { captureMessageWithAttachment } from '../../../lib/sentry'

const InputsCity = ({ data, onChangeCustom, field }) => {
  const { t } = useTranslation()
  const [city, setCity] = useState(data[field.id]?.city)
  const [cp, setCp] = useState(data[field.id]?.cp)
  const [idCity, setIdCity] = useState(data[field.id]?.idCity)
  const [num, setNum] = useState(data[field.id]?.num)
  const [results, setResults] = useState([])

  const [fetchCity, { data: resultsData }] = useLazyQuery(Residences.getCityByCode(), {
    fetchPolicy: config.fetchPolicy,
    notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
    variables: {
      cp,
    },
    onError: (err) => {
      const request = Residences.getCityByCode()
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: { cp },
      }
      captureMessageWithAttachment(event, err)
    },
    onCompleted: () => {
      if (resultsData.cities.nodes[0]) {
        const resul = resultsData.cities.nodes
        setResults(resul)
        setIdCity(resul[0].id)
        setCity(resul[0].name)
        setNum(resul[0].departmentByIdDepartment.num)
        onChangeCustom(field.id, {
          idCity: resul[0].id,
          cp,
          city: resul[0].name,
          num: resul[0].departmentByIdDepartment.num,
        })
      }
    },
  })

  useEffect(() => {
    if (data[field.id]?.idCity !== idCity) {
      const choice = results.find((item) => item.id === idCity)
      setCity(choice?.name)
      onChangeCustom(field.id, {
        idCity,
        cp,
        city: choice.name,
        num,
      })
    }
  }, [idCity])

  useEffect(() => {
    if (data.cityInputs) {
      setCity(data[field.id]?.city)
      setCp(data[field.id]?.cp)
      setIdCity(data[field.id]?.idCity)
      setNum(data[field.id]?.num)
    }
  }, data[field.id])

  return (
    <Row>
      <Col className="form-group col-md-3">
        <label className="font-weight-bold" htmlFor="cp">{t('residences.label.cp')}</label>
        <FormInput
          value={cp}
          type="number"
          onChange={(e) => {
            setCp(e.target.value)
            if (cp !== '') {
              fetchCity()
            }
          }}
          id="cp"
          placeholder={t('residences.placeholder.cp')}
          style={{
            paddingLeft: 20,
          }}
        />
      </Col>
      <Col className="col-md-3">
        <label className="font-weight-bold" htmlFor="city">{t('residences.label.city')}</label>
        <FormSelect
          value={idCity}
          onChange={(e) => {
            setIdCity(e.target.value)
          }}
          id="city"
          placeholder={t('residences.placeholder.city')}
          style={{
            paddingLeft: 20,
          }}
        >
          {results && results[0] ? results.map((r) => (
            <option selected={idCity === r.id} key={r.id} value={r.id}>{r.name}</option>
          )) : (
            <option selected>{city || `${t('residences.placeholder.city')}`}</option>
          )}
        </FormSelect>
      </Col>
      <Col className="p-0 pt-3" style={{ display: 'flex', alignItems: 'center' }}>
        {num ? (
          <p className="m-0 p-0">
            {
          `(
            ${num}
          )`
        }
          </p>
        ) : (
          <p className="m-0 p-0">
            (N°dept)
          </p>
        )}
      </Col>
    </Row>
  )
}

InputsCity.propTypes = {
  data: PropTypes.shape(),
  onChangeCustom: PropTypes.func.isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
}

InputsCity.defaultProps = {
  data: {},
}

export default InputsCity
