import React, { useState, useEffect } from 'react'
import { FormInput, Col, Row } from 'shards-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import config from '../../../config'
import { captureMessageWithAttachment } from '../../../lib/sentry'

import { DataListAdd } from '.'

const AdminByResidence = ({ onChangeCustom, field, data }) => {
  const { t } = useTranslation()
  const [admin, setAdmin] = useState(data[field.id] ? data[field.id] : [])
  const [results, setResults] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [coordinatorsChecked, setCoordinatorsChecked] = useState([])

  useEffect(() => {
    if (data[field.id] && data[field.id][0]) {
      setAdmin(data[field.id])
    }
  }, data[field.id])

  const styles = {
    border: '1px solid #e1e5eb',
    height: '40px',
    marginTop: 5,
    padding: '0 10px',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 400,
  }

  const [fetchNewResults, { data: dataResults }] = useLazyQuery(field.query, {
    fetchPolicy: config.fetchPolicy,
    onError: (err) => {
      const request = field.query
      const event = {
        request: request?.definitions[0]?.name?.value,
      }
      captureMessageWithAttachment(event, err)
    },
    onCompleted: () => {
      const newResults = []
      if (dataResults.staffs.nodes.length > 0) {
        dataResults.staffs.nodes.forEach((one) => {
          if (!admin.find((user) => user.id === one.id && user.status !== 'deleted')) {
            newResults.push(one)
          }
        })
      }
      setResults(newResults)
    },
  })

  const deleteItem = (u) => {
    let newAdmin = []
    if (field.id === 'coordinators') {
      newAdmin = [...admin.map((adm) => ({ ...adm }))]
      const userToRemove = u === 'multi' ? coordinatorsChecked : [u]

      newAdmin.forEach((user) => {
        userToRemove.forEach((utr) => {
          if (user.id === utr.id) {
            const usr = user
            usr.status = 'deleted'
          }
        })
      })
    }
    setCoordinatorsChecked([])
    setAdmin(newAdmin)
    onChangeCustom(field.id, newAdmin)
  }

  const addItem = (user) => {
    setResults([])
    setSearchValue('')
    if (admin.indexOf(user) === -1) {
      let newAdmin = [{
        ...user,
        status: 'added',
      }]
      if (field.id === 'coordinators') {
        newAdmin = [
          ...admin,
          {
            ...user,
            status: 'added',
          },
        ]
      }
      setAdmin(newAdmin)
      onChangeCustom(field.id, newAdmin)
    }
  }

  const changeResult = () => {
    if (searchValue !== '') {
      fetchNewResults({
        variables: {
          term: `%${searchValue}%`,
        },
      })
    } else {
      setResults([])
    }
  }

  useEffect(() => {
    if (searchValue !== '') {
      changeResult(searchValue)
    }
  }, [searchValue])

  return (
    <Row>
      <Col className="form-group col-md-8" style={{ position: 'relative' }}>
        <i className="fas fa-search" style={{ position: 'absolute', left: '25px', zIndex: 2, top: '11px' }} />
        <FormInput
          list={field.id}
          multiple
          value={searchValue}
          placeholder={t(`${field.placeholder}`)}
          style={{
            paddingLeft: 30,
          }}
          onChange={(e) => {
            setSearchValue(e.target.value)
            if (e.target.value === '') {
              setResults([])
            }
          }}
        />
        {results && results.length > 0 && (
          <DataListAdd
            resultsSearch={results}
            addItem={addItem}
          />
        )}

        {admin && admin?.map((user) => {
          if (user.status === 'deleted') {
            return (<></>)
          }

          return (
            <div className="d-flex" style={{ ...styles }} key={user.id}>
              {field.id !== 'manager' && (
              <input
                type="checkbox"
                className="mr-2"
                onClick={() => {
                  const index = coordinatorsChecked.indexOf(user)
                  if (index === -1) {
                    setCoordinatorsChecked([...coordinatorsChecked, user])
                  } else {
                    const array = [...coordinatorsChecked]
                    array.splice(index, 1)
                    setCoordinatorsChecked([...array])
                  }
                }}
              />
            )}
              <div className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ margin: 0 }}>{`${user.firstname} ${user.lastname}`}</p>
                <button
                  onClick={() => deleteItem(user)}
                  style={{ border: 'none', backgroundColor: 'transparent', width: '40px', height: '40px' }}
                  type="button"
                >
                  <i className="material-icons">delete</i>
                </button>
              </div>
            </div>
          )
        })}
        {field.id !== 'manager' && admin[0] && (
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <button
              type="button"
              onClick={() => deleteItem('multi')}
              style={{ border: 'none', backgroundColor: 'transparent', marginRight: '15px' }}
            >
              <span style={{ textDecoration: 'underline', fontSize: '0.8rem' }}>
                {t('ui.batchActions.delete')}
                <span>{`(${coordinatorsChecked.length})`}</span>
              </span>
            </button>
          </div>
        )}
      </Col>
    </Row>
  )
}

AdminByResidence.propTypes = {
  data: PropTypes.shape().isRequired,
  onChangeCustom: PropTypes.func.isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AdminByResidence
