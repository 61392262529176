import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'shards-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import api from '../../../api'

const Photos = ({
  onChangeCustom,
  field,
  indications,
  wording,
  read = false,
}) => {
  const inputFile = useRef(null)
  const [hasImage, setHasImage] = useState(false)
  const [fileLoaded, setFileLoaded] = useState()
  const { t } = useTranslation()
  const params = useParams()
  const { id } = params

  useEffect(async () => {
    try {
      if (id) {
        await api.checkFile(field.url(params, false))
        setHasImage(true)
      }
    } catch (e) {
      console.log('no image found')
    }
  }, [])

  const loadFile = (e) => {
    const selectedFile = e.target.files[0]
    if (!selectedFile.type.startsWith('image/')) {
      return
    }

    const preview = document.getElementById('preview')

    const img = document.createElement('img')
    img.file = selectedFile
    preview.appendChild(img)

    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.onload = (event) => {
      setFileLoaded(event.target.result)
      onChangeCustom(field.id, {
        img: event.target.result,
        type: e.target.files[0].type,
      })
    }
    reader.readAsDataURL(selectedFile)
  }

  return (
    <div className="photos-container">
      {hasImage && !fileLoaded && (
        <div className="photo">
          <img src={field.url(params)} alt="" height="250" />
          {field.deletable && (
            <>
              <br />
              <Button
                theme="primary"
                className="mb-2 mr-1"
                onClick={() => {
                  onChangeCustom(field.id, 'delete')
                  setHasImage(false)
                }}
              >
                {t('ui.deleteImage')}
              </Button>
            </>
          )}
        </div>
      )}
      {fileLoaded && (
        <div className="photo">
          <img src={fileLoaded} alt="" height="250" />
          <br />
          <Button
            theme="primary"
            className="mb-2 mr-1"
            onClick={() => {
              setFileLoaded(null)
            }}
          >
            {t('ui.deleteImage')}
          </Button>
        </div>
      )}
      {!read && (
        <Button
          theme="primary"
          className="mb-2 mr-1"
          onClick={() => {
            inputFile.current.click()
          }}
        >
          {wording ? t(wording) : t('ui.updateImage')}
        </Button>
      )}
      {indications !== '' && <p>{t(indications)}</p>}
      <input
        type="file"
        id="file"
        accept="image/png, image/jpeg"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={loadFile}
      />
      <div id="preview" />
    </div>
  )
}

Photos.propTypes = {
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  indications: PropTypes.string,
  wording: PropTypes.string,
  read: PropTypes.bool,
}

Photos.defaultProps = {
  onChangeCustom: () => {},
  indications: '',
  wording: '',
  read: false,
}

export default Photos
