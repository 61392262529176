export const Rates = {
  populateForm: (data) => {
    const cat = []
    const formatter = Intl.NumberFormat('fr-FR', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    })
    let rateLabel
    data.vatRates.nodes.forEach((rate) => {
      rateLabel = formatter.format(rate.rate)
      cat.push({ value: rate.id, label: rateLabel })
    })
    return cat
  },
}
