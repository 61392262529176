/* eslint-disable react/display-name */
import { Ressources } from '../gql'

export const RessourcesForResidence = {
  graphql: true,
  list: {
    table: [
      {
        head: 'ressources.label.title',
        key: 'name',
        width: '100%',
      },
    ],
  },
  update: {
    title: 'residences.services.update',
    url: (id, RessourceId, params, props) => `/ressource-management/${props.res}/edit/${RessourceId}`,
    getLink: (item) => item.id,
  },
  delete: {
    text: 'ressources.delete.confirm',
    query: Ressources.deleteResource,
    getTitle: (item) => `${item.name}`,
    errorMessage: 'ressources.delete.error',
  },
}
