import React from 'react'
import AutomatedModalBatchDelete from '../components/automated/custom/AutomatedModalBatchDelete'
import { UsersExternal } from '../gql'
import config from '../config'

const idRole = config.ROLES_ID.logifim_external
const { LIMIT } = config

const form = (create) => [
  {
    type: 'row',
    items: [
      {
        md: 6,
        id: 'lastname',
        type: 'text',
        placeholder: 'clients.lastname',
        label: 'clients.lastname',
        required: true,
      },
      {
        md: 6,
        id: 'firstname',
        type: 'text',
        placeholder: 'clients.firstname',
        label: 'clients.firstname',
        required: true,
      },
    ],
  },
  {
    type: 'row',
    items: [
      {
        id: 'mobilePhoneNumber',
        type: 'tel',
        placeholder: 'clients.placeholder.phoneNumber',
        label: 'clients.label.phoneNumber',
        regex: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/i,
      },
      {
        id: 'email',
        type: 'email',
        placeholder: 'clients.placeholder.email',
        label: 'clients.label.email',
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    ],
  },
  {
    id: 'password',
    type: 'password',
    label: 'clients.label.password',
    placeholder: 'clients.placeholder.password',
    required: create,
  },
  {
    type: 'row',
    right: true,
    items: [
      {
        type: 'back',
        label: 'ui.cancel',
        md: 2,
      },
      {
        type: 'submit',
        label: 'residences.label.submit',
        md: 2,
      },
    ],
  },
]

export const Externals = {
  graphql: true,
  title: 'clients.title.externals',
  query: UsersExternal.getExternalById,
  transformQuery: (items) => items.external.lastname,
  url: '/externes',
  subtitle: '',
  list: {
    table: [
      {
          head: 'clients.firstname',
          key: 'firstname',
          width: '50%',
      },
      {
          head: 'clients.lastname',
          key: 'lastname',
          width: '50%',
      },
    ],
    batchActions: {
      title: 'ui.batchActions.title',
      items: [
        {
          label: 'ui.batchActions.delete',
          title: 'clients.delete.externals',
          component: (props) => (<AutomatedModalBatchDelete {...props} />),
        },
      ],
    },
    query: UsersExternal.fetchExternals,
    variables: (
      limit,
      offset,
      dateBegin,
      dateEnd,
      orderBy,
      status,
      residenceSelected,
      lang,
      sortType,
      sortDirection,
      props,
      params,
      role,
    ) => ({
      first: limit,
      offset,
      id: role,
    }),
    transformQuery: (items) => items.externals.nodes,
    hasPreviousPage: data => data.externals.pageInfo.hasPreviousPage,
    hasNextPage: data => data.externals.pageInfo.hasNextPage,
    totalPage: data => Math.ceil(data.externals.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    form: form(true),
    getIdAfterSubmit: (data) => data.createExternal.external.id,
    submitQuery: (data, index) => {
      if (index === 0) {
        return UsersExternal.createExternal
      }
    },
    submit: (data) => ({
      firstname: data.firstname,
      lastname: data.lastname,
      mobilePhoneNumber: data.mobilePhoneNumber,
      password: data.password,
      email: data.email,
      idRole: data.idRole,
    }),
  },
  update: {
    title: 'clients.update.external',
    getLink: (item) => item.id,
    form: form(false),
    variables: (id) => ({
      id,
    }),
    query: UsersExternal.getExternalById,
    transformQuery: (i) => i.external,
    submitQuery: (data, index) => {
      if (index === 0) {
        return UsersExternal.updateExternal
      }
      return null
    },
    submit: (id, data) => ({
      id,
      firstname: data.firstname,
      lastname: data.lastname,
      mobilePhoneNumber: data.mobilePhoneNumber,
      password: data.password,
      email: data.email,
      idRole,
    }),
  },
  read: {
    form: [
      {
        type: 'row',
        items: [
          {
            md: 6,
            id: 'lastname',
            label: 'clients.lastname',
          },
          {
            md: 6,
            id: 'firstname',
            label: 'clients.firstname',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'mobilePhoneNumber',
            label: 'clients.label.phoneNumber',
          },
          {
            id: 'email',
            label: 'clients.label.email',
          },
        ],
      },
    ],
    getLink: (item) => item.id,
    variables: (id) => ({
      id,
    }),
    query: UsersExternal.getExternalById,
    transformQuery: (i) => i.external,
  },
  delete: {
    text: 'clients.delete.external',
    getTitle: (item) => `${item.firstname} ${item.lastname}`,
    query: UsersExternal.deleteExternal,
  },
}
