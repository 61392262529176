import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import config from '../config'
import { ResidenceServiceBookings } from '../gql'
import { OrderedStatus } from './OrderedStatus'

const { LIMIT } = config

export const Ordered = {
  graphql: true,
  title: 'ordered.title',
  url: '/ordered',
  list: {
    title: 'articles.list',
    table: [
      {
        head: 'registers.label.start_date',
        key: 'startDatetime',
        width: '17%',
      },
      {
        head: 'registers.label.lastname',
        key: 'lastname',
        width: '17%',
      },
      {
        head: 'registers.label.firstname',
        key: 'firstname',
        width: '17%',
      },
      {
        head: 'registers.label.residence',
        key: 'residence',
        width: '12%',
      },
      {
        head: 'registers.label.service',
        key: 'service',
        width: '12%',
      },
      {
        head: 'registers.label.amount',
        key: 'amount',
        width: '10%',
      },
      {
        head: 'registers.label.status',
        key: 'status',
        width: '10%',
      },
    ],
    query: ResidenceServiceBookings.getAllServiceBookings,
    variables: (
      limit,
      offset,
      dateBegin,
      dateEnd,
      orderBy = 'CREATED_AT_DESC',
      status,
      idResidence,
    ) => {
      const data = {
        first: limit,
        offset,
        orderBy,
      }
      if (dateBegin) {
        data.dateBegin = dateBegin
      }
      if (dateEnd) {
        data.dateEnd = dateEnd
      }
      if (status) {
        data.status = status
      }
      if (idResidence) {
        data.id = idResidence
      }
      const usr = jwtDecode(localStorage.getItem('accessToken'))
      if (usr.role === 'logifim_service_provider') {
        data.idExternal = usr.id
      }
      return data
    },
    transformQuery: (items) => {
      if (items?.residenceServiceBookings) {
        return items.residenceServiceBookings.nodes.map((node) => {
          let statusLabel
          switch (node.status) {
            case 'NEW':
              statusLabel = 'Nouveau'
              break
            case 'PENDING':
              statusLabel = 'En attente'
              break
            case 'VALIDATED':
              statusLabel = 'Validée'
              break
            case 'IN_PROGRESS':
              statusLabel = 'En cours'
              break
            case 'REFUSED':
              statusLabel = 'Refusée'
              break
            case 'ABONDONNED':
              statusLabel = 'Abandonnée'
              break
            case 'CLOSED':
              statusLabel = 'Terminée'
              break
            default:
              statusLabel = ''
          }

          const clients = node.externalByIdExternal || node.residentByIdResident
          return {
            ...node,
            startDatetime: dayjs(node.startDatetime).format('DD/MM/YYYY HH:mm'),
            endDatetime: dayjs(node.endDatetime).format('DD/MM/YYYY HH:mm'),
            lastname: clients?.lastname,
            firstname: clients?.firstname,
            mobilePhoneNumber: clients?.mobilePhoneNumber,
            residence:
              node?.residenceServiceByIdResidenceService?.residenceByIdResidence
                ?.name,
            service:
              node?.residenceServiceByIdResidenceService?.serviceByIdService?.name,
            status: statusLabel,
          }
        })
      }
      const itemArray = items?.residenceServicesByIdResidence?.nodes?.map(
        (item) => item?.residenceServiceBookingsByIdResidenceService?.nodes,
      )

      return itemArray.flat()?.map((node) => {
        const clients = node.externalByIdExternal || node.residentByIdResident
        const residence = items.name
        return {
          ...node,
          startDatetime: dayjs(node.startDatetime).format('DD/MM/YYYY HH:mm'),
          endDatetime: dayjs(node.endDatetime).format('DD/MM/YYYY HH:mm'),
          lastname: clients?.lastname,
          firstname: clients?.firstname,
          mobilePhoneNumber: clients?.mobilePhoneNumber,
          residence,
        }
      })
    },
    hasPreviousPage: (data) => {
      if (data.residenceServiceBookings) {
        return data.residenceServiceBookings.pageInfo.hasPreviousPage
      }
      return data?.residence?.residenceServicesByIdResidence.nodes[0]
        ?.residenceServiceBookingsByIdResidenceService?.pageInfo.hasPreviousPage
    },
    hasNextPage: (data) => {
      if (data.residenceServiceBookings) {
        return data?.residenceServiceBookings?.pageInfo.hasNextPage
      }
      return data?.residence?.residenceServicesByIdResidence?.nodes[0]
        ?.residenceServiceBookingsByIdResidenceService?.pageInfo.hasNextPage
    },
    totalPage: (data) => {
      if (data.residenceServiceBookings) {
        return Math.ceil(data.residenceServiceBookings.totalCount / LIMIT)
      }
      return Math.ceil(
        data?.residence?.residenceServicesByIdResidence.nodes[0]
          ?.residenceServiceBookingsByIdResidenceService.totalCount / LIMIT,
      )
    },
    limit: LIMIT,
  },
  update: {
    getLink: (item) => item.id,
    form: [
      {
        id: 'bookingStatus',
        type: 'select',
        placeholder: 'registers.label.status',
        label: 'registers.label.status',
        defaultValue: 'registers.label.status',
        model: OrderedStatus,
        required: true,
      },
      {
        type: 'row',
        right: true,
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'residences.label.submit',
            md: 2,
          },
        ],
      },
    ],
    variables: (id) => ({
      id,
    }),
    query: ResidenceServiceBookings.getResidenceServiceBooking,
    transformQuery: (item) => {
      const clients =
        item.residenceServiceBooking.externalByIdExternal ||
        item.residenceServiceBooking.residentByIdResident
      return {
        ...item,
        lastname: clients.lastname,
        firstname: clients.firstname,
        mobilePhoneNumber: clients.mobilePhoneNumber,
        startDatetime: dayjs(item.residenceServiceBooking.startDatetime).format(
          'DD/MM/YYYY HH:mm',
        ),
        endDatetime: dayjs(item.residenceServiceBooking.endDatetime).format(
          'DD/MM/YYYY HH:mm',
        ),
        service:
          item.residenceServiceBooking?.residenceServiceByIdResidenceService
            ?.serviceByIdService?.name,
        residence:
          item.residenceServiceBooking?.residenceServiceByIdResidenceService
            ?.residenceByIdResidence?.name,
        bookingStatus: item.bookingStatus.nodes,
      }
    },
    submitQuery: (data, index) => {
      if (index === 0) {
        return ResidenceServiceBookings.updateResidenceServiceBookingStatus
      }
      return null
    },
    submit: (id, data) => ({
      id,
      status: data.bookingStatus,
    }),
  },
  read: {
    form: [
      {
        id: 'lastname',
        type: 'text',
        placeholder: 'registers.placeholder.lastname',
        label: 'registers.label.lastname',
        readOnly: true,
      },
      {
        id: 'firstname',
        type: 'text',
        placeholder: 'registers.placeholder.firstname',
        label: 'registers.label.firstname',
        readOnly: true,
      },
      {
        id: 'mobilePhoneNumber',
        type: 'text',
        placeholder: 'registers.placeholder.tel',
        label: 'registers.label.tel',
        regex: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/i,
        readOnly: true,
      },
      {
        id: 'startDatetime',
        type: 'text',
        placeholder: 'registers.placeholder.start_date',
        label: 'registers.label.start_date',
        readOnly: true,
      },
      {
        id: 'endDatetime',
        type: 'text',
        placeholder: 'registers.placeholder.end_date',
        label: 'registers.label.end_date',
        readOnly: true,
      },
      {
        id: 'service',
        type: 'text',
        placeholder: 'registers.placeholder.service',
        label: 'registers.placeholder.service',
        readOnly: true,
      },
      {
        id: 'residence',
        type: 'text',
        placeholder: 'registers.placeholder.residence',
        label: 'registers.placeholder.residence',
        readOnly: true,
      },
      {
        id: 'amount',
        type: 'text',
        placeholder: 'registers.label.amount',
        label: 'registers.label.amount',
        readOnly: true,
      },
      {
        id: 'comments',
        type: 'textarea',
        placeholder: 'registers.placeholder.comments',
        label: 'registers.label.comments',
        readOnly: true,
      },
    ],
    getLink: (item) => item.id,
    variables: (id) => ({
      id,
    }),
    query: ResidenceServiceBookings.getResidenceServiceBooking,
    transformQuery: (item) => {
      const clients =
        item.residenceServiceBooking.externalByIdExternal ||
        item.residenceServiceBooking.residentByIdResident
      return {
        ...item,
        lastname: clients.lastname,
        firstname: clients.firstname,
        mobilePhoneNumber: clients.mobilePhoneNumber,
        startDatetime: dayjs(item.residenceServiceBooking.startDatetime).format(
          'DD/MM/YYYY HH:mm',
        ),
        endDatetime: dayjs(item.residenceServiceBooking.endDatetime).format(
          'DD/MM/YYYY HH:mm',
        ),
        service:
          item.residenceServiceBooking?.residenceServiceByIdResidenceService
            ?.serviceByIdService?.name,
        residence:
          item.residenceServiceBooking?.residenceServiceByIdResidenceService
            ?.residenceByIdResidence?.name,
      }
    },
  },
}
