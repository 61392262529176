/* eslint-disable react/display-name */
import { SubCategories } from './SubCategories'
import { ServiceCategories } from '../gql'
import config from '../config'

const { LIMIT } = config

export const Categories = {
  graphql: true,
  title: 'categories.title',
  query: ServiceCategories.getServiceCategory,
  transformQuery: (items) => items?.serviceCategory?.label,
  url: '/categories',
  list: {
    title: 'categories.list',
    expendable: true,
    table: [
      {
        head: 'categories.label.title',
        key: 'label',
        width: '100%',
        expendable: true,
      },
    ],
    query: ServiceCategories.getServiceCategories,
    variables: (limit, offset) => ({
      first: limit,
      offset,
    }),
    transformQuery: (items) => {
      const cats = []
      items.serviceCategories.nodes.forEach((c) => {
        const subCat = c.serviceCategoriesByIdParentCategory.nodes
        cats.push({
          ...c,
          id: c.id,
          label: `${c.label} (${subCat.length} item${subCat.length > 1 ? 's' : ''})`,
          expendedParent: null,
          count: subCat.length,
          isDeletable: subCat.length ? subCat.length === 0 : true,
        })
        subCat.forEach((subC) => {
          cats.push({
            ...subC,
            expendedParent: c.id,
            isDeletable: true,
          })
        })
      })

      return cats
    },
    hasPreviousPage: data => data.serviceCategories.pageInfo.hasPreviousPage,
    hasNextPage: data => data.serviceCategories.pageInfo.hasNextPage,
    totalPage: data => Math.ceil(data.serviceCategories.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    title: 'categories.label.create',
    form: [
      {
        id: 'label',
        type: 'text',
        placeholder: 'categories.placeholder.title',
        label: 'categories.label.title',
        required: true,
      },
      {
        id: 'idParentCategory',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        model: SubCategories,
        placeholder: 'categories.placeholder.category',
        label: 'categories.label.category',
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'categories.label.submit',
            md: 2,
          },
        ],
      },
    ],
    query: ServiceCategories.getServiceCategories,
    transformQuery: (item) => ({
      ...item.serviceCategories,
    }),
    getIdAfterSubmit: (data) => data.createServiceCategory.serviceCategory.id,
    submitQuery: (data, index) => {
      if (index === 0) {
        return ServiceCategories.createServiceCategory
      }
    },
    submit: (data) => ({
      label: data.label,
      idParentCategory: data.idParentCategory,
    }),
  },
  update: {
    title: 'categories.label.update',
    getLink: (item) => item.id,
    query: ServiceCategories.getServiceCategory,
    transformQuery: (item) => ({
      ...item.serviceCategory,
    }),
    submitQuery: (data, index) => {
      if (index === 0) {
        return ServiceCategories.updateServiceCategory
      }
      return null
    },
    submit: (id, data) => ({
      id,
      label: data.label,
      idParentCategory: data.idParentCategory,
    }),
  },
  read: {
    form: [
      {
        id: 'label',
        type: 'text',
        placeholder: 'categories.placeholder.title',
        label: 'categories.label.title',
        required: true,
      },
    ],
    getLink: (item) => item.id,
    query: ServiceCategories.getServiceCategory,
    transformQuery: (i) => ({
      ...i.serviceCategory,
      label: i.serviceCategory.label,
    }),
  },
  delete: {
    text: 'categories.delete.confirm',
    query: ServiceCategories.deleteServiceCategory(),
    getTitle: (item) => `${item.label}`,
  },
}
