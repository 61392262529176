import React from 'react'
import { Container } from 'shards-react'
import PropTypes from 'prop-types'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { List, Create, Update, Read } from '../components/automated'

const Automated = ({ model, custom }) => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { path, url } = useRouteMatch()

  return (
    <Container fluid className="main-content-container px-4">
      <Switch>
        <Route exact path={path}>
          <List {...{ model, url, custom }} />
        </Route>
        <Route path={`${path}/create`}>
          <Create {...{ model, url, custom }} />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <Update {...{ model, url, custom }} />
        </Route>
        <Route path={`${path}/read/:id`}>
          <Read {...{ model, url, custom }} />
        </Route>
        {model.submodules &&
          model.submodules.map((submodule, index) => (
            <Route path={`${submodule.url}/create`} key={`${index}`}>
              <Create {...submodule} custom={custom} />
            </Route>
          ))}
        {model.submodules &&
          model.submodules.map((submodule, index) => (
            <Route path={`${submodule.url}/edit/:subId`} key={`${index}`}>
              <Update {...submodule} custom={custom} />
            </Route>
          ))}
        {model.submodules &&
          model.submodules.map((submodule, index) => (
            <Route path={`${submodule.url}/read/:subId`} key={`${index}`}>
              <Read {...submodule} custom={custom} />
            </Route>
          ))}
        {model.submodules &&
          model.submodules.map((submodule, index) => (
            <Route path={submodule.url} key={`${index}`}>
              <List {...submodule} custom={custom} />
            </Route>
          ))}
      </Switch>
    </Container>
  )
}

Automated.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  custom: PropTypes.objectOf(PropTypes.any),
}

Automated.defaultProps = {
  custom: () => {},
}

export default Automated
