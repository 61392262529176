import { gql } from '@apollo/client'

const fetchProvider = gql`
  query fetchProvider ($id: BigFloat!, $first: Int, $offset: Int) {
    externals(
      filter: { idRole: { equalTo: $id } }
      first: $first
      offset: $offset
    ) {
      nodes {
        id
        firstname
        lastname
        # liste des commandes effectuées, à adapter selon les besoins
        # residenceServiceBookingsByIdExternal {
        #   nodes
        # }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
`

const fetchProviderRoleId = gql`
  query listRoles {
    viewRoles {
      nodes {
        id
        rolname
      }
    }
  }
`

const deleteProviderUser = gql`
  mutation deleteProviderUser($id: UUID!) {
    deleteExternal(input: { id: $id }) {
      clientMutationId
    }
  }
`

const getUserProviderById = gql`
  query getUserProviderById($id: UUID!) {
    external(id: $id) {
      lastname
      firstname
      mobilePhoneNumber
      email
      id
    }
  }
`

const updateExternal = gql`
  mutation updateExternal(
    $firstname: String!
    $lastname: String!
    $email: EmailAddress
    $mobilePhoneNumber: MobilePhoneNumberFr
    $id: UUID!
  ) {
    updateExternal(
      input: {
        patch: {
          firstname: $firstname
          lastname: $lastname
          mobilePhoneNumber: $mobilePhoneNumber
          email: $email
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`
const createUserProvider = gql`
  mutation createUserProvider(
    $firstname: String!
    $lastname: String!
    $mobilePhoneNumber: MobilePhoneNumberFr
    $password: String!
    $idRole: BigFloat!
    $email: EmailAddress
  ) {
    createExternal(
      input: {
        external: {
          firstname: $firstname
          lastname: $lastname
          password: $password
          idRole: $idRole
          mobilePhoneNumber: $mobilePhoneNumber
          email: $email
        }
      }
    ) {
      external {
        id
      }
    }
  }
`

export default {
  fetchProvider,
  fetchProviderRoleId,
  deleteProviderUser,
  getUserProviderById,
  updateExternal,
  createUserProvider,
}
