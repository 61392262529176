import { gql } from '@apollo/client'
import config from '../config'

const searchResidenceByName = gql`
  query searchResidenceByName($name: String!) {
    residences(filter: {name: {likeInsensitive: $name}}) {
      nodes {
        id
        name
        resourcesByIdResidence {
          nodes {
            residenceServiceResourcesByIdResource {
              nodes {
                idResidenceService
              }
            }
          }
        }
      }
    }
  }
`

const getResidences = gql`
  query getResidences {
    residences {
      nodes {
        cityByIdCity {
          name
          id
        }
        name
        id
      }
    }
  }
`

const getResidencesWithRessources = gql`
  query getResidences {
    residences {
      nodes {
        cityByIdCity {
          name
          id
        }
        resourcesByIdResidence {
          nodes {
            name
            id
          }
        }
        name
        id
      }
    }
  }
`

const getResidencesForList = gql`
  query getResidences ($first: Int, $offset: Int) {
    residences(first: $first, offset: $offset) {
      nodes {
        id
        name,
        cityByIdCity {
          name
          departmentByIdDepartment {
            name
            num
          }
        }
        residenceCoordinationsByIdResidence {
          nodes {
            staffByIdCoordinator {
              firstname
              lastname
            }
          }
        }
        staffByIdManager {
          firstname
          lastname
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const getResidencesForListManager = gql`
  query getResidencesForListManager ($first: Int, $offset: Int, $id: UUID!) {
    residences(first: $first, offset: $offset, condition: {idManager: $id}) {
      nodes {
        id
        name,
        cityByIdCity {
          name
          departmentByIdDepartment {
            name
            num
          }
        }
        residenceCoordinationsByIdResidence {
          nodes {
            staffByIdCoordinator {
              firstname
              lastname
            }
          }
        }
        staffByIdManager {
          firstname
          lastname
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const getResidencesForListCoordinator = gql`
  query getResidencesForListCoordinator ($first: Int, $offset: Int, $id: UUID!) {
    residenceCoordinations(first: $first, offset: $offset, condition: {idCoordinator: $id}) {
      nodes {
        residenceByIdResidence {
          id
          name,
          cityByIdCity {
            name
            departmentByIdDepartment {
              name
              num
            }
          }
          residenceCoordinationsByIdResidence {
            nodes {
              staffByIdCoordinator {
                firstname
                lastname
              }
            }
          }
          staffByIdManager {
            firstname
            lastname
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const getCityByCode = () => gql`
  query getCities($cp: String!) {
    cities(filter: {postcode: {equalTo: $cp}}) {
      nodes {
        id
        name
        postcode
        departmentByIdDepartment {
          num
        }
      }
    }
  }
`

const fetchManagersByName = () => gql`
  query fetchManagersByName ($term: String, $role: BigFloat = "${config.ROLES_ID.logifim_manager.toString()}") {
    staffs(
      filter: {
        or: [
          {or : {firstname: {likeInsensitive: $term}, lastname: {likeInsensitive: "%%"}}},
          {or : {firstname: {likeInsensitive: "%%"}, lastname: {likeInsensitive: $term}}}
        ]
      }
      condition: {idRole: $role}
    ) {
      nodes {
        id
        firstname
        lastname
      }
    }
  }
`

const fetchCoordinatorsByName = () => gql`
  query fetchManagersByName ($term: String, $role: BigFloat = "${config.ROLES_ID.logifim_coordinator.toString()}") {
    staffs(
      filter: {
        or: [
          {or : {firstname: {likeInsensitive: $term}, lastname: {likeInsensitive: "%%"}}},
          {or : {firstname: {likeInsensitive: "%%"}, lastname: {likeInsensitive: $term}}}
        ]
      }
      condition: {idRole: $role}
    ) {
      nodes {
        id
        firstname
        lastname
      }
    }
  }
`

const createResidence = gql`
  mutation createResidence($name: String!, $idCity: UUID!, $address: String!, $idManager: UUID) {
    createResidence(input: {residence: {name: $name, idCity: $idCity, address: $address, idManager: $idManager}}) {
      residence {
        id
      }
    }
  }
`

const deleteResidence = gql`
  mutation deleteResidence($id: UUID!) {
    deleteResidence(input: {id: $id}) {
      clientMutationId
    }
  }
`

const createResidenceCoordination = gql`
  mutation createResidenceCoordination($idResidence: UUID!, $idCoordinator: UUID!) {
    createResidenceCoordination(input: {residenceCoordination: {idResidence: $idResidence, idCoordinator: $idCoordinator}}) {
      clientMutationId
    }
  }
`

const getResidence = gql`
  query getResidence($id: UUID!) {
    residence(id: $id) {
      id
      name
      address
      idCity
      cityByIdCity {
        id
        name
        postcode
        departmentByIdDepartment {
          name
          num
        }
      }
      residenceCoordinationsByIdResidence {
        nodes {
          staffByIdCoordinator {
            firstname
            lastname
            id
          }
        }
      }
      staffByIdManager {
        id
        firstname
        lastname
      }
    }
  }
`

const deleteCoordinatorResidence = gql`
  mutation deleteCoordinatorResidence($idResidence: UUID!, $idCoordinator: UUID!) {
    deleteResidenceCoordination(
      input: {idResidence: $idResidence, idCoordinator: $idCoordinator}
    ) {
      clientMutationId
    }
  }
`

const updateResidence = gql`
  mutation updateResidence($id: UUID!, $name: String!, $idCity: UUID!, $address: String!, $idManager: UUID) {
    updateResidence(input: {patch: {
      name: $name,
      address: $address,
      idCity: $idCity,
      idManager: $idManager,
    }, id: $id}) {
      clientMutationId
    }
  }
`

export default {
  searchResidenceByName,
  getResidences,
  getResidencesForList,
  getCityByCode,
  fetchManagersByName,
  fetchCoordinatorsByName,
  createResidence,
  deleteResidence,
  createResidenceCoordination,
  getResidence,
  deleteCoordinatorResidence,
  updateResidence,
  getResidencesWithRessources,
  getResidencesForListManager,
  getResidencesForListCoordinator,
}
