import { gql } from '@apollo/client'

const getRates = gql`
  query getRates {
    vatRates {
      nodes {
        id
        label
        rate
      }
    }
  }
`

export default getRates
