import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouteNavLink } from 'react-router-dom'
import { NavItem, NavLink } from 'shards-react'
import { useTranslation } from 'react-i18next'
import SubSidebarNavItem from './SubSidebarNavItem'
import { shouldShowItem } from '../../../lib'

const SidebarNavItem = ({ item }) => {
  const { t } = useTranslation()

  if (!shouldShowItem(item)) {
    return (<></>)
  }

  return (
    <NavItem>
      {!item.subItems ? (
        <NavLink tag={RouteNavLink} to={item.to}>
          {item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          {item.title && <span>{t(item.title)}</span>}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
            />
          )}
        </NavLink>
      ) : (
        <SubSidebarNavItem item={item} />
      )}
    </NavItem>
  )
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default SidebarNavItem
