import { gql } from '@apollo/client'

const fetchResidents = gql`
  query fetchResidents($first: Int, $offset: Int) {
    residents(first: $first, offset: $offset) {
      nodes {
        id
        residentNumber
        firstname
        lastname
        email
        residenceByIdResidence {
          id
          name
          cityByIdCity {
            name
            id
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const fetchResidentForManager = gql`
  query fetchResidentForManager($first: Int, $offset: Int, $idRole: UUID!) {
    residences(
      first: $first
      offset: $offset
      condition: { idManager: $idRole }
    ) {
      nodes {
        residentsByIdResidence {
          nodes {
            id
            firstname
            lastname
            email
            residenceByIdResidence {
              id
              name
              cityByIdCity {
                name
                id
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const fetchResidentForCoordinator = gql`
  query fetchResidentForCoordinator($first: Int, $offset: Int, $idRole: UUID!) {
    residenceCoordinations(
      first: $first
      offset: $offset
      condition: { idCoordinator: $idRole }
    ) {
      nodes {
        residenceByIdResidence {
          residentsByIdResidence {
            nodes {
              id
              firstname
              lastname
              email
              residenceByIdResidence {
                id
                name
                cityByIdCity {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

const deleteResident = gql`
  mutation deleteResident($id: UUID!) {
    deleteResident(input: { id: $id }) {
      clientMutationId
    }
  }
`

const getDataToCreateResident = gql`
  query getDataToCreateResident {
    maritalStatuses {
      nodes {
        id
        name
      }
    }
    civilities(orderBy: ID_DESC) {
      nodes {
        id
        name
      }
    }
  }
`

const createResident = gql`
  mutation createResident(
    $idCivility: UUID!
    $residentNumber: String!
    $firstname: String!
    $lastname: String!
    $idMaritalStatus: UUID!
    $email: EmailAddress
    $birthdate: Date!
    $mobilePhoneNumber: MobilePhoneNumberFr
    $password: String!
    $idResidence: UUID!
    $door: String
  ) {
    createResident(
      input: {
        resident: {
          idCivility: $idCivility
          residentNumber: $residentNumber
          firstname: $firstname
          lastname: $lastname
          idMaritalStatus: $idMaritalStatus
          birthdate: $birthdate
          mobilePhoneNumber: $mobilePhoneNumber
          email: $email
          password: $password
          idResidence: $idResidence
          door: $door
        }
      }
    ) {
      resident {
        id
      }
    }
  }
`

const getResidentById = gql`
  query getResidentById($id: UUID!) {
    resident(id: $id) {
      lastname
      firstname
      residentNumber
      birthdate
      mobilePhoneNumber
      email
      id
      door
      residenceByIdResidence {
        name
        cityByIdCity {
          name
        }
      }
      maritalStatusByIdMaritalStatus {
        id
        name
      }
      civilityByIdCivility {
        id
        name
      }
    }
    maritalStatuses {
      nodes {
        id
        name
      }
    }
    civilities(orderBy: ID_DESC) {
      nodes {
        id
        name
      }
    }
  }
`
const getResidentByResidentNumber = gql`
  query getResidentByResidentNumber($residentNumber: String!) {
    residentByResidentNumber(residentNumber: $residentNumber) {
      firstname
      lastname
      id
    }
  }
`

const updateResident = gql`
  mutation updateResident(
    $idCivility: UUID!
    $residentNumber: String!
    $firstname: String!
    $lastname: String!
    $idMaritalStatus: UUID!
    $email: EmailAddress
    $birthdate: Date!
    $mobilePhoneNumber: MobilePhoneNumberFr
    $password: String
    $idResidence: UUID
    $id: UUID!
    $door: String
  ) {
    updateResident(
      input: {
        patch: {
          idCivility: $idCivility
          residentNumber: $residentNumber
          firstname: $firstname
          lastname: $lastname
          idMaritalStatus: $idMaritalStatus
          birthdate: $birthdate
          mobilePhoneNumber: $mobilePhoneNumber
          email: $email
          password: $password
          idResidence: $idResidence
          door: $door
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

export default {
  fetchResidents,
  deleteResident,
  getDataToCreateResident,
  createResident,
  getResidentById,
  updateResident,
  getResidentByResidentNumber,
  fetchResidentForManager,
  fetchResidentForCoordinator,
}
