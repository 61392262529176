import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormInput,
  Button,
} from 'shards-react'

import {
  useHistory,
  Link,
  useLocation,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import queryString from 'query-string'
import backgroundImage from '../images/fgtPwd.svg'

import api from '../api'
import logo from '../images/logo.png'

const ResetPwd = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const [errorPwd, setErrorPwd] = useState(false)
  const [newPwd, setNewPwd] = useState('')
  const [confirmNewPwd, setConfirmNewPwd] = useState('')
  const [togglePwdVisibility, setTogglePwdVisibility] = useState(false)
  const mediaMatch = window.matchMedia('(max-width: 480px)')
  const [matches, setMatches] = useState(mediaMatch.matches)
  const { search } = useLocation()
  const { token } = queryString.parse(search)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addListener(handler)
    return () => mediaMatch.removeListener(handler)
  })

  const resetPassword = async (event) => {
      event.preventDefault()
      setError(false)
      if ((newPwd === '' || confirmNewPwd === '') || (newPwd === '' && confirmNewPwd === '')) {
        setError(true)
      } else if (newPwd !== confirmNewPwd) {
        setErrorPwd(true)
      } else {
try {
          await api.resetPwd(confirmNewPwd, token)
          history.push('/login')
      } catch (e) {
          setError(true)
      }
    }
  }

  const styles = {
    title: {
      fontSize: matches ? '2.5em' : '4.5em',
      paddingLeft: matches ? '25vw' : '0',
      color: '#485156',
      fontFamily: 'Source Serif Pro',
    },
    subTitle: {
      fontSize: matches ? '1em' : '1.5em',
      paddingLeft: matches ? '25vw' : '0',
      color: '#485156',
      opacity: '0.6',
      fontFamily: 'Source Sans Pro',
      fontWeight: '700',
    },
  }

  return (
    <Container fluid className="main-content-container" style={{ padding: 0 }}>
      <Row
        style={{
          height: '100vh',
        }}
      >
        <Col
          style={{
            backgroundColor: 'rgba(110, 60, 165, 0.2)',
            position: 'relative',
          }}
        >
          <div className="p-5">
            <h4
              style={styles.title}
            >
              résid’
            </h4>
            <p className="pt-4" style={styles.subTitle}>Portail de connexion V-Services</p>
          </div>
          <img
            alt="background"
            src={backgroundImage}
            style={{
              position: 'absolute',
              bottom: '4vh',
              right: '-11vw',
              left: '0px',
              zIndex: 100,
              maxHeight: '70%',
              maxWidth: '45vw',
            }}
          />
        </Col>
        <Col sm={{ size: 8 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
          <div style={{ width: '70%', height: '60%', paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="mb-5">
              <img
                id="main-logo"
                style={{ width: '62px', height: '56px' }}
                className="mb-3"
                src={logo}
                alt="V-Services"
              />
              <h5 className="m-0 pb-1 mt-3" style={{ fontWeight: '700', fontSize: '2rem', color: '#485156', fontFamily: 'Source Sans Pro' }}>{t('resetPwd.title')}</h5>
              <p className="mb-2" style={{ fontSize: '1.1rem', color: '#485156', fontWeight: '400', fontFamily: 'Source Sans Pro' }}>
                {t('resetPwd.subTitle')}
              </p>
            </div>

            <Form>
              <FormGroup>
                <label style={{ fontSize: '0.875rem', fontWeight: '600', color: '#485156', fontFamily: 'Source Sans Pro' }} htmlFor="username1">{t('resetPwd.mail')}</label>
                <FormInput
                  id="username1"
                  type="password"
                  value={newPwd}
                  placeholder={t('resetPwd.mail')}
                  onChange={(event) => {
                        setNewPwd(event.target.value)
                        }}
                />
              </FormGroup>
              <FormGroup>
                <label style={{ fontSize: '0.875rem', fontWeight: '600', color: '#485156' }} htmlFor="username2">{t('resetPwd.confirmMail')}</label>
                <FormInput
                  id="username2"
                  type={togglePwdVisibility ? 'text' : 'password'}
                  value={confirmNewPwd}
                  placeholder={t('resetPwd.mail')}
                  onChange={(event) => {
                        setConfirmNewPwd(event.target.value)
                        }}
                />
              </FormGroup>
              <FormGroup style={{ display: 'flex' }}>
                <label style={{ color: '#485156', marginRight: 5 }} htmlFor="togglepwdvisibility">{t('resetPwd.seePwd')}</label>
                <FormInput
                  id="togglepwdvisibility"
                  type="checkbox"
                  value={togglePwdVisibility}
                  onChange={() => {
                    setTogglePwdVisibility((prevState) => !prevState)
                  }}
                  style={{ height: '20px', width: '20px' }}
                />
              </FormGroup>
              {error && (
              <FormFeedback
                invalid
                style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
              >
                {t('form.fieldInvalid')}
              </FormFeedback>
            )}
              {errorPwd && (
                <FormFeedback
                  invalid
                  style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
                >
                  {t('resetPwd.errorPwd')}
                </FormFeedback>
            )}
              <FormGroup style={{
                display: 'flex',
                justifyContent: 'center',
            }}
              >
                <Button
                  type="submit"
                  onClick={resetPassword}
                  style={{ backgroundColor: '#C6CF00', width: '100%', borderRadius: '5px', height: '50px', fontSize: '1.25rem', fontWeight: '700', fontFamily: 'Source Sans Pro' }}
                >
                  {t('resetPwd.submit')}
                </Button>
              </FormGroup>
              <Link to="/login" exact className="pl-0" style={{ textDecoration: 'underline', color: '#00AFA0', fontSize: 14 }}>{t('login.title')}</Link>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

ResetPwd.propTypes = {
  // model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ResetPwd
