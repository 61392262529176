export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '50px',
  },
  divButtons: {
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'row',
    marginTop: '2rem',
  },
  button: {
    border: '0 none',
    background: 'none',
    color: 'rgb(90, 97, 105)',
    fontSize: '13px',
    fontWeight: 700,
  },
  buttonSubmit: {
    width: '120px',
    backgroundColor: '#32A0E6',
    height: '32px',
    border: 'none',
    fontWeight: 700,
  },
}
