import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DataListAdd = ({ resultsSearch, addItem }) => {
  const { t } = useTranslation()
  return (
    <div className="mb-3">
      {resultsSearch.map((r) => (
        <div
          key={r.id}
          style={{ border: '1px solid rgba(196, 196, 196, 0.4)', height: '40px', display: 'flex', alignItems: 'center' }}
          className="mt-1 pl-3"
        >
          <p className="p-0 m-0" style={{ minWidth: '15%' }}>{`${r.firstname} ${r.lastname}`}</p>
          <button type="button" onClick={() => { addItem(r) }} className="ml-3" style={{ border: 'none' }}>{t('ui.add')}</button>
        </div>
    ))}
    </div>
  )
}

DataListAdd.propTypes = {
  resultsSearch: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  addItem: PropTypes.func.isRequired,
}

export default DataListAdd
