import { gql } from '@apollo/client'

const fetchCatalogArticles = gql`
  query catalogArticles($first: Int, $offset: Int, $idCatalog: UUID) {
    catalogArticles(
      first: $first
      offset: $offset
      filter: { idCatalog: { equalTo: $idCatalog } }
    ) {
      nodes {
        articleByIdArticle {
          label
          id
          status
          description
          idVatRate
          unitPricesByIdArticle {
            nodes {
              value
              unitByIdUnit {
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
    vatRates {
        nodes {
          id
          label
          rate
        }
      }
  }
`

const deleteArticle = gql`
  mutation deleteArticle($id: UUID!) {
    deleteArticle(input: { id: $id }) {
      clientMutationId
    }
  }
`

// Step 1 : Create an article
const createArticle = gql`
  mutation createArticle(
    $label: String!
    $description: String!
    $status: ArticleStatus,
    $idVatRate: UUID!
  ) {
    createArticle(
      input: {
        article: { label: $label, description: $description, status: $status, idVatRate: $idVatRate }
      }
    ) {
      article {
        id
      }
    }
  }
`

// Step 2 : Create a unit price for an article
// Currenty is forced to EURO
const createUnitPrice = gql`
  mutation createUnitPrice(
    $idArticle: UUID!
    $idUnit: UUID!
    $value: Int!
    $idCurrency: UUID = "e3e84430-5255-4166-90a6-c841994c9c44"
  ) {
    createUnitPrice(
      input: {
        unitPrice: {
          value: $value
          idCurrency: $idCurrency
          idUnit: $idUnit
          idArticle: $idArticle
        }
      }
    ) {
      clientMutationId
    }
  }
`

// Step 3 : Associate article to a catalog
const createCatalogArticle = gql`
  mutation createCatalogArticle($idArticle: UUID!, $idCatalog: UUID!) {
    createCatalogArticle(
      input: {
        catalogArticle: { idArticle: $idArticle, idCatalog: $idCatalog }
      }
    ) {
      clientMutationId
      catalogByIdCatalog {
        id
      }
    }
  }
`

const getArticleById = gql`
  query getArticleById($id: UUID!) {
    article(id: $id) {
      label
      id
      description
      status
      idVatRate
      unitPricesByIdArticle {
        nodes {
          id
          value
          unitByIdUnit {
            name
            id
          }
        }
      }
    }
    vatRates {
      nodes {
        id
        label
        rate
      }
    }
  }
`

const updateArticle = gql`
  mutation updateArticle(
    $label: String!
    $status: ArticleStatus
    $description: String!
    $id: UUID!
    $idVatRate: UUID!
  ) {
    updateArticle(
      input: {
        patch: { label: $label, description: $description, status: $status, idVatRate: $idVatRate }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const updateUnitPrice = gql`
  mutation updateUnitPrice(
    $idArticle: UUID!
    $idUnit: UUID!
    $id: UUID!
    $value: Int!
  ) {
    updateUnitPrice(
      input: {
        patch: { idArticle: $idArticle, idUnit: $idUnit, value: $value }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const getArticleByOrderId = gql`
  query getArticlesByIdOrder($id: UUID!) {
    residenceServiceBooking(id: $id) {
      totalAmount
      orderArticlesByIdOrder {
        nodes {
          quantity
          articleByIdArticle {
            label
            unitPricesByIdArticle {
              nodes {
                value
              }
            }
          }
        }
      }
    }
  }
`

export default {
  fetchCatalogArticles,
  createArticle,
  createUnitPrice,
  createCatalogArticle,
  deleteArticle,
  getArticleById,
  updateArticle,
  updateUnitPrice,
  getArticleByOrderId,
}
