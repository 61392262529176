import React, { useEffect, useState, useRef } from 'react'
import {
    Col, FormSelect, Row, FormInput, Button,
  } from 'shards-react'
  import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import config from '../../../config'
import { UsersResident, Residences } from '../../../gql'

const SearchPerimeterNews = ({ data, onChangeCustom }) => {
    const { t } = useTranslation()
    const [residencesBackup, setResidenceBackup] = useState([])
    const [residenceId, setResidenceId] = useState('')
    const [residentNumber, setResidentNumber] = useState('')
    const [resultsResident, setResultsResident] = useState([])
    const [noResident, setNoResident] = useState(false)
    const btnSearchResident = useRef(null)
    const [getResidences, { data: residenceList }] =
    useLazyQuery(Residences.getResidences, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
    onError: (err) => {
      const request = Residences.getResidences
      const event = {
        request: request?.definitions[0]?.name?.value,
      }
      captureMessageWithAttachment(event, err)
    },
    onCompleted: () => {
      setResidenceBackup(residenceList.residences.nodes)
    },
  })

  const [fetchResidentByResidentNumber, { data: resultsData }] =
  useLazyQuery(UsersResident.getResidentByResidentNumber, {
    fetchPolicy: config.fetchPolicy,
    notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
    variables: {
      residentNumber,
    },
    onError: (err) => {
      const request = UsersResident.getResidentByResidentNumber
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          residentNumber,
        },
      }
      captureMessageWithAttachment(event, err)
    },
    onCompleted: () => {
      setResultsResident([])
      if (resultsData.residentByResidentNumber) {
        const rs = []
        rs.push({
          firstName:
          resultsData.residentByResidentNumber.firstname,
        lastName: resultsData.residentByResidentNumber.lastname })
        setResultsResident(rs)
        onChangeCustom('idResident', resultsData.residentByResidentNumber.id)
      } else {
        setNoResident(true)
      }
    },
  })

  useEffect(() => {
    getResidences()
  }, [])

    return (
      <div>
        {data.scope === 'Residence' && (

          <>
            <Row>
              <Col className="form-group col-md-3">
                <label className="font-weight-bold" htmlFor="residence">{t('residences.title')}</label>
                <FormSelect
                  id="residence"
                  value={residenceId}
                  onChange={((event) => {
                  setResidenceId(event.target.value)
                  onChangeCustom('residenceId', event.target.value)
                })}
                >
                  <option value="">{t('residences.services.chooseResidence')}</option>
                  {residencesBackup.length > 0 &&
                  residencesBackup.map((option, index) => (
                    <option value={option.id} key={`optionRes${index}`}>{option.name}</option>
                  ))}
                </FormSelect>
              </Col>
            </Row>
            <>
              {data.perimeter?.idResidence?.fromDb && (
              <div style={{ border: '1px solid rgba(196, 196, 196, 0.4)', height: '40px', display: 'flex', alignItems: 'center' }} className="mt-1 pl-3">
                <p className="p-0 m-0" style={{ minWidth: '15%' }}>
                  {t('news.update.residence.actual')}
                  {data.perimeter.idResidence?.fromDb}
                </p>
              </div>
              )}
            </>

          </>
          )}
        {data.scope === 'Resident' && (
          <>
            <Row>
              <Col className="form-group col-md-6">
                <label className="font-weight-bold" htmlFor="residentNumber">{t('clients.residentNumber')}</label>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormInput
                    style={{
                    width: '200px',
                  }}
                    id="residentNumber"
                    value={residentNumber}
                    type="text"
                    ref={btnSearchResident}
                    onChange={(e) => {
                    setResidentNumber(e.target.value)
                    setNoResident(false)
                  }}
                  />
                  <Button
                    type="button"
                    style={{
                    width: '50px',
                    borderRadius: '0 .25rem .25rem 0',
                  }}
                    onClick={() => {
                    if (residentNumber !== '') {
                      fetchResidentByResidentNumber({ variables: { residentNumber } })
                    }
                  }}
                  >
                    {t('news.button.valid')}
                  </Button>
                  {resultsResident.length > 0 && (
                  <>
                    <p style={{ margin: 0, marginLeft: 5 }}>{resultsResident[0].firstName}</p>
                    <p style={{ margin: 0, marginLeft: 5 }}>{resultsResident[0].lastName}</p>
                  </>

                )}
                  {noResident && (
                  <p style={{
                    color: 'red',
                    margin: 0,
                    marginLeft: 5,
                  }}
                  >
                    {t('news.noResident')}

                  </p>
                )}
                </div>
              </Col>
            </Row>
            <>
              {data.perimeter?.idResident?.fromDb && (
              <div style={{ border: '1px solid rgba(196, 196, 196, 0.4)', height: '40px', display: 'flex', alignItems: 'center' }} className="mt-1 pl-3">
                <p className="p-0 m-0" style={{ minWidth: '15%' }}>
                  {t('news.update.resident.actual')}
                  {data.perimeter?.idResident?.fromDb?.lastname}
                  {' '}
                  {data.perimeter?.idResident?.fromDb?.firstname}
                </p>
              </div>
              )}
            </>

          </>
          )}
      </div>
    )
}

SearchPerimeterNews.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeCustom: PropTypes.func.isRequired,
  }

export default SearchPerimeterNews
