/* eslint-disable react/display-name */
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import React from 'react'
import { Files, Photos, ResidenceService } from '../components/automated/custom'
import ServiceProvider from '../components/automated/custom/ServiceProvider'
import config from '../config'
import { ServiceCategories, Services as ServicesGql } from '../gql'
import { dataURItoBlob } from '../lib'
import { ResidencesForService } from './ResidencesForService'
import { SubCategories } from './SubCategories'

const { LIMIT } = config

const connectedUser =
  localStorage.getItem('accessToken') &&
  jwtDecode(localStorage.getItem('accessToken'))

const form = (create = true) => [
  {
    id: 'ref',
    type: 'text',
    placeholder: 'services.placeholder.ref',
    label: 'services.label.ref',
    required: true,
  },
  {
    id: 'label',
    type: 'text',
    placeholder: 'services.placeholder.label',
    label: 'services.label.libelle',
    required: true,
  },
  {
    id: 'name',
    type: 'text',
    placeholder: 'services.placeholder.title',
    label: 'services.label.title',
    required: true,
  },
  {
    id: 'description',
    type: 'textarea',
    placeholder: 'services.placeholder.description',
    label: 'services.label.description',
    required: true,
    disabled: false,
  },
  {
    id: 'visuel',
    type: 'custom',
    component: (props) => (
      <Photos {...props} wording={create ? 'ui.addImage' : 'ui.updateImage'} />
    ),
    url: (params, withToken = true) => {
      let url = `${config.API_BASE_URL}services/${params.id}/assets/visuel.png`
      if (withToken) {
        url += `?token=${localStorage.getItem('accessToken')}`
      }
      return url
    },
    label: 'services.label.visuel',
    deletable: false,
    required: create,
    needToSubmit: async (id, data) => {
      if (data && data.img && data.type) {
        const token = localStorage.getItem('accessToken')
        const url = `${config.API_BASE_URL}services/${id}/assets/visuel.png`
        const formdata = new FormData()
        formdata.append('asset', dataURItoBlob(data.img))
        return axios.post(url, formdata, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
      }
    },
  },
  {
    id: 'files',
    type: 'custom',
    component: (props) => <Files {...props} url="services" multiple={true} />,
    url: (params, name, withToken = true) => {
      let url = `${config.API_BASE_URL}services/${params.id}/assets/${name}`
      if (withToken) {
        url += `?token=${localStorage.getItem('accessToken')}`
      }
      return url
    },
    label: 'services.label.files',
    needToSubmit: async (id, data) => {
      if (data && data.length > 0) {
        const token = localStorage.getItem('accessToken')
        data.forEach((d) => {
          const url = `${
            config.API_BASE_URL
          }services/${id}/assets/${d.name.replace(/\s/g, '_')}`
          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          }

          if (d.status === 'added') {
            const formdata = new FormData()
            formdata.append('asset', dataURItoBlob(d.file))
            return axios.post(url, formdata, { headers })
          }

          if (d.status === 'deleteFromServer') {
            return axios.delete(url, { headers })
          }
        })
      }
    },
  },
  {
    id: 'url',
    type: 'text',
    placeholder: 'services.placeholder.url',
    label: 'services.label.url',
  },
  {
    id: 'picto',
    type: 'custom',
    component: (props) => (
      <Photos
        {...props}
        indications="300*300px"
        wording={create ? 'ui.addPicto' : 'ui.updatePicto'}
      />
    ),
    url: (params, withToken = true) => {
      let url = `${config.API_BASE_URL}services/${params.id}/assets/picto.png`
      if (withToken) {
        url += `?token=${localStorage.getItem('accessToken')}`
      }
      return url
    },
    label: 'services.label.image',
    deletable: false,
    required: create,
    needToSubmit: async (id, data) => {
      if (data && data.img && data.type) {
        const token = localStorage.getItem('accessToken')
        const url = `${config.API_BASE_URL}services/${id}/assets/picto.png`
        const formdata = new FormData()
        formdata.append('asset', dataURItoBlob(data.img))
        return axios.post(url, formdata, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
      }
    },
  },
  {
    id: 'idServiceCategory',
    type: 'select',
    defaultValue: 'ui.defaultValue',
    model: SubCategories,
    placeholder: 'services.placeholder.category',
    label: 'services.label.category',
    required: true,
  },
  connectedUser?.role !== 'logifim_service_provider' && {
    id: 'provider',
    type: 'custom',
    label: 'services.label.provider',
    required: false,
    component: (props) => <ServiceProvider {...props} />,
  },
  {
    type: 'row',
    rowCustom: { marginTop: '60px' },
    items: [
      {
        type: 'back',
        label: 'ui.cancel',
        md: 2,
      },
      {
        type: 'submit',
        label: 'services.label.submit',
      },
    ],
  },
]

export const Services = {
  graphql: true,
  title: 'services.title',
  query: ServicesGql.getServiceWithCategory,
  transformQuery: (items) => items?.service?.ref,
  url: '/services',
  redirectionAfterUpdate: true,
  submodules: [
    {
      url: '/services-residence',
      model: ResidencesForService,
    },
  ],
  list: {
    title: 'services.list',
    table: [
      {
        head: 'services.label.ref',
        key: 'ref',
        width: '20%',
      },
      {
        head: 'services.label.libelle',
        key: 'label',
        width: '40%',
      },
      {
        head: 'services.label.title',
        key: 'name',
        width: '40%',
      },
    ],
    query: () => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return ServicesGql.fetchServicesForManager
        }
        if (usr?.role === 'logifim_coordinator') {
          return ServicesGql.fetchServicesForCoordinator
        }
      }

      return ServicesGql.getServices
    },
    variables: (limit, offset) => {
      const data = {
        first: limit,
        offset,
      }

      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (
          ['logifim_manager', 'logifim_coordinator'].indexOf(usr?.role) !== -1
        ) {
          data.idRole = usr.id
        }
        if (usr.role === 'logifim_service_provider') {
          data.idProvider = usr.id
        }
      }

      return data
    },
    transformQuery: (items) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          const usrs = []
          items?.residences?.nodes?.forEach((node) => {
            node?.residenceServicesByIdResidence?.nodes?.forEach((user) => {
              if (!usrs.find((u) => u.id === user?.serviceByIdService?.id)) {
                usrs.push(user.serviceByIdService)
              }
            })
          })
          return usrs
        }

        if (usr?.role === 'logifim_coordinator') {
          const usrs = []
          items?.residenceCoordinations?.nodes?.forEach((node) => {
            node?.residenceByIdResidence?.residenceServicesByIdResidence?.nodes?.forEach(
              (user) => {
                if (!usrs.find((u) => u.id === user?.serviceByIdService?.id)) {
                  usrs.push(user.serviceByIdService)
                }
              },
            )
          })
          return usrs
        }
      }
      return items.services.nodes
    },
    hasPreviousPage: (data) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return data.residences.hasPreviousPage
        }
        if (usr?.role === 'logifim_coordinator') {
          return data.residenceCoordinations.hasPreviousPage
        }
      }
      return data.services.pageInfo.hasPreviousPage
    },
    hasNextPage: (data) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return data.residences.hasNextPage
        }
        if (usr?.role === 'logifim_coordinator') {
          return data.residenceCoordinations.hasNextPage
        }
      }
      return data.services.pageInfo.hasNextPage
    },
    totalPage: (data) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return Math.ceil(data.residences.totalCount / LIMIT)
        }
        if (usr?.role === 'logifim_coordinator') {
          return Math.ceil(data.residenceCoordinations.totalCount / LIMIT)
        }
      }
      return Math.ceil(data.services.totalCount / LIMIT)
    },
    limit: LIMIT,
  },
  create:
    connectedUser?.role === 'logifim_service_provider'
      ? null
      : {
          title: 'services.label.create',
          form: [...form(true)],
          query: ServiceCategories.getAllServiceCategories,
          transformQuery: (item) => item.serviceCategories,
          getIdAfterSubmit: (data) => data.createService.service.id,
          submitQuery: (data, index) => {
            if (index === 0) {
              return ServicesGql.createService
            }
          },
          submit: (data) => ({
            label: data.label,
            name: data.name,
            idServiceCategory: data.idServiceCategory,
            ref: data.ref,
            idServiceProvider: data.idExternal,
            description: data.description,
            url: data.url,
          }),
        },
  update: {
    title: 'services.label.update',
    form: [
      ...form(false),
      {
        id: 'residence',
        type: 'custom',
        component: (props) => <ResidenceService {...props} />,
      },
    ],
    getLink: (item) => item.id,
    query: ServicesGql.getServiceWithCategory,
    transformQuery: (item) => ({
      ...item.service,
    }),
    submitQuery: (data, index) => {
      if (index === 0) {
        return ServicesGql.updateService
      }
    },
    submit: (id, data) => ({
      id,
      label: data.label,
      name: data.name,
      idServiceCategory: data.idServiceCategory,
      ref: data.ref,
      idServiceProvider:
        connectedUser?.role === 'logifim_service_provider'
          ? connectedUser?.id
          : data.idExternal,
      description: data.description,
      url: data.url,
    }),
  },
  read: {
    form: [
      {
        id: 'residence',
        type: 'custom',
        component: (props) => <ResidenceService {...props} />,
      },
      {
        id: 'ref',
        type: 'text',
        placeholder: 'services.placeholder.ref',
        label: 'services.label.ref',
        required: true,
      },
      {
        id: 'label',
        type: 'text',
        placeholder: 'services.placeholder.label',
        label: 'services.label.libelle',
        required: true,
      },
      {
        id: 'name',
        type: 'text',
        placeholder: 'services.placeholder.title',
        label: 'services.label.title',
        required: true,
      },
      {
        id: 'description',
        type: 'textarea',
        placeholder: 'services.placeholder.description',
        label: 'services.label.description',
        required: true,
        disabled: false,
      },
      {
        id: 'visuel',
        type: 'custom',
        component: (props) => <Photos {...props} read={true} />,
        url: (params, withToken = true) => {
          let url = `${config.API_BASE_URL}services/${params.id}/assets/visuel.png`
          if (withToken) {
            url += `?token=${localStorage.getItem('accessToken')}`
          }
          return url
        },
        label: 'services.label.visuel',
        deletable: false,
        needToSubmit: async (id, data) => {
          if (data && data.img && data.type) {
            const token = localStorage.getItem('accessToken')
            const url = `${config.API_BASE_URL}services/${id}/assets/visuel.png`
            const formdata = new FormData()
            formdata.append('asset', dataURItoBlob(data.img))
            return axios.post(url, formdata, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            })
          }
        },
      },
      {
        id: 'files',
        type: 'custom',
        component: (props) => (
          <Files {...props} read={true} url="services" multiple={true} />
        ),
        url: (params, name, withToken = true) => {
          let url = `${config.API_BASE_URL}services/${params.id}/assets/${name}`
          if (withToken) {
            url += `?token=${localStorage.getItem('accessToken')}`
          }
          return url
        },
        label: 'services.label.files',
        needToSubmit: async (id, data) => {
          if (data && data.length > 0) {
            const token = localStorage.getItem('accessToken')
            data.forEach((d) => {
              const url = `${
                config.API_BASE_URL
              }services/${id}/assets/${d.name.replace(/\s/g, '_')}`
              const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              }
              if (d.status === 'added') {
                const formdata = new FormData()
                formdata.append('asset', dataURItoBlob(d.file))
                return axios.post(url, formdata, { headers })
              }
              if (d.status === 'deleteFromServer') {
                return axios.delete(url, { headers })
              }
            })
          }
        },
      },
      {
        id: 'url',
        type: 'text',
        placeholder: 'services.placeholder.url',
        label: 'services.label.url',
      },
      {
        id: 'picto',
        type: 'custom',
        component: (props) => (
          <Photos {...props} indications="300*300px" read={true} />
        ),
        url: (params, withToken = true) => {
          let url = `${config.API_BASE_URL}services/${params.id}/assets/picto.png`
          if (withToken) {
            url += `?token=${localStorage.getItem('accessToken')}`
          }
          return url
        },
        label: 'services.label.image',
        deletable: false,
        needToSubmit: async (id, data) => {
          if (data && data.img && data.type) {
            const token = localStorage.getItem('accessToken')
            const url = `${config.API_BASE_URL}services/${id}/assets/picto.png`
            const formdata = new FormData()
            formdata.append('asset', dataURItoBlob(data.img))
            return axios.post(url, formdata, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            })
          }
        },
      },
      {
        id: 'idServiceCategory',
        defaultValue: 'ui.defaultValue',
        placeholder: 'services.placeholder.category',
        label: 'services.label.category',
      },
      connectedUser?.role !== 'logifim_service_provider' && {
        id: 'provider',
        type: 'custom',
        label: 'services.label.provider',
        component: (props) => <ServiceProvider disabled={true} {...props} />,
      },
    ],
    getLink: (item) => item.id,
    query: ServicesGql.getServiceWithCategory,
    transformQuery: (i) => ({
      ...i.service,
      ref: i.service.ref,
      label: i.service.label,
      name: i.service.name,
      description: i.service.description,
      url: i.service.url,
      idServiceCategory: i.service.serviceCategoryByIdServiceCategory.label,
    }),
  },
  delete:
    connectedUser?.role === 'logifim_service_provider'
      ? null
      : {
          text: 'services.delete.confirm',
          query: ServicesGql.deleteService,
          getTitle: (item) => `${item.ref}-${item.name}`,
          errorMessage: 'services.delete.error',
        },
}
