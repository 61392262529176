/* eslint-disable react/display-name */
import React from 'react'
import { ResidenceServiceLinks } from '../components/automated/custom'
import { ResidenceService as ResidenceServiceGql } from '../gql'
import { Catalogues } from './Catalogues'

export const ResidencesForService = {
  name: 'ResidencesForService',
  graphql: true,
  title: 'services.title',
  parentUrl: '/services',
  secondParentUrl: (id) => `/services/edit/${id}`,
  url: (id) => `/services/edit/${id?.substr(37, 45)}`,
  query: ResidenceServiceGql?.getResidenceServicesById,
  transformQuery: (items) =>
    items?.residenceService?.residenceByIdResidence?.name,
  secondQuery: ResidenceServiceGql.getServiceNameAndResidenceName,
  transformSecondQuery: (items) =>
    items?.residenceService?.serviceByIdService?.name,
  redirectionAfterUpdate: true,
  list: {
    table: [
      {
        head: 'residences.label.title',
        key: 'name',
        width: '40%',
      },
      {
        head: 'residences.label.citylist',
        key: 'cityByIdCity.name',
        width: '40%',
      },
      {
        head: 'residences.label.status',
        key: 'status',
        width: '20%',
      },
    ],
  },
  update: {
    title: 'residences.services.update',
    url: (parentId, id) => `/services-residence/edit/${id}|${parentId}`,
    form: [
      {
        id: 'links',
        type: 'custom',
        component: (props) => <ResidenceServiceLinks {...props} />,
      },
     /* {
        id: 'residentPrice',
        type: 'text',
        placeholder: 'residences.placeholder.resident_price',
        label: 'residences.label.resident_price',
      }, */
      {
        id: 'idResidentCatalogue',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        model: Catalogues,
        placeholder: 'residences.placeholder.resident_catalog',
        label: 'residences.label.resident_catalog',
      },
     /* {
        id: 'externalPrice',
        type: 'text',
        placeholder: 'residences.placeholder.external_price',
        label: 'residences.label.external_price',
      }, */
      {
        id: 'idExternalCatalogue',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        model: Catalogues,
        placeholder: 'residences.placeholder.external_catalog',
        label: 'residences.label.external_catalog',
      },
      {
        type: 'submit',
        label: 'residences.label.submit',
      },
    ],
    getLink: (item) => item.id,
    query: ResidenceServiceGql.getResidenceServicesById,
    variable: (params) => {
      const ids = params.id.split('|')
      return {
        id: ids[0],
      }
    },
    transformQuery: (item) => ({
      ...item,
      ...item.residenceService,
    }),
    submitQuery: (data, index) => {
      if (index === 0) {
        return ResidenceServiceGql.updateResidenceService
      }
    },
    submit: (id, data, lang, params) => {
      const ids = params.id.split('|')
      return {
        id: ids[0],
        idExternalCatalogue:
          data.idExternalCatalogue !== '' ? data.idExternalCatalogue : null,
        idResidentCatalogue:
          data.idResidentCatalogue !== '' ? data.idResidentCatalogue : null,
        /* residentPrice: data.residentPrice !== '' ? data.residentPrice : null,
        externalPrice: data.externalPrice !== '' ? data.externalPrice : null, */
      }
    },
  },
  delete: {
    text: 'residences.services.delete.confirm',
    query: ResidenceServiceGql.deleteResidenceService,
    getTitle: (item) => `${item.name}`,
    errorMessage: 'residences.services.delete.error',
  },
}
