/* eslint-disable react/display-name */
import { ResidenceServiceResources, ResidenceService } from '../gql'
import config from '../config'

const { LIMIT } = config

export const Ressources = {
  graphql: true,
  name: 'ServiceResource',
  title: 'ressources.titleResourceService',
  thirdtitle: 'ressources.thirdtitle',
  parentUrl: '/services',
  thirdUrl: (id) => `/services/edit/${id}`,
  secondParentUrl: (id) => `/services-residence/edit/${id}`,
  url: (id) => `/services-residence/edit/${id}`,
  query: ResidenceService?.getResidenceServicesById,
  transformQuery: (items) => items.residenceService.residenceByIdResidence.name,
  secondQuery: ResidenceService.getServiceNameAndResidenceName,
  transformSecondQuery: (items) =>
    items?.residenceService?.serviceByIdService?.name,
  list: {
    queryInputAutocompletion: ResidenceService.getRessourcesForResidence,
    // eslint-disable-next-line max-len
    transformQueryInputAutocompletion: (item) =>
      item?.residenceService?.residenceByIdResidence?.resourcesByIdResidence
        ?.nodes,
    table: [
      {
        head: 'ressources.label.name',
        key: 'name',
        width: '30%',
      },
      {
        head: 'ressources.label.description',
        key: 'description',
        width: '70%',
      },
    ],
    query: ResidenceServiceResources.getResidenceServiceResources,
    variables: (
      limit,
      offset,
      filterDateBegin,
      filterDateEnd,
      orderBy,
      status,
      residenceSelected,
      lang,
      sortType,
      sortDirection,
      props,
      params,
    ) => {
      const ids = params.resId.split('|')
      return {
        first: limit,
        offset,
        id: ids[0],
      }
    },
    transformQuery: (items) =>
      items.residenceServiceResources.nodes.map(
        (res) => res.resourceByIdResource,
      ),
    hasPreviousPage: (data) =>
      data.residenceServiceResources.pageInfo.hasPreviousPage,
    hasNextPage: (data) => data.residenceServiceResources.pageInfo.hasNextPage,
    totalPage: (data) =>
      Math.ceil(data.residenceServiceResources.totalCount / LIMIT),
    limit: LIMIT,
  },
  delete: {
    text: 'ressources.delete.confirm',
    query: ResidenceServiceResources.deleteResidenceServiceResource,
    variables: (currentItem, props, params) => {
      const ids = params.resId.split('|')
      return {
        idResidenceService: ids[0],
        idResource: currentItem.id,
      }
    },
    getTitle: (item) => `${item.name}`,
  },
}
