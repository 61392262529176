import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  FormInput,
} from 'shards-react'
import config from '../../../config'
import { ResidenceServiceResources } from '../../../gql'
import { captureMessageWithAttachment } from '../../../lib/sentry'

const TextInputAutoCompleted = ({
  onChangeCustom,
  field,
  autoCompleteList,
  }) => {
  const { t } = useTranslation()
  const [autoComplete, setAutoComplete] = useState(false)
  const [closeAutocomplete, setCloseAutoComplete] = useState(false)
  const [itemChoosen, setItemChoosen] = useState()
  const params = useParams()

    let list = null
    if (autoCompleteList) {
      list = autoCompleteList.filter((item) => item?.name?.toLowerCase()
        .includes(field?.toLowerCase()))
        .slice(0, 20)
    }

    let fetchGrahQlItems = () => {}
    try {
      const fetchItem = useLazyQuery(ResidenceServiceResources.getResidenceServiceResources, {
        fetchPolicy: config.fetchPolicy,
        notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
        variables: {
          id: params.resId.substr(0, 36),
        },
      })
      fetchGrahQlItems = fetchItem[0]
    } catch (e) {
      console.log(e)
    }

  const [pushServiceResidence] = useMutation(
    ResidenceServiceResources.createResidenceServiceResource, {
      residenceServiceResource: {
        clientMutationId: null,
        __typename: 'residenceServiceResourcePayload',
      },
      onCompleted: () => {
        fetchGrahQlItems()
      },
      onError: (err) => {
        const request = ResidenceServiceResources.createResidenceServiceResource
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: {
            idResidenceService: params.resId.substr(0, 36),
            idResource: itemChoosen.id,
          },
        }
        captureMessageWithAttachment(event, err)
      },
      },
    )

  useEffect(() => {
    if (field && !closeAutocomplete) {
      setAutoComplete(true)
    } else {
      setAutoComplete(false)
      setCloseAutoComplete(false)
    }
  }, [field, closeAutocomplete])

  return (
    <div className="uiInput_autocomplete">
      {autoComplete && autoCompleteList && list.length > 0 && (
      <div className="uiInput_listAutocomplete" style={{ display: 'flex', flexDirection: 'column', width: '400px', position: 'absolute', top: '65px', zIndex: '5', border: '1px solid gray', borderRadius: '5px' }}>
        {list.map((item) => (
          <button
            key={item.id}
            type="button"
            onClick={() => {
              setAutoComplete(false)
              setItemChoosen(item)
              pushServiceResidence({
                variables: {
                  idResidenceService: params.resId.substr(0, 36),
                  idResource: item.id,
                },
              })
            }}
            style={{ backgroundColor: 'white', border: 'none', borderBottom: '1px solid gray' }}
          >
            {item.name}
          </button>
         ))}
      </div>
      )}
      <FormInput
        id="addWithAutoCompletion"
        type="text"
        onChange={onChangeCustom}
        value={field}
        style={{
            width: '400px',
          }}
        placeholder={t('InputAutoCompleted.placeholder')}
        autoComplete="off"
      />
    </div>
    )
}

TextInputAutoCompleted.propTypes = {
  onChangeCustom: PropTypes.func,
  field: PropTypes.string,
  autoCompleteList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
}

TextInputAutoCompleted.defaultProps = {
  onChangeCustom: () => {},
  autoCompleteList: null,
  field: '',
}

export default TextInputAutoCompleted
