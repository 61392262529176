/* eslint-disable react/display-name */
import config from '../config'
import { Issue } from '../gql'

const { LIMIT } = config

export const IssueTypes = {
  graphql: true,
  title: 'issueTypes.title',
  query: Issue.fetchIssueType,
  transformQuery: (items) => items.issueType.label,
  url: '/issue-types',
  list: {
    title: 'issueTypes.list',
    table: [
      {
        head: 'issueTypes.label.label',
        key: 'label',
        width: '25%',
      },
    ],
    query: Issue.fetchIssueTypes,
    variables: (limit, offset) => ({
      first: limit,
      offset,
    }),
    transformQuery: (items) => items.issueTypes.nodes,
    hasPreviousPage: data => data.issueTypes.pageInfo.hasPreviousPage,
    hasNextPage: data => data.issueTypes.pageInfo.hasNextPage,
    totalPage: data => Math.ceil(data.issueTypes.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    title: 'issueTypes.label.create',
    form: [
      {
        id: 'label',
        type: 'text',
        placeholder: 'issueTypes.placeholder.title',
        label: 'issueTypes.label.title',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'issueTypes.label.submit',
            md: 2,
          },
        ],
      },
    ],
    getIdAfterSubmit: (data) => data.createIssueType.issueType.id,
    submitQuery: (data, index) => {
      if (index === 0) {
        return Issue.createIssueType
      }
    },
    submit: (data) => ({
      label: data.label,
    }),
  },
  update: {
    title: 'issueTypes.label.update',
    getLink: (item) => item.id,
    query: Issue.fetchIssueType,
    transformQuery: (item) => item.issueType,
    submitQuery: (data, index) => {
      if (index === 0) {
        return Issue.updateIssueType
      }
      return null
    },
    submit: (id, data) => ({
      id,
      label: data.label,
    }),
  },
  delete: {
    text: 'catalogues.delete.confirm',
    query: Issue.deleteIssueType,
    getTitle: (item) => `${item.label}`,
    errorMessage: 'catalogues.delete.errorIssues',
  },
}
