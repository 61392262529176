import { gql } from '@apollo/client'

const getPlanning = gql`
  query getPlanning($id: UUID!) {
    residenceService(id: $id) {
      id
      residenceByIdResidence {
        id
        name
        cityByIdCity {
          id
          name
          departmentByIdDepartment {
            num
          }
        }
      }
      serviceByIdService {
        id
        name
      }
      serviceStatusByIdServiceStatus {
        id
        name
      }
      isForExternal
      isForResident
      residenceServiceAvailabilityRangesByIdResidenceService(
        orderBy: START_DATETIME_DESC
      ) {
        nodes {
          id
          repeatParameterValue
          repeatValue
          startDatetime
          endDatetime
          serviceRepeatParameterByIdRepeatParameter {
            id
            name
          }
          serviceRepeatUnitByIdRepeatUnit {
            id
            name
          }
        }
      }
      endDatetime
      availabilityMode
      startDatetime
      prepareDelay
    }
    serviceStatuses {
      nodes {
        name
        id
      }
    }
    serviceRepeatParameters {
      nodes {
        id
        name
      }
    }
    serviceRepeatUnits {
      nodes {
        id
        name
      }
    }
  }
`

const createResidenceServiceAvailabilityRange = gql`
  mutation createResidenceServiceAvailabilityRange(
    $idResidenceService: UUID!,
    $endDatetime: Datetime!,
    $startDatetime: Datetime!,
    $idRepeatParameter: UUID,
    $idRepeatUnit: UUID,
    $repeatParameterValue: Int,
    $repeatValue: Int,
  ) {
    createResidenceServiceAvailabilityRange(
      input: {residenceServiceAvailabilityRange: {
        startDatetime: $startDatetime,
        endDatetime: $endDatetime,
        idResidenceService: $idResidenceService,
        idRepeatParameter: $idRepeatParameter,
        idRepeatUnit: $idRepeatUnit,
        repeatParameterValue: $repeatParameterValue,
        repeatValue: $repeatValue,
      }}
    ) {
      clientMutationId
    }
  }
`

const deleteResidenceServiceAvailabilityRange = gql`
  mutation deleteResidenceServiceAvailabilityRange($id: UUID!) {
    deleteResidenceServiceAvailabilityRange(input: {id: $id}) {
      clientMutationId
    }
  }
`

export default {
  getPlanning,
  createResidenceServiceAvailabilityRange,
  deleteResidenceServiceAvailabilityRange,
}
