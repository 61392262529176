import React from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import config from '../config'
import { dataURItoBlob } from '../lib'
import { News as NewsGql } from '../gql'
import { SearchPerimeterNews, Photos } from '../components/automated/custom'

const { LIMIT } = config

const form = (create = true) => ([
  {
    id: 'title',
    type: 'text',
    placeholder: 'news.placeholder.title',
    label: 'news.placeholder.title',
    required: true,
  },
  {
    id: 'body',
    type: 'textarea',
    placeholder: 'news.placeholder.message',
    label: 'news.label.message',
    required: true,
  },
  {
    id: 'visuel',
    type: 'custom',
    component: (props) => (<Photos {...props} wording={create ? 'ui.addImage' : 'ui.updateImage'} />),
    url: (params, withToken = true) => {
      let url = `${config.API_BASE_URL}news/${params.id}/picture`
      if (withToken) {
        url += `?token=${localStorage.getItem('accessToken')}`
      }
      return url
    },
    label: 'services.label.visuel',
    deletable: false,
    needToSubmit: async (id, data) => {
      if (data && data.img && data.type) {
        const token = localStorage.getItem('accessToken')
        const url = `${config.API_BASE_URL}news/${id}/picture`
        const formdata = new FormData()
        formdata.append('picture', dataURItoBlob(data.img))
        return axios.post(
          url,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        )
      }
    },
  },
  {
    type: 'row',
    items: [
      {
        md: 6,
        id: 'startDate',
        type: 'datetimepicker',
        time: true,
        placeholder: 'news.placeholder.choosedate',
        label: 'news.label.startdate',
      },
      {
        md: 6,
        id: 'endDate',
        type: 'datetimepicker',
        time: true,
        placeholder: 'news.placeholder.choosedate',
        label: 'news.label.enddate',
      },
    ],
  },
  {
    id: 'scope',
    type: 'select',
    defaultValue: 'ui.defaultValue',
    label: 'news.label.perimeter',
    placeholder: 'news.label.perimeter',
    required: true,
    data: [
      {
        label: 'Global',
        value: 'Global',
      },
      {
        label: 'Residence',
        value: 'Residence',
      },
      {
        label: 'Resident',
        value: 'Resident',
      },
    ],
  },
  {
    type: 'custom',
    id: 'perimeter',
    component: (props) => <SearchPerimeterNews {...props} />,
  },
  {
    type: 'row',
    items: [
      {
        type: 'back',
        label: 'ui.cancel',
        md: 2,
      },
      {
        type: 'submit',
        label: 'news.label.submit',
        md: 2,
      },
    ],
  },
])

export const News = {
  graphql: true,
  title: 'news.title',
  url: '/news',
  list: {
    table: [
      {
        head: 'news.label.title',
        key: 'title',
        width: '25%',
      },
      {
        head: 'news.label.perimeter',
        key: 'perimeter',
        width: '25%',
      },
      {
        head: 'news.label.message',
        key: 'body',
        width: '25%',
      },
    ],
    query: NewsGql.getNews,
    transformQuery: (items) =>
      items.newses.nodes.map((i) => {
        let perimeter
        if (i.idResidence) {
          perimeter = `Résidence : ${i.residenceByIdResidence.name}`
        } else if (i.idResident) {
          perimeter = `Résident : ${i.residentByIdResident.lastname} ${i.residentByIdResident.firstname}`
        } else {
          perimeter = 'Global'
        }
        const item = {
        ...i,
        title: i.title,
        perimeter,
        body: i.body,
        }
        return item
      }),
    variables: (limit, offset) => ({
      first: limit,
      offset,
    }),
    hasPreviousPage: (data) => data.newses.pageInfo.hasPreviousPage,
    hasNextPage: (data) => data.newses.pageInfo.hasNextPage,
    totalPage: (data) => Math.ceil(data.newses.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    title: 'news.label.create',
    form: form(true),
    getIdAfterSubmit: (data) => data.createNews.news.id,
    transformQuery: (item) => item,
    submitQuery: (data, index) => {
      if (index === 0) {
        return NewsGql.createNews
      }
    },
    submit: (data) => ({
      title: data.title,
      body: data.body,
      idResidence: data.residenceId ? data.residenceId : null,
      idResident: data.idResident ? data.idResident : null,
      startDate: data.startDate ? new Date(data.startDate) : new Date(),
      endDate: data.endDate ? new Date(data.endDate) : 'infinity',
    }),
  },
  update: {
    title: 'news.label.update',
    form: form(false),
    getLink: (item) => item.id,
    query: NewsGql.fetchNewsMessage,
    transformQuery: (i) => {
      let scope
      if (i.news.idResidence) {
        scope = 'Residence'
      } else if (i.news.idResident) {
        scope = 'Resident'
      } else {
        scope = 'Global'
      }

      const item = {
        ...i.news,
        scope,
        perimeter: {
          idResidence: { fromDb: i.news.residenceByIdResidence?.name },
          idResident: { fromDb: i.news.residentByIdResident },
        },
        startDate: new Date(i.news?.publicationRange?.start?.value),
        endDate: i.news?.publicationRange?.end?.value === 'infinity' ? undefined : new Date(i.news?.publicationRange?.end?.value),
      }
      return item
    },
    submitQuery: (data, index) => {
      if (index === 0) {
        return NewsGql.updateNews
      }
      return null
    },
    submit: (id, data) => {
      let residence
      if (data.idResident) {
        residence = null
      } else if (data.residenceId) {
          residence = data.residenceId
        } else {
          residence = undefined
        }

        let resident
        if (data.residenceId) {
          resident = null
        } else if (data.idResident) {
          resident = data.idResident
        } else {
          resident = undefined
        }

      const item = {
      id,
      title: data.title,
      body: data.body,
      idResidence: residence,
      idResident: resident,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
    }
    return item
  },
},
  read: {
    form: [
      {
        label: 'news.placeholder.title',
        id: 'title',
        width: '25%',
      },
      {
        label: 'news.label.message',
        id: 'message',
        width: '25%',
      },
      {
        type: 'row',
        items: [
          {
            md: 6,
            id: 'startDate',
            label: 'news.label.startdate',
          },
          {
            md: 6,
            id: 'endDate',
            label: 'news.label.enddate',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'perimeter',
            label: 'news.label.perimeter',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'status',
            label: 'news.label.status',
          },
        ],
      },
    ],
    getLink: (item) => item.id,
    variables: (id) => ({
      id,
    }),
    query: NewsGql.fetchNewsMessage,
    transformQuery: (i) => {
      let perimeter
      if (i.news.idResidence) {
        perimeter = `résidence: ${i.news.residenceByIdResidence.name} `
      } else if (i.news.idResident) {
        perimeter = `Résident: ${i.news.residentByIdResident.lastname} ${i.news.residentByIdResident.firstname}`
      } else {
        perimeter = 'Global'
      }
      const item = {
        ...i,
        title: i.news.title,
        message: i.news.body,
        startDate: `${dayjs(i.news?.publicationRange?.start?.value).format('DD/MM/YYYY  HH:mm')}`,
        endDate: i.news?.publicationRange?.end?.value === 'infinity' ? undefined : `${dayjs(i.news?.publicationRange?.end?.value).format('DD/MM/YYYY  HH:mm')}`,
        perimeter,
        status: i.news.isRead ? '' : 'non lu',
        residence: i.news.residenceByIdResidence?.name
          ? i.news.residenceByIdResidence.name
          : '',
        resident: i.news.residentByIdResident?.lastname
          ? i.news.residentByIdResident.lastname
          : '',
      }
      return item
    },
  },
  delete: {
    text: 'news.delete.confirm',
    query: NewsGql.deleteNews,
    getTitle: (item) => `${item.title}`,
  },
}
