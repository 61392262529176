import { useLazyQuery } from '@apollo/client'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Button, Card, CardBody, CardHeader, Col, FormFeedback, FormGroup,
  FormInput, FormTextarea, ListGroup,
  ListGroupItem, Row,
} from 'shards-react'
import config from '../../../config'
import UserProvider from '../../../gql/UserProvider'
import { populateFormAfterApi, userCanDoAction } from '../../../lib'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import { customStyles } from './style'

const AutomatedUpdate = ({ model, id }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [data, setData] = useState({})
  // used to popupate select, radio, checkbox when data is from api
  const [populatedForm, setPopulatedForm] = useState([])

  const params = useParams()
  const { subId } = params
  const { pathname } = useLocation()

  const formFromModel = model.read.form || model.create.form

  const loadDataAsync = async () => {
    const form = {
      ...populatedForm,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.model) {
            const dataToPopulate = await field.model.populateForm()
            form[field.id] = dataToPopulate
            setPopulatedForm(form)
          }
        })
      } else if (row.model) {
        const dataToPopulate = await row.model.populateForm()
        form[row.id] = dataToPopulate
        setPopulatedForm(form)
      }
    })
  }

  let fetchGrahQlItem = () => {}
  try {
    const fetchItem = useLazyQuery(pathname.includes('/provider') ? UserProvider.getUserProviderById : model.read.query, {
      fetchPolicy: config.fetchPolicy,
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      variables: {
        id,
      },
      onError: (err) => {
        const request = model.read.query
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: { id },
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (itemToUpdate) => {
        populateFormAfterApi(pathname.includes('/provider') ? itemToUpdate.external : model.read.transformQuery(itemToUpdate), data, formFromModel, setData)
        loadDataAsync(itemToUpdate)
      },
    })
    fetchGrahQlItem = fetchItem[0]
  } catch (e) {
    console.log(e)
  }

  const loadItemAsync = async () => {
    if (config.CONNECTOR === 'graphql' && model.graphql) {
      fetchGrahQlItem()
    } else {
      const item = await model.read.getItem(id, i18n.language, subId)
      const tData = {
        ...data,
      }

      formFromModel.forEach(async (row) => {
        if (row.type === 'row') {
          row.items.forEach((field) => {
            if (field.id && item[field.id]) {
              tData[field.id] = item[field.id]
              setData(tData)
            }
          })
        } else if (row.id && item[row.id]) {
          tData[row.id] = item[row.id]
          setData(tData)
        }
      })
    }
  }

  useEffect(() => {
    if (model.create?.authorizedRole && !userCanDoAction(model.create?.authorizedRole)) {
      history.push({
        pathname: model.url && typeof model.url === 'function' ? model.url(null, params) : model.url,
        state: { noAccess: true },
      })
    }

    if (config.CONNECTOR !== 'graphql') {
      loadDataAsync()
    }
    loadItemAsync()
  }, [])

  const makeMyInput = (field) => {
    switch (field.type) {
      case 'wysiwyg':
      case 'textarea':
        return (
          <>
            <label htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && (<span>*</span>)}
            </label>
            <FormTextarea
              id={`fe${field.id}`}
              value={data[field.id]}
              rows="5"
              disabled
            />
            <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
          </>
        )
      case 'button':
        return (
          <Button
            style={{ width: '175px', backgroundColor: '#32A0E6', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none' }}
            className={field.className}
            onClick={() => {
              if (field.history) {
                history.push(field.history(id))
              } else {
                field.onClick(id)
              }
            }}
          >
            {t(field.label)}
          </Button>
        )
      case 'custom':
        return (
          <>
            <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && (<span>*</span>)}
            </label>
            {data && field.component({
              model,
              id,
              data,
              field,
            })}
          </>
        )
      case 'back':
        return (
          <Button
            type="button"
            style={{ width: '175px', backgroundColor: '#FFFF', color: '#485156', border: 'none', boxShadow: '0 1px 3px rgba(0,0,0,.2)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => {
              history.push(model.url && typeof model.url === 'function' ? model.url(id) : model.url)
            }}
          >
            <p className="p-0 m-0 font-weight-bold">{t(field.label)}</p>
          </Button>
        )
      default:
        return (
          <>
            <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
              {t(field.label)}
              {field.required && (<span>*</span>)}
            </label>
            <FormInput
              id={`fe${field.id}`}
              type={field.type}
              value={data[field.id]}
              placeholder={t(field.placeholder)}
              disabled
            />
            <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
            <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
          </>
        )
    }
  }

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          {data && model.read.title && (
          <CardHeader className="border-bottom">
            {model.parentUrl && (
              <p>
                {model.url && typeof model.url === 'function' ? (
                  <a href={model.url(id)}>
                    &larr;
                    &nbsp;
                    {t('ui.back')}
                  </a>
                  ) : (
                    <a href={model.url}>
                      &larr;
                      &nbsp;
                      {t('ui.back')}
                    </a>
                )}
              </p>
            )}
            <h6 className="m-0">
              {t(model.read.title)}
            </h6>
          </CardHeader>
          )}
          <CardBody className="p-0 pb-3">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    {formFromModel.map((item, indexRow) => (
                      <div key={`row${indexRow}`}>
                        {item.type === 'row' ? (
                          <>
                            {item.right ? (
                              <Row style={{ ...customStyles.divButtons }} className="mr-1">
                                {item.items.map((field, index) => (
                                  <div className="p-1" key={`col${index}`}>
                                    { makeMyInput(field) }
                                  </div>
                                  ))}
                              </Row>
                                ) : (
                                  <Row form>
                                    {item.items.map((field, index) => (
                                      <Col md={field.md} className="form-group" key={`col${index}`}>
                                        { makeMyInput(field) }
                                      </Col>
                                ))}
                                  </Row>
                                )}
                          </>
                            ) : (
                              <FormGroup>
                                { makeMyInput(item) }
                              </FormGroup>
                            )}
                      </div>
                      ))}
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

AutomatedUpdate.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
}

export default AutomatedUpdate
