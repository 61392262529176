import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormInput,
  Button,
} from 'shards-react'
import jwtDecode from 'jwt-decode'
import {
  useHistory,
  useLocation,
  Link,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import backgroundImage from '../images/login.svg'

import api from '../api'
import logo from '../images/logo.png'
import config from '../config'

const Login = () => {
  const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  const history = useHistory()
  const location = useLocation()
  const { state } = location
  const { t } = useTranslation()
  const [email, setEmail] = useState(isLocal ? 'admin@test.com' : '')
  const [password, setPassword] = useState(isLocal ? '12345' : '')
  const [noAccess, setNoAccess] = useState(state ? state.noAccess : false)
  const [error, setError] = useState(false)
  const [mailMissing, setMailMissing] = useState(false)
  const [pwdMissing, setPwdMissing] = useState(false)
  const mediaMatch = window.matchMedia('(max-width: 480px)')
  const [matches, setMatches] = useState(mediaMatch.matches)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addListener(handler)
    return () => mediaMatch.removeListener(handler)
  })

  const onSubmit = async (event) => {
    event.preventDefault()
    setNoAccess(false)
    setError(false)
    setMailMissing(false)
    setPwdMissing(false)
    if (email === '' || password === '') {
      if (email === '') {
        setMailMissing(true)
      }
      if (password === '') {
        setPwdMissing(true)
      }
      return
    }

    try {
      const user = await api.loginWithCredentials(email, password)
      if (user) {
        const usr = jwtDecode(user.access_token)
        if (config.authorizedRole.indexOf(usr.role) !== -1) {
          localStorage.setItem('accessToken', user.access_token)
          localStorage.setItem('refreshToken', user.refresh_token)
          history.push('/')
        } else {
          setNoAccess(true)
        }
      }
    } catch (e) {
      console.log(e)
      setError(true)
    }
  }

  const styles = {
    title: {
      fontSize: matches ? '2.5em' : '4.5em',
      paddingLeft: matches ? '10vw' : '0',
      color: '#485156',
      fontFamily: 'Source Serif Pro',
    },
    subTitle: {
      fontSize: matches ? '1em' : '1.5em',
      paddingLeft: matches ? '18vw' : '0',
      color: '#485156',
      opacity: '0.6',
      fontFamily: 'Source Sans Pro',
      fontWeight: '700',
    },
  }

  return (
    <Container fluid className="main-content-container" style={{ padding: 0 }}>
      <Row
        style={{
          height: '100vh',
        }}
      >
        <Col
          style={{
            backgroundColor: 'rgba(198, 207, 0, 0.2)',
            position: 'relative',
          }}
        >
          <div className="p-5 col-xs-6 ">
            <h4
              style={styles.title}
            >
              résid’
            </h4>
            <p className="pt-4" style={styles.subTitle}>Portail de connexion V-Services</p>
          </div>
          <img
            alt="background"
            src={backgroundImage}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 100,
              maxHeight: '64%',
              maxWidth: '40vw',
            }}
          />
        </Col>
        <Col sm={{ size: 8 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
          <div style={{ width: '70%', height: '60%', paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="mb-5">
              <img
                id="main-logo"
                style={{ width: '62px', height: '56px' }}
                className="mb-3"
                src={logo}
                alt="V-Services"
              />
              <h5 className="m-0 pb-1 mt-3" style={{ fontWeight: '700', fontSize: '2.25rem', color: '#485156', fontFamily: 'Source Sans Pro' }}>{t('login.title')}</h5>
              <p className="mb-2" style={{ fontSize: '1.5rem', color: '#485156', fontWeight: '400', fontFamily: 'Source Sans Pro' }}>
                {t('login.subTitle')}
              </p>
            </div>

            <Form>
              <FormGroup>
                <label style={{ fontSize: '0.875rem', fontWeight: '600', color: '#485156', fontFamily: 'Source Sans Pro' }} htmlFor="#username">{t('login.username')}</label>
                {mailMissing && (
                  <FormFeedback
                    invalid
                    style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
                  >
                    {t('form.fieldRequired')}
                  </FormFeedback>
                )}
                <FormInput
                  id="#username"
                  value={email}
                  placeholder={t('login.username')}
                  onChange={(event) => {
                        setEmail(event.target.value)
                      }}
                />
              </FormGroup>
              <FormGroup>
                <label style={{ fontSize: '0.875rem', fontWeight: '600', color: '#485156' }} htmlFor="#password">{t('login.password')}</label>
                {pwdMissing && (
                  <FormFeedback
                    invalid
                    style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
                  >
                    {t('form.fieldRequired')}
                  </FormFeedback>
                )}
                <FormInput
                  id="#password"
                  type="password"
                  placeholder={t('login.password')}
                  value={password}
                  onChange={(event) => {
                      setPassword(event.target.value)
                    }}
                />
                <Link to={{ pathname: '/forgotPWD', state: { emailProps: email } }} exact className="pl-0" style={{ textDecoration: 'underline', color: '#00AFA0', fontSize: 14 }}>{t('login.forgotLogin')}</Link>
              </FormGroup>
              {error && (
              <FormFeedback
                invalid
                style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
              >
                {t('form.fieldLoginMismatch')}
              </FormFeedback>
                )}
              {noAccess && (
                <FormFeedback
                  invalid
                  style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
                >
                  {t('form.fieldLoginNoAccess')}
                </FormFeedback>
              )}
              <FormGroup style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="submit"
                  onClick={onSubmit}
                  style={{ backgroundColor: 'rgba(110, 60, 165, 0.8)', width: '100%', borderRadius: '5px', height: '50px', fontSize: '1.25rem', fontWeight: '700', fontFamily: 'Source Sans Pro' }}
                >
                  {t('login.submit')}
                </Button>
              </FormGroup>
              {noAccess && (<p style={{ color: 'red' }}>{t('login.noAccess')}</p>)}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

Login.propTypes = {
  // model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Login
