import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import {
  CardSubtitle,
} from 'shards-react'
import { ResidenceService } from '../../../gql'
import { List } from '../form'
import { ServicesForResidence } from '../../../models'
import config from '../../../config'
import { captureMessageWithAttachment } from '../../../lib/sentry'

const ServiceResidence = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [residences, setResidence] = useState([])

  const [fetchResidences] = useLazyQuery(ResidenceService.getServicesByResidences, {
    fetchPolicy: config.fetchPolicy,
    notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
    variables: {
      id,
    },
    onError: (err) => {
      const request = ResidenceService.getServicesByResidences
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: { id },
      }
      captureMessageWithAttachment(event, err)
    },
    onCompleted: (data) => {
      setResidence(data.residenceServices.nodes.map((node) => ({
        ...node.serviceByIdService,
        id: node.id,
      })))
    },
  })

  useEffect(() => {
    fetchResidences()
  }, [])

  return (
    <div className="category-container">
      <CardSubtitle>{t('services.label.services')}</CardSubtitle>
      {residences.length > 0 && (
        <List {...{
          model: ServicesForResidence,
          dataToShow: residences,
          noHeader: true,
          noFooter: true,
          id,
          reload: () => fetchResidences(),
        }}
        />
      )}
    </div>
  )
}

export default ServiceResidence
