import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import {
  Button,
} from 'shards-react'

const RessourceLinks = () => {
  const { t } = useTranslation()
  const { resId, id } = useParams()
  const history = useHistory()
  const style = {
    width: '300px',
    backgroundColor: '#32A0E6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    marginRight: '10px',
  }

  return (
    <div>
      <Button
        style={style}
        onClick={() => {
          history.push({
            pathname: `/ressource-planning/${resId}/edit/${id}`,
          })
        }}
      >
        <p className="p-0 m-0 font-weight-bold">{t('ressources.planning.button')}</p>
      </Button>
    </div>
  )
}

export default RessourceLinks
