export const MaritalStatuses = {
    graphql: true,
    populateForm: (items) => {
        const form = []
        items.maritalStatuses.nodes?.forEach(status => {
        form.push({
            label: status.name,
            value: status.id,
        })
    })
    return form
    },
}
