const data = () => [
  {
    title: 'navbar.residences',
    htmlBefore: '<i class="material-icons">apartment</i>',
    to: '/residences',
    pathForAuthorizedRole: '/residences',
  },
  {
    title: 'navbar.users.title',
    htmlBefore: '<i class="material-icons">people</i>',
    opened: true,
    alwaysOpen: false,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
    subItems: [
      {
        title: 'navbar.users.internal',
        htmlBefore: '<i class="material-icons">person</i>',
        to: 'residents',
        pathForAuthorizedRole: '/residents',
      },
      {
        title: 'navbar.users.external',
        htmlBefore: '<i class="material-icons">person</i>',
        to: 'externes',
        pathForAuthorizedRole: '/externes',
      },
      {
        title: 'navbar.users.admin',
        htmlBefore: '<i class="material-icons">person</i>',
        to: 'admin',
        pathForAuthorizedRole: '/admin',
      },
      {
        title: 'navbar.users.coordinators',
        htmlBefore: '<i class="material-icons">person</i>',
        to: 'coordinator',
        pathForAuthorizedRole: '/coordinator',
      },
      {
        title: 'navbar.users.manager',
        htmlBefore: '<i class="material-icons">person</i>',
        to: 'manager',
        pathForAuthorizedRole: '/manager',
      },
      {
        title: 'navbar.users.provider',
        htmlBefore: '<i class="material-icons">person</i>',
        to: 'provider',
        pathForAuthorizedRole: '/provider',
      },
    ],
  },
  {
    title: 'navbar.categories',
    htmlBefore: '<i class="material-icons">category</i>',
    to: '/categories',
    pathForAuthorizedRole: '/categories',
  },
  {
    title: 'navbar.services',
    htmlBefore: '<i class="material-icons">cleaning_services</i>',
    to: '/services',
    pathForAuthorizedRole: '/services',
  },
  {
    title: 'navbar.ressources',
    htmlBefore: '<i class="material-icons">book</i>',
    to: '/ressourcesList',
    pathForAuthorizedRole: '/ressourcesList',
  },
  {
    title: 'navbar.catalogues',
    htmlBefore: '<i class="material-icons">shopping_cart</i>',
    to: '/catalogues',
    pathForAuthorizedRole: '/catalogues',
  },
  {
    title: 'navbar.contactReasons',
    htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
    to: '/contact-reasons',
    pathForAuthorizedRole: '/contact-reasons',
  },
  {
    title: 'navbar.contact',
    htmlBefore: '<i class="material-icons">support_agent</i>',
    to: '/contact',
    pathForAuthorizedRole: '/contact',
  },
  {
    title: 'navbar.issueTypes',
    htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
    to: '/issue-types',
    pathForAuthorizedRole: '/issue-types',
  },
  {
    title: 'navbar.issue',
    htmlBefore: '<i class="material-icons">support_agent</i>',
    to: '/issues',
    pathForAuthorizedRole: '/issues',
  },
  {
    title: 'navbar.news',
    htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
    to: '/news',
    pathForAuthorizedRole: '/news',
  },
  {
    title: 'navbar.ordered',
    htmlBefore: '<i class="material-icons">shopping_cart</i>',
    to: '/ordered',
    pathForAuthorizedRole: '/ordered',
  },
  {
    title: 'navbar.faq',
    htmlBefore: '<i class="material-icons">live_help</i>',
    to: '/faq',
    pathForAuthorizedRole: '/faq',
  },
]

export default data
