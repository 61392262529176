import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Button } from 'shards-react'
import api from '../../../api'

const Files = ({
  onChangeCustom,
  field,
  wording,
  url,
  oneFile,
  read = false,
}) => {
  const params = useParams()
  const { id } = params
  const inputFile = useRef(null)
  const [files, setFiles] = useState([])
  const { t } = useTranslation()
  useEffect(async () => {
    if (id) {
      let fls
      if (oneFile) {
        const response = await api.getFile(params.id, url, field.id)
        if (response) {
          fls = [field.id, 'picto.png', 'visuel.png']
        }
      } else {
        fls = await api.getFiles(params.id, url)
      }
      setFiles(
        fls
          .filter((file) => ['picto.png', 'visuel.png'].indexOf(file) === -1)
          .map((name) => ({ name, status: 'fromServer' })),
      )
    }
  }, [])

  const loadFile = (e) => {
    const selectedFile = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (event) => {
      let fs = []
      if (oneFile) {
        fs = [
          {
            file: event.target.result,
            status: 'added',
            name: field.id,
          },
        ]
      } else {
        fs = [
          ...files,
          {
            file: event.target.result,
            status: 'added',
            name: selectedFile.name,
          },
        ]
      }
      setFiles(fs)
      onChangeCustom(field.id, fs)
    }
    reader.readAsDataURL(selectedFile)
  }

  return (
    <div className="category-container">
      {!read && (
        <Button
          theme="primary"
          className="mb-2 mr-1"
          onClick={() => {
            inputFile.current.click()
          }}
        >
          {wording ? t(wording) : t('ui.addFile')}
        </Button>
      )}
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={loadFile}
      />
      {files.length > 0 && (
        <div>
          {files.map((fs, idx) => {
            if (fs.status.indexOf('delete') !== -1) {
              return <></>
            }

            return (
              <div key={`file${fs.name}${idx}`}>
                <button
                  type="button"
                  style={{ background: 'none', border: 0, marginRight: '5px' }}
                  onClick={() => {
                    const filesAfterDelete = [...files]
                    filesAfterDelete[idx].status =
                      filesAfterDelete[idx].status === 'fromServer'
                        ? 'deleteFromServer'
                        : 'delete'
                    setFiles(filesAfterDelete)
                    onChangeCustom(field.id, filesAfterDelete)
                  }}
                >
                  x
                </button>
                {fs.status === 'fromServer' ? (
                  <a href={field.url(params, fs.name)}>{fs.name}</a>
                ) : (
                  fs.name
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

Files.propTypes = {
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  wording: PropTypes.string,
  url: PropTypes.string.isRequired,
  oneFile: PropTypes.bool,
  read: PropTypes.bool,
}

Files.defaultProps = {
  onChangeCustom: () => {},
  wording: '',
  oneFile: false,
  read: false,
}

export default Files
