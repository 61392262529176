const BookingStatus = [
  { value: 'NEW', label: 'Nouvelle' },
  { value: 'PENDING', label: 'En attente' },
  { value: 'VALIDATED', label: 'Validée' },
  { value: 'IN_PROGRESS', label: 'En cours' },
  { value: 'REFUSED', label: 'Refusée' },
  { value: 'ABONDONNED', label: 'Abandonnée' },
  { value: 'CLOSED', label: 'Terminée' },
]

export default BookingStatus
