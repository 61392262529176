import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CardSubtitle,
  FormSelect,
  Row,
  Col,
  Button,
} from 'shards-react'
import { ResidenceService as ResidenceServiceGql, Residences } from '../../../gql'
import { List } from '../form'
import { ResidencesForService } from '../../../models'
import config from '../../../config'
import { captureMessageWithAttachment } from '../../../lib/sentry'

const ResidenceService = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [residences, setResidence] = useState([])
  const [residencesBackup, setResidenceBackup] = useState([])
  const [residencesInSelect, setResidenceInSelect] = useState([])
  const [cities, setCities] = useState([])
  const [city, setCity] = useState('')
  const [res, setRes] = useState('')

  const [fetchResidences] = useLazyQuery(Residences.getResidences, {
    fetchPolicy: config.fetchPolicy,
    notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
    onCompleted: (data) => {
      setResidenceBackup(data.residences.nodes)
      setResidenceInSelect(data.residences.nodes)

      const ct = []
      data.residences.nodes.forEach((c) => {
        if (!ct.find((a) => a.name === c.cityByIdCity.name)) {
          ct.push(c.cityByIdCity)
        }
      })
      setCities(ct)
    },
  })

  const [fetchResidencesByServiceId] = useLazyQuery(ResidenceServiceGql.getResidenceServices, {
    fetchPolicy: config.fetchPolicy,
    notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
    variables: {
      id,
    },
    onError: (err) => {
      const request = ResidenceServiceGql.getResidenceServices
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: { id },
      }
      captureMessageWithAttachment(event, err)
    },
    onCompleted: (data) => {
      const rs = []
      data.residenceServices.nodes.forEach((node) => {
        rs.push({
          id: node.id,
          ...node.residenceByIdResidence,
        })
      })
      setResidence(rs)
    },
  })

  let submitGraphQl
  let mutationLoading
  let mutationError
  try {
    const mutation = useMutation(ResidenceServiceGql.createResidenceService, {
      variables: {
        idResidence: res,
        idService: id,
      },
      onError: (err) => {
        const request = ResidenceServiceGql.createResidenceService
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: {
            idResidence: res,
            idService: id,
          },
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (() => {
        fetchResidencesByServiceId()
      }),
    })
    submitGraphQl = mutation[0]
    mutationLoading = mutation[1].loading
    mutationError = mutation[1].error
  } catch (e) {
    console.log(e)
  }

  useEffect(() => {
    fetchResidences()
    fetchResidencesByServiceId()
  }, [])

  useEffect(() => {
    if (city === '') {
      setResidenceInSelect(residencesBackup)
    } else {
      const rs = []
      residencesBackup.forEach((c) => {
        if (c.cityByIdCity.id === city) {
          rs.push(c)
        }
      })
      setResidenceInSelect(rs)
    }
  }, [city])

  return (
    <div className="category-container">
      <CardSubtitle>{t('services.label.residence')}</CardSubtitle>
      {residences.length > 0 ? (
        <List {...{
          model: ResidencesForService,
          dataToShow: residences,
          noHeader: true,
          noFooter: true,
          id,
          reload: () => {
            fetchResidencesByServiceId()
          },
        }}
        />
      ) : (
        <p>{t('services.label.noResidenceAssociated')}</p>
      )}
      <Row form>
        <Col md="md-3" className="form-group">
          <FormSelect
            id="feCity"
            value={city}
            onChange={((event) => {
              setCity(event.target.value)
            })}
          >
            <option value="">{t('residences.services.chooseCity')}</option>
            {cities.length > 0 && cities.map((option, index) => (
              <option value={option.id} key={`optionCity${index}`}>{option.name}</option>
            ))}
          </FormSelect>
        </Col>
        <Col md="md-3" className="form-group">
          <FormSelect
            id="feRes"
            value={res}
            onChange={((event) => {
              setRes(event.target.value)
            })}
          >
            <option value="">{t('residences.services.chooseResidence')}</option>
            {residencesInSelect.length > 0 && residencesInSelect.map((option, index) => (
              <option value={option.id} key={`optionRes${index}`}>{option.name}</option>
            ))}
          </FormSelect>
        </Col>
        <Col md="md-3" className="form-group">
          <Button
            style={{ width: '175px', backgroundColor: '#32A0E6', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none' }}
            disabled={res === '' || mutationLoading}
            onClick={() => {
              submitGraphQl()
            }}
          >
            {!mutationLoading && (<p className="p-0 m-0 font-weight-bold">{t('residences.services.addRes')}</p>)}
            {mutationLoading && (<p className="p-0 m-0 font-weight-bold">{t('residences.services.loading')}</p>)}
          </Button>
        </Col>
      </Row>
      {mutationError && (<p className="p-0 m-0 font-weight-bold">{t('residences.services.error')}</p>)}
    </div>
  )
}

export default ResidenceService
