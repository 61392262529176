import axios from 'axios'
import config from '../config'
import {
  isUserTokenExpired,
} from '../lib'

const api = axios.create({
  baseURL: config.API_BASE_URL,
})

api.defaults.headers.post['Content-Type'] = 'application/json'

const post = async (path, data = null, headers = {}) => {
  const res = await api({
    method: 'post',
    url: path,
    data,
    headers,
  })
  return res.data
}

const get = async (path, params = {}, cancelToken = null) => {
  const conf = {
    method: 'get',
    url: path,
    params,
  }

  if (cancelToken) {
    conf.cancelToken = cancelToken
  }

  const res = await api(conf)
  return res.data
}

const put = async (path, data, headers = {}) => {
  const res = await api({
    method: 'put',
    url: path,
    data,
    headers,
  })
  return res.data
}

const del = async (path, data) => {
  const res = await api({
    method: 'delete',
    url: path,
    data,
  })
  return res.data
}

const loginWithCredentials = async (login, password) => {
  const response = await post(config.LOGIN_URL, { login, password })
  return response
}

const getFiles = async (id, url) => get(`/${url}/${id}/assets`)
const getFile = async (id, url, fileName) => get(`/${url}/${id}/assets/${fileName}`)

const getToken = async () => get('/token')

const checkFile = async (url) => get(url)

const forgotPwd = async (login) => post('/password/forgot', { login })

const resetPwd = async (password, token) => put(`/password?token=${token}`, { password })

const requestWithAuthorization = (request, token) => ({
  ...request,
  headers: {
    ...request.headers,
    Authorization: `bearer ${token}`,
  },
})

api.interceptors.request.use((cfg) => {
  const originalRequest = cfg
  const isLogin = cfg.url.indexOf('/token') !== -1

  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')
  if (accessToken && !isLogin) {
    if (isUserTokenExpired(accessToken)) {
      return getToken().then(
        (user) => {
          localStorage.setItem('accessToken', user.access_token)
          localStorage.setItem('refreshToken', user.refresh_token)
          return Promise.resolve(requestWithAuthorization(originalRequest, user.access_token))
        },
        () => Promise.reject(new Error('E_REFRESH_TOKEN_FAILED')),
      )
    }
  }
  let token = null
  if (accessToken) {
    token = (cfg.method === 'get' && isLogin) ? refreshToken : accessToken
  }
  if (token) {
    return requestWithAuthorization(cfg, token)
  }
  return cfg
}, (error) => Promise.reject(error))

export default {
  get,
  post,
  put,
  del,
  loginWithCredentials,
  getToken,
  checkFile,
  forgotPwd,
  resetPwd,
  getFiles,
  getFile,
}
