import { gql } from '@apollo/client'

const getResidenceServices = gql`
  query getResidenceServices($id: UUID!) {
    residenceServices(
      filter: {idService: {equalTo: $id}}
    ) {
      nodes {
        id
        availabilityMode
        residenceByIdResidence {
          name
          cityByIdCity {
            name
            departmentByIdDepartment {
              num
            }
          }
        }
      }
    }
  }
`

const createResidenceService = gql`
  mutation createResidenceService(
    $idResidence: UUID!,
    $idService: UUID!,
    $availabilityMode: ServiceAvailabilityMode = PERMANENT,
    $idServiceStatus: UUID = "0081cfee-97c2-4d1f-9e1b-16772fd413df"
  ) {
    createResidenceService(
      input: {
        residenceService: {
          idResidence: $idResidence,
          idService: $idService,
          availabilityMode: $availabilityMode,
          idServiceStatus: $idServiceStatus,
        }
      }
    ) {
      clientMutationId
    }
  }
`

const deleteResidenceService = gql`
  mutation deleteResidenceService($id: UUID!) {
    deleteResidenceService(input: {id: $id}) {
      clientMutationId
    }
  }
`

const getResidenceServicesById = gql`
query getResidenceServicesById($id: UUID!) {
  residenceService(id: $id) {
    id
    idExternalCatalogue
    externalPrice
    idResidentCatalogue
    residentPrice
    residenceByIdResidence {
      name
    }
  }
  catalogs {
    nodes {
      label
      id
    }
  }
}
`
const getServiceNameAndResidenceName = gql`
query getServiceNameAndResidenceName($id: UUID!) {
  residenceService(id: $id) {
    id
    serviceByIdService {
      id
      name
    }
    residenceByIdResidence {
      name
      id
    }
  }
}
`

const getResidenceServicesForSetup = gql`
  query getResidenceServicesForSetup($id: UUID!) {
    residenceService(id: $id) {
      id
      bookingLimit
      bookingLength
      bookingType
      isAutomatedBooking
      isCommentedBooking
      isBookable
    }
  }
`

const updateResidenceService = gql`
  mutation updateResidenceService(
    $id: UUID!,
    $idExternalCatalogue: UUID,
    $idResidentCatalogue: UUID,
    $residentPrice: BigFloat,
    $externalPrice: BigFloat,
  ) {
    updateResidenceService(
      input: {
        patch: {
          idExternalCatalogue: $idExternalCatalogue,
          idResidentCatalogue: $idResidentCatalogue,
          residentPrice: $residentPrice,
          externalPrice: $externalPrice,
        }, id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const updateResidenceServiceForSetup = gql`
  mutation updateResidenceService(
    $id: UUID!,
    $bookingLimit: Int,
    $bookingType: ServiceBookingType,
    $bookingLength: Int,
    $isAutomatedBooking: Boolean,
    $isBookable: Boolean,
    $isCommentedBooking: Boolean,
  ) {
    updateResidenceService(
      input: {
        patch: {
          bookingLimit: $bookingLimit,
          bookingType: $bookingType,
          bookingLength: $bookingLength,
          isAutomatedBooking: $isAutomatedBooking,
          isBookable: $isBookable,
          isCommentedBooking: $isCommentedBooking,
        }, id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const getServicesByResidences = gql`
  query getServicesByResidences($id: UUID!) {
    residenceServices(filter: {idResidence: {equalTo: $id}}) {
      nodes {
        id
        serviceByIdService {
          label
          name
          ref
          id
        }
      }
    }
  }
`

const updateResidenceServiceForPlanning = gql`
  mutation updateResidenceService(
    $id: UUID!,
    $idServiceStatus: UUID!,
    $availabilityMode: ServiceAvailabilityMode!,
    $isForExternal: Boolean!,
    $isForResident: Boolean!,
    $prepareDelay: Int,
    $startDatetime: Datetime,
    $endDatetime: Datetime,
  ) {
    updateResidenceService(
      input: {
        patch: {
          idServiceStatus: $idServiceStatus,
          availabilityMode: $availabilityMode,
          isForExternal: $isForExternal,
          isForResident: $isForResident,
          prepareDelay: $prepareDelay,
          startDatetime: $startDatetime,
          endDatetime: $endDatetime,
        }, id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const getRessourcesForResidence = gql`
query getRessourcesForResidence($id: UUID!) {
  residenceService(id: $id) {
    id
    residenceByIdResidence {
      resourcesByIdResidence {
        nodes {
          name
          id
        }
      }
    }
  }
}
`

export default {
  getResidenceServices,
  createResidenceService,
  deleteResidenceService,
  getResidenceServicesById,
  updateResidenceService,
  getResidenceServicesForSetup,
  updateResidenceServiceForSetup,
  getServicesByResidences,
  updateResidenceServiceForPlanning,
  getServiceNameAndResidenceName,
  getRessourcesForResidence,
}
