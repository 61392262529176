import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import routes from './routes'
import withTracker from './withTracker'
import PrivateRoute from './components/PrivateRoute'
import config from './config'
import { initSentry } from './lib/sentry'

import './i18n'

import 'bootstrap/dist/css/bootstrap.min.css'
import './shards-dashboard/styles/shards-dashboards.1.1.0.min.css'
import './App.css'

initSentry()

const httpLink = createHttpLink({
  uri: config.GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken')
  if (!token) {
    return {
      headers,
    }
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }
})

const client = new ApolloClient({
  connectToDevTools: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

const RouteConfig = () => (
  <Router basename={process.env.REACT_APP_BASENAME || ''}>
    <div>
      {routes.map((route, index) => {
        if (route.public) {
          return (
            <Route
              key={`route${index}`}
              path={route.path}
              exact={route.exact}
              component={withTracker((props) => (
                <route.layout {...props}>
                  <route.component {...props} model={route.model} />
                </route.layout>
              ))}
            />
          )
        }

        return (
          <PrivateRoute
            key={`route${index}`}
            path={route.path}
            exact={route.exact}
            authorizedRole={route.authorizedRole}
            component={withTracker((props) => (
              <route.layout {...props}>
                <route.component
                  {...props}
                  custom={route.props ? route.props : {}}
                  model={route.model}
                  authorizedRole={route.authorizedRole}
                />
              </route.layout>
            ))}
          />
        )
      })}
    </div>
  </Router>
)

const App = () => (
  <>
    {config.CONNECTOR === 'graphql' ? (
      <ApolloProvider client={client}>
        <RouteConfig />
      </ApolloProvider>
    ) : (
      <RouteConfig />
    )}
  </>
)

export default App
