import React from 'react'
import { Redirect } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import config from './config'

// Layout Types
import { DefaultLayout, LoginLayout } from './layouts'

// Route Views
import RessourcesView from './views/Ressources'
import Login from './views/Login'
import FgtPassword from './views/FgtPassword'
import ResetPwd from './views/ResetPwd'
import Automated from './views/Automated'
import {
  Residences,
  Residents,
  Staffs,
  Externals,
  Categories,
  Services,
  Catalogues,
  Planning,
  ResidencesForService,
  Registers,
  Ressources,
  PlanningManagement,
  AddRessourceToResidence,
  RessourcePlanning,
  ContactReasons,
  Contacts,
  IssueTypes,
  Issues,
  News,
  Ordered,
  Faq,
} from './models'

const component = () => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    const usr = jwtDecode(accessToken)
    switch (usr.role) {
      case 'logifim_service_provider':
        return <Redirect to="/services" />
      default:
        return <Redirect to="/residences" />
    }
  }

  return <Redirect to="/residences" />
}

const routes = [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component,
  },
  {
    path: '/login',
    layout: LoginLayout,
    component: Login,
    public: true,
  },
  {
    path: '/forgotPWD',
    layout: LoginLayout,
    component: FgtPassword,
    public: true,
  },
  {
    path: '/reset-password',
    layout: LoginLayout,
    component: ResetPwd,
    public: true,
  },
  {
    path: '/residences',
    layout: DefaultLayout,
    component: Automated,
    model: Residences,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/residents',
    layout: DefaultLayout,
    component: Automated,
    model: Residents,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/externes',
    layout: DefaultLayout,
    component: Automated,
    model: Externals,
    authorizedRole: ['logifim_admin'],
    props: { roleName: 'logifim_external' },
  },
  {
    path: '/admin',
    layout: DefaultLayout,
    component: Automated,
    model: Staffs,
    authorizedRole: ['logifim_admin'],
    props: { path: '/admin', roleId: config.ID_ROLES.admin },
  },
  {
    path: '/coordinator',
    layout: DefaultLayout,
    component: Automated,
    model: Staffs,
    authorizedRole: ['logifim_admin'],
    props: { path: '/coordinator', roleId: config.ID_ROLES.coordinator },
  },
  // {
  //   path: '/manager',
  //   layout: DefaultLayout,
  //   component: Automated,
  //   model: Staffs,
  //   authorizedRole: ['logifim_admin'],
  //   props: { path: '/manager', roleId: 37495 },
  // },
  {
    path: '/provider',
    layout: DefaultLayout,
    component: Automated,
    model: Staffs,
    authorizedRole: ['logifim_admin'],
    props: { path: '/provider', roleName: 'logifim_service_provider' },
  },
  // {
  //   path: '/components-overview',
  //   layout: DefaultLayout,
  //   component: ComponentsOverview,
  // },
  {
    path: '/categories',
    layout: DefaultLayout,
    component: Automated,
    model: Categories,
    authorizedRole: ['logifim_admin'],
  },
  {
    path: '/services',
    layout: DefaultLayout,
    component: Automated,
    model: Services,
    authorizedRole: [
      'logifim_admin',
      'logifim_coordinator',
      'logifim_service_provider',
    ],
  },
  {
    path: '/ressourcesList',
    layout: DefaultLayout,
    component: RessourcesView,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/catalogues',
    layout: DefaultLayout,
    component: Automated,
    model: Catalogues,
    authorizedRole: ['logifim_admin', 'logifim_coordinator', 'logifim_service_provider'],
  },
  {
    path: '/planning',
    layout: DefaultLayout,
    component: Automated,
    model: Planning,
    authorizedRole: [
      'logifim_admin',
      'logifim_coordinator',
      'logifim_service_provider',
    ],
  },
  {
    path: '/services-residence',
    layout: DefaultLayout,
    component: Automated,
    model: ResidencesForService,
    authorizedRole: [
      'logifim_admin',
      'logifim_coordinator',
      'logifim_service_provider',
    ],
  },
  {
    path: '/register/:resId',
    layout: DefaultLayout,
    component: Automated,
    model: Registers,
    authorizedRole: ['logifim_admin', 'logifim_coordinator', 'logifim_service_provider'],
  },
  {
    path: '/services-ressources/:resId',
    layout: DefaultLayout,
    component: Automated,
    model: Ressources,
    authorizedRole: [
      'logifim_admin',
      'logifim_coordinator',
      'logifim_service_provider',
    ],
  },
  {
    path: '/planning-management',
    layout: DefaultLayout,
    component: Automated,
    model: PlanningManagement,
    authorizedRole: [
      'logifim_admin',
      'logifim_coordinator',
      'logifim_service_provider',
    ],
  },
  {
    path: '/ressource-management/:resId',
    layout: DefaultLayout,
    component: Automated,
    model: AddRessourceToResidence,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/ressource-planning/:resId',
    layout: DefaultLayout,
    component: Automated,
    model: RessourcePlanning,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/contact-reasons',
    layout: DefaultLayout,
    component: Automated,
    model: ContactReasons,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/contact',
    layout: DefaultLayout,
    component: Automated,
    model: Contacts,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/issue-types',
    layout: DefaultLayout,
    component: Automated,
    model: IssueTypes,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/issues',
    layout: DefaultLayout,
    component: Automated,
    model: Issues,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/news',
    layout: DefaultLayout,
    component: Automated,
    model: News,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
  {
    path: '/ordered',
    layout: DefaultLayout,
    component: Automated,
    model: Ordered,
    authorizedRole: [
      'logifim_admin',
      'logifim_coordinator',
      'logifim_service_provider',
    ],
  },
  {
    path: '/faq',
    layout: DefaultLayout,
    component: Automated,
    model: Faq,
    authorizedRole: ['logifim_admin', 'logifim_coordinator'],
  },
]

export default routes
