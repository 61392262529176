import { useLazyQuery, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Col, FormSelect, Row } from 'shards-react'
import { Services, UsersProvider } from '../../../gql'

export default function ServiceProvider({ onChangeCustom, disabled = false }) {
  const { t } = useTranslation('translation')
  const [provider, setProvider] = useState('')
  const [providers, setProviders] = useState()
  const params = useParams()
  const { id } = params

  const [fetchProviders] = useLazyQuery(UsersProvider.fetchProvider, {
    onCompleted: (dataProvider) => {
      setProviders(dataProvider.externals.nodes)
    },
  })

  const [fetchService] = useLazyQuery(Services.getServiceWithCategory, {
    onCompleted: (data) => {
      setProvider(data.service.idServiceProvider)
    },
  })

  const { data: dataRole } = useQuery(UsersProvider.fetchProviderRoleId, {
    onCompleted: () => {
      const array = dataRole.viewRoles.nodes.filter(
        (el) => el.rolname === 'logifim_service_provider',
      )
      fetchProviders({
        variables: {
          id: array[0].id,
        },
      })
    },
  })

  useEffect(() => {
    if (id) {
      fetchService({
        variables: {
          id,
        },
      })
    }
  }, [])

  return (
    <Row form>
      <Col md="md-3" className="form-group">
        <FormSelect
          disabled={disabled}
          id="feCity"
          value={provider}
          onChange={(event) => {
            setProvider(event.target.value)
            onChangeCustom('idExternal', event.target.value)
          }}
        >
          <option value="">{t('servicesProvider')}</option>
          {providers?.length > 0 &&
            providers.map((option, index) => (
              <option value={option.id} key={`optionCity${index}`}>
                {`${option.firstname} ${option.lastname}`}
              </option>
            ))}
        </FormSelect>
      </Col>
    </Row>
  )
}

ServiceProvider.propTypes = {
  onChangeCustom: PropTypes.func,
  disabled: PropTypes.bool,
}

ServiceProvider.defaultProps = {
  onChangeCustom: () => {},
  disabled: false,
}
