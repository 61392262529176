import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useLazyQuery } from '@apollo/client'
import { FormInput, Col, Row } from 'shards-react'
import { useTranslation } from 'react-i18next'
import config from '../../../config'
import { List } from '../form'
import { Ordered } from '../../../models'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import { Residences, ResidenceServiceBookings, Services } from '../../../gql'

const { LIMIT } = config

const SearchOrderedByResidence = () => {
  const { t } = useTranslation()
  const [term, setTerm] = useState('')
  const [searchTerm, setSearchTerm] = useState([])
  const [results, setResults] = useState([])
  const [error, setError] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [offset, setOffset] = useState(0)
  const [itemInMemory, setItemInMemrory] = useState()
  const [havePrevPage, setHavePrevPage] = useState()
  const [haveNextPage, setHaveNextPage] = useState()

  const [searchByService, { data: service }] = useLazyQuery(
    Services.searchServicesByName,
    {
      fetchPolicy: config.fetchPolicy,
      onError: (err) => {
        const request = Services.searchServicesByName
        const event = {
          request: request?.definitions[0]?.name?.value,
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: () => {
        setResults(service.services?.nodes || [])
      },
    },
  )

  const [fetchNewResults, { data: dataResults }] = useLazyQuery(
    Residences.searchResidenceByName,
    {
      fetchPolicy: config.fetchPolicy,
      onError: (err) => {
        const request = Residences.searchResidenceByName
        const event = {
          request: request?.definitions[0]?.name?.value,
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: () => {
        if (dataResults.residences.nodes.length > 0) {
          setResults(dataResults.residences?.nodes || [])
        } else {
          searchByService({
            variables: {
              name: `%${term}%`,
            },
          })
        }
      },
    },
  )

  const [SearchByResidence, { data: resultOrdered }] = useLazyQuery(
    ResidenceServiceBookings.getResidenceServicesBookingsByIdResidence,
    {
      fetchPolicy: config.fetchPolicy,
      onError: (err) => {
        const request =
          ResidenceServiceBookings.getResidenceServicesBookingsByIdResidence
        const event = {
          request: request?.definitions[0]?.name?.value,
        }
        setError(true)
        captureMessageWithAttachment(event, err)
      },
      onCompleted: () => {
        if (resultOrdered.residenceServiceBookings.nodes) {
          console.log('==========>', resultOrdered?.residenceServiceBookings?.pageInfo.hasNextPage)

          setHavePrevPage(resultOrdered?.residenceServiceBookings?.pageInfo?.hasPreviousPage)
          setHaveNextPage(resultOrdered?.residenceServiceBookings?.pageInfo?.hasNextPage)

          setError(false)
          const items = [...resultOrdered.residenceServiceBookings.nodes]
          setSearchResults(
            items.map((item) => ({
              ...item,
              startDatetime: dayjs(item.startDatetime).format(
                'DD/MM/YYYY HH:mm',
              ),
              endDatetime: dayjs(item.endDatetime).format('DD/MM/YYYY HH:mm'),
              lastname:
                item.externalByIdExternal?.lastname ||
                item.residentByIdResident?.lastname,
              firstname:
                item.externalByIdExternal?.firstname ||
                item.residentByIdResident?.firstname,
              mobilePhoneNumber:
                item.externalByIdExternal?.mobilePhoneNumber ||
                item.residentByIdResident?.mobilePhoneNumber,
            })),
          )
        }
      },
    },
  )

  const [SearchByService, { data: resultOrderedByService }] = useLazyQuery(
    ResidenceServiceBookings.getServiceBookingByIdService,
    {
      fetchPolicy: config.fetchPolicy,
      onError: (err) => {
        const request = ResidenceServiceBookings.getServiceBookingByIdService
        const event = {
          request: request?.definitions[0]?.name?.value,
        }
        setSearchResults([])
        setError(true)
        captureMessageWithAttachment(event, err)
      },
      onCompleted: () => {
        setSearchResults([])
        if (resultOrderedByService.service.residenceServicesByIdService.nodes) {
          setHavePrevPage(resultOrderedByService?.service?.residenceServicesByIdService
            ?.nodes[1]?.residenceServiceBookingsByIdResidenceService?.pageInfo?.hasPreviousPage)
          setHaveNextPage(resultOrderedByService?.service?.residenceServicesByIdService
            ?.nodes[1]?.residenceServiceBookingsByIdResidenceService?.pageInfo?.hasNextPage)

          setError(false)
          resultOrderedByService.service.residenceServicesByIdService.nodes.forEach(
            (node) => {
              if (
                node.residenceServiceBookingsByIdResidenceService.nodes.length >
                0
              ) {
                const items = [
                  ...node.residenceServiceBookingsByIdResidenceService.nodes,
                ]
                setSearchResults(
                  items.map((item) => ({
                    ...item,
                    startDatetime: dayjs(item.startDatetime).format(
                      'DD/MM/YYYY HH:mm',
                    ),
                    endDatetime: dayjs(item.endDatetime).format(
                      'DD/MM/YYYY HH:mm',
                    ),
                    lastname:
                      item.externalByIdExternal?.lastname ||
                      item.residentByIdResident?.lastname,
                    firstname:
                      item.externalByIdExternal?.firstname ||
                      item.residentByIdResident?.firstname,
                    mobilePhoneNumber:
                      item.externalByIdExternal?.mobilePhoneNumber ||
                      item.residentByIdResident?.mobilePhoneNumber,
                  })),
                )
              }
            },
          )
        }
      },
    },
  )

  const styles = {
    border: '1px solid #e1e5eb',
    height: '40px',
    marginTop: 5,
    padding: '0 10px',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 400,
  }

  const changeResult = () => {
    if (term !== '') {
      fetchNewResults({
        variables: {
          name: `%${term}%`,
        },
      })
    } else if (term === '') {
      setResults([])
      setSearchTerm([])
    }
  }

  const addItem = (item) => {
    setSearchResults([])
    if (item.resourcesByIdResidence?.nodes.length > 0) {
      const ids = []
      item.resourcesByIdResidence?.nodes?.forEach((node) => {
        node.residenceServiceResourcesByIdResource?.nodes?.forEach((i) => {
          ids.push(i.idResidenceService)
        })
      })
      if (ids.length > 0) {
        setError(false)
        const clear = Array.from(new Set(ids))
        SearchByResidence({
          variables: {
            first: LIMIT,
            offset,
            idResidenceService: clear,
          },
        })
      }
    } else {
      setError(true)
    }
    if (item.residenceServicesByIdService?.nodes.length > 0) {
      setError(false)
      SearchByService({
        variables: {
          first: LIMIT,
          offset,
          id: item.residenceServicesByIdService?.nodes[0]?.idService,
        },
      })
    } else {
      setError(true)
    }
    setSearchTerm([item])
    setTerm('')
    setResults([])
  }

  const close = () => {
    setResults([])
    setSearchTerm([])
    setSearchResults([])
    setTerm('')
    setError(false)
  }

  useEffect(() => {
    if (term !== '') {
      changeResult(term)
    }
  }, [term])

  useEffect(() => {
    if (itemInMemory) {
      addItem(itemInMemory)
    }
  }, [offset])

  return (
    <Row>
      <Col>
        <i
          className="fas fa-search"
          style={{ position: 'absolute', left: '25px', zIndex: 2, top: '11px' }}
        />
        <FormInput
          id="tag"
          type="text"
          style={{
            paddingLeft: 30,
          }}
          value={term}
          placeholder={t('registers.placeholder.searchByResidenceOrService')}
          onChange={(event) => {
            setTerm(event.target.value)
            if (event.target.value === '') {
              close()
            }
          }}
        />
        {error && (
          <p
            style={{
              color: 'red',
              marginTop: '5px',
            }}
          >
            {t('editos.search.noContent')}
          </p>
        )}
        {results && results[0] && (
          <div className="mb-3">
            {results.map((r) => (
              <div
                key={r.id}
                style={{
                  border: '1px solid rgba(196, 196, 196, 0.4)',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="mt-1 pl-3"
              >
                <p className="p-0 m-0" style={{ minWidth: '15%' }}>
                  {r.name}
                </p>
                <button
                  type="button"
                  onClick={() => {
                    setItemInMemrory(r)
                    addItem(r)
                  }}
                  className="ml-3"
                  style={{ border: 'none' }}
                >
                  {t('ui.add')}
                </button>
              </div>
            ))}
          </div>
        )}
        {searchTerm &&
          searchTerm[0] &&
          searchTerm.map((r) => (
            <div className="d-flex" style={{ ...styles }} key={r.id}>
              <div
                className="d-flex"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p style={{ margin: 0 }}>{r.name}</p>
              </div>
            </div>
          ))}
        {searchResults && searchResults.length > 0 && (
          <div
            style={{
              position: 'fixed',
              zIndex: 9999,
              width: '89vw',
              left: '10vw',
              marginTop: '10px',
            }}
          >
            {console.warn('-----', offset)}
            <List
              {...{
                model: Ordered,
                close,
                closeButton: true,
                overrideNext: haveNextPage,
                overideNextButton: () => { setOffset(offset + LIMIT) },
                overridePrev: havePrevPage,
                overidePrevButton: () => { setOffset(offset - LIMIT) },
                dataToShow: searchResults,
                noHeader: true,
                noFooter: false,
              }}
            />
          </div>
        )}
      </Col>
    </Row>
  )
}

SearchOrderedByResidence.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default SearchOrderedByResidence
