/* eslint-disable react/display-name */
import dayjs from 'dayjs'
import { Ressources } from '../gql'
import config from '../config'

const { LIMIT } = config

export const ListPlanningForRessources = {
  graphql: true,
  list: {
    table: [
      {
        head: 'ressources.planning.startdate',
        key: 'startdate',
        width: '50%',
        expendable: false,
      },
      {
        head: 'ressources.planning.enddate',
        key: 'enddate',
        width: '50%',
        expendable: false,
      },
    ],
    query: Ressources.getRessourcePlanning,
    variables: (
      limit,
      offset,
      filterDateBegin,
      filterDateEnd,
      orderBy,
      status,
      residenceSelected,
      lang,
      sortType,
      sortDirection,
      props,
      params,
    ) => ({
      idResource: params.id,
    }),
    transformQuery: (items) =>
      items?.resourceBookings?.nodes?.map((booking) => ({
        ...booking,
        startdate: dayjs(booking.startDatetime).format('DD/MM/YYYY'),
        enddate: dayjs(booking.endDatetime).format('DD/MM/YYYY'),
      })),
    hasPreviousPage: (data) => data.resourceBookings.pageInfo.hasPreviousPage,
    hasNextPage: (data) => data.resourceBookings.pageInfo.hasNextPage,
    totalPage: (data) => Math.ceil(data.resourceBookings.totalCount / LIMIT),
    limit: LIMIT,
  },
}
