export const OrderedStatus = {
  graphql: true,
  populateForm: (items) => {
      const form = []
      let statusLabel
      items.bookingStatus.nodes?.forEach(status => {
      switch (status) {
        case 'NEW':
          statusLabel = 'Nouvelle'
          break
        case 'PENDING':
          statusLabel = 'En attente'
          break
        case 'VALIDATED':
          statusLabel = 'Validée'
          break
        case 'IN_PROGRESS':
          statusLabel = 'En cours'
          break
        case 'REFUSED':
          statusLabel = 'Refusée'
          break
        case 'ABONDONNED':
          statusLabel = 'Abandonnée'
          break
        case 'CLOSED':
          statusLabel = 'Terminée'
          break
        default:
          statusLabel = ''
      }
      form.push({
          label: statusLabel,
          value: status,
          id: status,
      })
  })
  return form
  },
}
