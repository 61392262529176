/* eslint-disable react/display-name */
import React from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import {
  AdminByResidence,
  InputsCity,
  ServiceResidence,
  Photos,
  Files,
} from '../components/automated/custom'
import AutomatedModalBatchDelete from '../components/automated/custom/AutomatedModalBatchDelete'
import { Residences as ResidencesGql } from '../gql'
import { dataURItoBlob } from '../lib'
import config from '../config'

const { LIMIT } = config
const form = (create = true) => ([
  {
    id: 'name',
    type: 'text',
    placeholder: 'residences.placeholder.title',
    label: 'residences.label.title',
    required: true,
  },
  {
    id: 'address',
    type: 'text',
    placeholder: 'residences.placeholder.address',
    label: 'residences.label.address',
    required: true,
  },
  {
    id: 'cityInputs',
    type: 'custom',
    component: (props) => (<InputsCity {...props} />),
  },
  {
    id: 'manager',
    type: 'custom',
    label: 'residences.label.manager',
    placeholder: 'residences.placeholder.manager',
    component: (props) => (<AdminByResidence {...props} />),
    query: ResidencesGql.fetchManagersByName(),
  },
  {
    id: 'coordinators',
    type: 'custom',
    label: 'residences.label.coordinators',
    placeholder: 'residences.placeholder.coordinators',
    component: (props) => (<AdminByResidence {...props} />),
    query: ResidencesGql.fetchCoordinatorsByName(),
  },
  {
    id: 'visuel',
    type: 'custom',
    component: (props) => (<Photos {...props} wording={create ? 'ui.addImage' : 'ui.updateImage'} />),
    url: (params, withToken = true) => {
      let url = `${config.API_BASE_URL}residences/${params.id}/assets/visuel.png`
      if (withToken) {
        url += `?token=${localStorage.getItem('accessToken')}`
      }
      return url
    },
    label: 'services.label.visuel',
    deletable: false,
    required: create,
    needToSubmit: async (id, data) => {
      if (data && data.img && data.type) {
        const token = localStorage.getItem('accessToken')
        const url = `${config.API_BASE_URL}residences/${id}/assets/visuel.png`
        const formdata = new FormData()
        formdata.append('asset', dataURItoBlob(data.img))
        return axios.post(
          url,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        )
      }
    },
  },
  {
    id: 'rulesfiles',
    type: 'custom',
    component: (props) => (<Files {...props} url="residences" oneFile={true} wording={create ? 'ui.addFile' : 'ui.updateFile'} />),
    url: (params, name, withToken = true) => {
      let url = `${config.API_BASE_URL}residences/${params.id}/assets/${name}`
      if (withToken) {
        url += `?token=${localStorage.getItem('accessToken')}`
      }
      return url
    },
    label: 'residences.label.rulesfiles',
    needToSubmit: async (id, data) => {
      if (data && data.length > 0) {
        const token = localStorage.getItem('accessToken')
        data.forEach((d) => {
          const url = `${config.API_BASE_URL}residences/${id}/assets/${d.name.replace(/\s/g, '_')}`
          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          }

          if (d.status === 'added') {
            const formdata = new FormData()
            formdata.append('asset', dataURItoBlob(d.file))
            return axios.post(
              url,
              formdata,
              { headers },
            )
          }

          if (d.status === 'deleteFromServer') {
            return axios.delete(
              url,
              { headers },
            )
          }
        })
      }
    },
  },
  {
    id: 'conditionfiles',
    type: 'custom',
    component: (props) => (<Files {...props} url="residences" oneFile={true} wording={create ? 'ui.addFile' : 'ui.updateFile'} />),
    url: (params, name, withToken = true) => {
      let url = `${config.API_BASE_URL}residences/${params.id}/assets/${name}`
      if (withToken) {
        url += `?token=${localStorage.getItem('accessToken')}`
      }
      return url
    },
    label: 'residences.label.conditionfiles',
    needToSubmit: async (id, data) => {
      if (data && data.length > 0) {
        const token = localStorage.getItem('accessToken')
        data.forEach((d) => {
          const url = `${config.API_BASE_URL}residences/${id}/assets/${d.name.replace(/\s/g, '_')}`
          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          }

          if (d.status === 'added') {
            const formdata = new FormData()
            formdata.append('asset', dataURItoBlob(d.file))
            return axios.post(
              url,
              formdata,
              { headers },
            )
          }

          if (d.status === 'deleteFromServer') {
            return axios.delete(
              url,
              { headers },
            )
          }
        })
      }
    },
  },
  {
    type: 'row',
    items: [
      {
        type: 'back',
        label: 'ui.cancel',
        md: 2,
      },
      {
        type: 'submit',
        label: 'residences.label.submit',
        md: 2,
      },
    ],
  },
])

export const Residences = {
  graphql: true,
  title: 'residences.title',
  query: ResidencesGql.getResidence,
  transformQuery: (items) => items?.residence?.name,
  url: '/residences',
  list: {
    table: [
      {
        head: 'residences.label.title',
        key: 'name',
        width: '25%',
      },
      {
        head: 'residences.label.zone',
        key: 'zone',
        width: '25%',
      },
      {
        head: 'residences.city',
        key: 'city',
        width: '20%',
      },
      {
        head: 'residences.coordination',
        key: 'coordinateur',
        width: '25%',
      },
    ],
    batchActions: {
      title: 'batchActions.label.title',
      defaultValue: 'batchActions.label.defaultValue',
      submitButton: 'batchActions.label.submit',
      items: [
        {
          authorizedRole: ['logifim_admin'],
          label: 'batchActions.delete.label',
          title: 'batchActions.delete.title',
          // eslint-disable-next-line react/display-name
          component: (props) => (<AutomatedModalBatchDelete {...props} />),
        },
      ],
      getTitle: (item) => item.title,
    },
    query: () => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return ResidencesGql.getResidencesForListManager
        }
        if (usr?.role === 'logifim_coordinator') {
          return ResidencesGql.getResidencesForListCoordinator
        }
      }
      return ResidencesGql.getResidencesForList
    },
    variables: (limit, offset) => {
      const data = {
        first: limit,
        offset,
      }

      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (['logifim_manager', 'logifim_coordinator'].indexOf(usr?.role) !== -1) {
          data.id = usr.id
        }
      }
      return data
    },
    transformQuery: (items) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_coordinator') {
          return items.residenceCoordinations.nodes.map((rc) => {
            const i = rc.residenceByIdResidence
            const coordinators = i.residenceCoordinationsByIdResidence.nodes
            const allC = coordinators.map((c) => `${c.staffByIdCoordinator.firstname} ${c.staffByIdCoordinator.lastname}`).join(', ')
            const manager = i.staffByIdManager?.firstname ? `${i.staffByIdManager?.firstname} ${i.staffByIdManager?.lastname}` : 'Aucun exploitant'
            const item = {
              ...i,
              managerCompleted: manager,
              city: i.cityByIdCity.name,
              manager: i.staffByIdManager?.firstname ? i.staffByIdManager.firstname : 'Aucun manager',
              zone: i.cityByIdCity.departmentByIdDepartment.name,
              coordinateur: allC[0] ? allC : 'Aucun responsable de site',
            }
            return item
          })
        }
      }

      return items.residences.nodes.map((i) => {
        const coordinators = i.residenceCoordinationsByIdResidence.nodes
        const allC = coordinators.map((c) => `${c.staffByIdCoordinator.firstname} ${c.staffByIdCoordinator.lastname}`).join(', ')
        const manager = i.staffByIdManager?.firstname ? `${i.staffByIdManager?.firstname} ${i.staffByIdManager?.lastname}` : 'Aucun exploitant'
        const item = {
          ...i,
          managerCompleted: manager,
          city: i.cityByIdCity.name,
          manager: i.staffByIdManager?.firstname ? i.staffByIdManager.firstname : 'Aucun manager',
          zone: i.cityByIdCity.departmentByIdDepartment.name,
          coordinateur: allC[0] ? allC : 'Aucun responsable de site',
        }
        return item
      })
    },
    hasPreviousPage: data => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_coordinator') {
          return data.residenceCoordinations.pageInfo.hasPreviousPage
        }
      }
      return data.residences.pageInfo.hasPreviousPage
    },
    hasNextPage: data => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_coordinator') {
          return data.residenceCoordinations.pageInfo.hasNextPage
        }
      }
      return data.residences.pageInfo.hasNextPage
    },
    totalPage: data => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_coordinator') {
          return Math.ceil(data.residenceCoordinations.totalCount / LIMIT)
        }
      }
      return Math.ceil(data.residences.totalCount / LIMIT)
    },
  },
  create: {
    authorizedRole: ['logifim_admin'],
    form: form(true),
    getIdAfterSubmit: (dt) => dt.createResidence?.residence.id,
    transformSubmitQuery: (data, fromDb, indexQuery) => {
      if (indexQuery === 0) {
        return {
          ...data,
          createdId: fromDb.createResidence.residence.id,
        }
      }
      return data
    },
    submitQuery: (data, index) => {
      const rqst = [
        ResidencesGql.createResidence,
      ]

      data.coordinators?.forEach((coordinator) => {
        if (coordinator.status === 'added') {
          rqst.push(ResidencesGql.createResidenceCoordination)
        }
      })

      if (rqst.length > index) {
        return rqst[index]
      }
    },
    submit: (data, params, indexQuery) => {
      if (indexQuery === 0) {
        return {
          name: data.name,
          address: data.address,
          idCity: data?.cityInputs?.idCity,
          idManager: data.manager ? data.manager[0]?.id : null,
        }
      }

      const coordinators = []
      data.coordinators?.forEach((coordinator) => {
        if (coordinator.status === 'added') {
          coordinators.push(coordinator)
        }
      })

      if (indexQuery - 1 < coordinators.length) {
        return {
          idResidence: data.createdId,
          idCoordinator: coordinators[indexQuery - 1].id,
        }
      }
    }
    ,
  },
  update: {
    authorizedRole: ['logifim_admin'],
    form: [
      ...form(false),
      {
        id: 'residence',
        type: 'custom',
        component: (props) => (<ServiceResidence {...props} />),
      },
    ],
    populatedForm: () => ({}),
    getLink: (item) => item.id,
    query: ResidencesGql.getResidence,
    transformQuery: (i) => ({
      ...i.residence,
      cityInputs: {
        idCity: i.residence.cityByIdCity.id,
        cp: i.residence.cityByIdCity.postcode,
        city: i.residence.cityByIdCity.name,
        num: i.residence.cityByIdCity.departmentByIdDepartment.num,
      },
      manager: [i.residence.staffByIdManager],
      coordinators: i.residence.residenceCoordinationsByIdResidence.nodes.map(
        (coordinator) => coordinator.staffByIdCoordinator,
      ),
    }),
    submitQuery: (data, indexQuery) => {
      const atDelete = []
      const atCreate = []

      data.coordinators?.forEach((coordinator) => {
        if (coordinator.status === 'added') {
          atCreate.push(coordinator)
        } else if (coordinator.status === 'deleted') {
          atDelete.push(coordinator)
        }
      })

      const arrayQuery = [ResidencesGql.updateResidence]

      atDelete.forEach(() => {
        arrayQuery.push(ResidencesGql.deleteCoordinatorResidence)
      })

      atCreate.forEach(() => {
        arrayQuery.push(ResidencesGql.createResidenceCoordination)
      })

      return arrayQuery[indexQuery]
    },

    submit: (id, data, indexQuery) => {
      if (indexQuery === 0) {
        return ({
          id,
          name: data.name,
          address: data.address,
          idCity: data?.cityInputs?.idCity,
          idManager: data.manager && data.manager[0] ? data.manager[0]?.id : null,
        })
      }
      if (indexQuery > 0) {
        const atDelete = []
        const atCreate = []

        data.coordinators?.forEach((coordinator) => {
          if (coordinator.status === 'added') {
            atCreate.push(coordinator)
          } else if (coordinator.status === 'deleted') {
            atDelete.push(coordinator)
          }
        })

        if (indexQuery < atDelete.length + 1) {
          return ({
            idCoordinator: atDelete[indexQuery - 1].id,
            idResidence: `${id}`,
          })
        }

        if (indexQuery < atDelete.length + atCreate.length + 1) {
          return ({
            idCoordinator: atCreate[indexQuery - 1 - atDelete.length].id,
            idResidence: `${id}`,
          })
        }
      }
    },
  },
  read: {
    authorizedRole: ['logifim_admin', 'logifim_manager', 'logifim_coordinator'],
    form: [
      {
        id: 'name',
        label: 'residences.label.title',
      },
      {
        id: 'address',
        label: 'residences.label.address',
      },
      {
        type: 'row',
        items: [
          {
            md: 4,
            id: 'cp',
            label: 'residences.label.cp',
          },
          {
            md: 4,
            id: 'city',
            label: 'residences.label.city',
          },
        ],
      },
      {
        id: 'residence',
        type: 'custom',
        component: (props) => (<ServiceResidence {...props} />),
      },
    ],
    populatedForm: () => ({}),
    getLink: (item) => item.id,
    query: ResidencesGql.getResidence,
    transformQuery: (i) => ({
      ...i.residence,
      cp: i.residence.cityByIdCity.postcode,
      city: i.residence.cityByIdCity.name,
    }),
  },
  delete: {
    authorizedRole: ['logifim_admin'],
    text: 'residences.delete.confirm',
    query: ResidencesGql.deleteResidence,
    getTitle: (item) => `${item.name}`,
    errorMessage: 'residences.delete.error',
  },
}
