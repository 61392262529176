/* eslint-disable react/display-name */
import dayjs from 'dayjs'
import config from '../config'
import { Issue } from '../gql'

const { LIMIT } = config

export const Issues = {
  graphql: true,
  title: 'issues.title',
  query: Issue.fetchIssue,
  transformQuery: (items) => items.issue?.residentByIdResident?.lastname,
  url: '/issues',
  list: {
    title: 'issues.list',
    table: [
      {
        head: 'issues.label.reason',
        key: 'reason',
        width: '20%',
      },
      {
        head: 'issues.label.firstname',
        key: 'firstname',
        width: '20%',
      },
      {
        head: 'issues.label.lastname',
        key: 'lastname',
        width: '20%',
      },
      {
        head: 'issues.label.email',
        key: 'email',
        width: '20%',
      },
      {
        head: 'issues.label.createdat',
        key: 'createdAt',
        width: '20%',
      },
    ],
    query: Issue.fetchIssues,
    variables: (limit, offset) => ({
      first: limit,
      offset,
      orderBy: 'CREATED_AT_DESC',
    }),
    transformQuery: (items) => items.issues.nodes.map((i) => ({
        ...i,
        reason: i.issueTypeByIdIssueType.label,
        firstname: i.residentByIdResident.firstname,
        lastname: i.residentByIdResident.lastname,
        email: i.residentByIdResident.email,
        createdAt: dayjs(i.createdAt).format('DD/MM/YYYY HH:mm'),
      })),

    hasPreviousPage: data => data.issues.pageInfo.hasPreviousPage,
    hasNextPage: data => data.issues.pageInfo.hasNextPage,
    totalPage: data => Math.ceil(data.issues.totalCount / LIMIT),
    limit: LIMIT,
  },
  read: {
    form: [
      {
        type: 'row',
        items: [
          {
            md: 6,
            id: 'reason',
            label: 'issues.label.reason',
          },
          {
            md: 6,
            id: 'createdAt',
            label: 'issues.label.createdat',
          },
        ],
      },
      {
        label: 'issues.label.description',
        id: 'description',
        width: '25%',
      },
      {
        type: 'row',
        items: [
          {
            md: 6,
            id: 'lastname',
            label: 'issues.label.lastname',
          },
          {
            md: 6,
            id: 'firstname',
            label: 'issues.label.firstname',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'mobilePhoneNumber',
            label: 'issues.label.phoneNumber',
          },
          {
            id: 'email',
            label: 'issues.label.email',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'residence',
            label: 'issues.label.residence',
          },
          {
            id: 'city',
            label: 'issues.label.city',
          },
        ],
      },
    ],
    getLink: (item) => item.id,
    variables: (id) => ({
      id,
    }),
    query: Issue.fetchIssue,
    transformQuery: (i) => {
      const item = {
        ...i.issue,
       reason: i.issue.issueTypeByIdIssueType.label,
       firstname: i.issue.residentByIdResident.firstname,
       lastname: i.issue.residentByIdResident.lastname,
       mobilePhoneNumber: i.issue.residentByIdResident.mobilePhoneNumber,
       email: i.issue.residentByIdResident.email,
       city: i.issue.residentByIdResident.residenceByIdResidence.cityByIdCity.name,
       residence: i.issue.residentByIdResident.residenceByIdResidence.name,
       createdAt: dayjs(i.issue.createdAt).format('DD/MM/YYYY HH:mm'),
      }
      console.log(item)
      return item
    },
  },
}
