import { gql } from '@apollo/client'

const getResidenceServiceBookings = gql`
  query getResidenceServiceBookings($first: Int, $offset: Int, $id: UUID!) {
    residenceServiceBookings(
      first: $first
      offset: $offset
      filter: { idResidenceService: { equalTo: $id } }
    ) {
      nodes {
        id
        startDatetime
        endDatetime
        externalByIdExternal {
          lastname
          firstname
          id
          mobilePhoneNumber
        }
        residentByIdResident {
          firstname
          id
          lastname
          mobilePhoneNumber
        }
        status
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`
const getAllServiceBookings = gql`
  query getAllServiceBookings(
    $orderBy: [ResidenceServiceBookingsOrderBy!]
    $dateBegin: Datetime
    $dateEnd: Datetime
    $first: Int
    $offset: Int
    $idExternal: UUID
    $status: BookingStatus
  ) {
    residenceServiceBookings(
      orderBy: $orderBy
      first: $first
      offset: $offset
      filter: {
        createdAt: {
          greaterThanOrEqualTo: $dateBegin
          lessThanOrEqualTo: $dateEnd
        }
        idExternal: { equalTo: $idExternal }
        status: { equalTo: $status }
      }
    ) {
      nodes {
        amount
        endDatetime
        idResidenceService
        idResident
        startDatetime
        status
        comments
        idExternal
        id
        externalByIdExternal {
          id
          lastname
          mobilePhoneNumber
          firstname
        }
        residentByIdResident {
          firstname
          lastname
          id
          mobilePhoneNumber
        }
        residenceServiceByIdResidenceService {
          residenceByIdResidence {
            name
          }
          serviceByIdService{
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const createResidenceServiceBooking = gql`
  mutation createResidenceServiceBooking(
    $idResidenceService: UUID!
    $startDatetime: Datetime!
    $endDatetime: Datetime!
    $idExternal: UUID!
    $status: BookingStatus!
  ) {
    createResidenceServiceBooking(
      input: {
        residenceServiceBooking: {
          idResidenceService: $idResidenceService
          startDatetime: $startDatetime
          endDatetime: $endDatetime
          status: $status
          idExternal: $idExternal
        }
      }
    ) {
      clientMutationId
    }
  }
`

const getResidenceServiceBooking = gql`
  query getResidenceServiceBooking($id: UUID!) {
    residenceServiceBooking(id: $id) {
      amount
      id
      status
      startDatetime
      endDatetime
      comments
      residenceServiceByIdResidenceService {
        serviceByIdService {
          name
        }
        residenceByIdResidence {
          name
        }
      }
      externalByIdExternal {
        email
        lastname
        firstname
        id
        mobilePhoneNumber
      }
      residentByIdResident {
        firstname
        email
        lastname
        mobilePhoneNumber
      }
    }
    bookingStatus {
      nodes
    }
  }
`
const getResidenceServicesBookingsByIdResidence = gql`
  query getResidenceServicesBookingsByIdResidence(
    $idResidenceService: [UUID!]
    $offset: Int!
    $first: Int!
  ) {
    residenceServiceBookings(
      filter: { idResidenceService: { in: $idResidenceService } }
      offset: $offset
      first: $first
    ) {
      nodes {
        id
        status
        startDatetime
        comments
        endDatetime
        externalByIdExternal {
          firstname
          email
          lastname
          mobilePhoneNumber
          id
        }
        residentByIdResident {
          email
          firstname
          id
          lastname
          mobilePhoneNumber
        }
        residenceServiceByIdResidenceService {
          residenceByIdResidence {
            name
          }
          serviceByIdService {
            name
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const getServiceBookingByIdService = gql`
  query getServiceBookingByIdService($id: UUID!, $first: Int!, $offset: Int!) {
    service(id: $id) {
      residenceServicesByIdService {
        nodes {
          residenceServiceBookingsByIdResidenceService(
            first: $first
            offset: $offset
          ) {
            nodes {
              comments
              endDatetime
              idExternal
              idResident
              status
              startDatetime
              residentByIdResident {
                email
                lastname
                firstname
                id
                mobilePhoneNumber
                residenceByIdResidence {
                  name
                }
              }
              id
              externalByIdExternal {
                email
                firstname
                lastname
                mobilePhoneNumber
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
      }
    }
  }
`

const getResidences = gql`
  query getResidences {
    residences {
      nodes {
        name
        id
      }
    }
  }
`

const updateResidenceServiceBooking = gql`
  mutation updateResidenceServiceBooking($status: BookingStatus!, $id: UUID!) {
    updateResidenceServiceBooking(
      input: { patch: { status: $status }, id: $id }
    ) {
      clientMutationId
    }
  }
`

const updateResidenceServiceBookingStatus = gql`
  mutation updateResidenceServiceBooking($status: BookingStatus, $id: UUID!) {
    updateResidenceServiceBooking(
      input: { patch: { status: $status }, id: $id }
    ) {
      clientMutationId
    }
  }
`

const getOrderByResidence = gql`
  query getOrderByResidence($id: UUID!,
    $orderBy: [ResidenceServiceBookingsOrderBy!]
    $dateBegin: Datetime
    $dateEnd: Datetime
    $first: Int
    $offset: Int
    $idExternal: UUID
    $status: BookingStatus) {
    residence(id: $id) {
      name
      residenceServicesByIdResidence {
        nodes {
          residenceServiceBookingsByIdResidenceService(
            orderBy: $orderBy
            first: $first
            offset: $offset
            filter: {
              createdAt: {
                greaterThanOrEqualTo: $dateBegin
                lessThanOrEqualTo: $dateEnd
              }
              idExternal: { equalTo: $idExternal }
              status: { equalTo: $status }
            }
            ) {
            nodes {
              id
              startDatetime
              endDatetime
              externalByIdExternal {
                lastname
                firstname
                id
                mobilePhoneNumber
              }
              residentByIdResident {
                firstname
                id
                lastname
                mobilePhoneNumber
              }
              status
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
      }
    }
  }
`

export default {
  getResidenceServiceBookings,
  createResidenceServiceBooking,
  getResidenceServiceBooking,
  updateResidenceServiceBooking,
  getAllServiceBookings,
  getServiceBookingByIdService,
  getResidenceServicesBookingsByIdResidence,
  updateResidenceServiceBookingStatus,
  getResidences,
  getOrderByResidence,
}
