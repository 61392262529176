import { gql } from '@apollo/client'

const fetchIssueTypes = gql`
query fetchIssueTypes($first: Int, $offset: Int) {
  issueTypes(first: $first, offset: $offset) {
    nodes {
      id
      label
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
`

const fetchIssueType = gql`
query fetchIssueType($id: UUID!) {
  issueType(id: $id) {
    label
    id
  }
}
`

const createIssueType = gql`
mutation createIssueType($label: String!) {
  createIssueType(input: {issueType: {label: $label}}) {
    clientMutationId
    issueType {
      id
    }
  }
}
`

const updateIssueType = gql`
mutation updateIssueType($label: String= "", $id: UUID!) {
  updateIssueType(input: {patch: {label: $label}, id: $id}) {
    clientMutationId
  }
}
`

const deleteIssueType = gql`
mutation deleteIssueType($id: UUID!) {
  deleteIssueType(input: {id: $id}) {
    clientMutationId
  }
}
`

const fetchIssues = gql`
query fetchIssues($offset: Int, $first: Int, $orderBy: [IssuesOrderBy!]) {
  issues(offset: $offset, first: $first, orderBy: $orderBy) {
    nodes {
      id
      description
      createdAt
      issueTypeByIdIssueType {
        label
      }
      residentByIdResident {
        firstname
        lastname
        email
        mobilePhoneNumber
        id
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`

const fetchIssue = gql`
query fetchIssue($id: UUID!) {
  issue(id: $id) {
    issueTypeByIdIssueType {
      label
    }
    description
    createdAt
    residentByIdResident {
      firstname
      lastname
      email
      mobilePhoneNumber
      residenceByIdResidence {
        name
        cityByIdCity {
          name
        }
      }
    }
  }
}
`

export default {
  fetchIssueTypes,
  fetchIssueType,
  createIssueType,
  updateIssueType,
  deleteIssueType,
  fetchIssues,
  fetchIssue,
}
