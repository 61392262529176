import { gql } from '@apollo/client'

const fetchCatalogs = gql`
  query fetchCatalogs($first: Int, $offset: Int) {
    catalogs(first: $first, offset: $offset) {
      nodes {
        id
        label
        ref
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const deleteCatalog = gql`
  mutation deleteCatalog($id: UUID!) {
    deleteCatalog(
      input: {id: $id}
    ) {
      clientMutationId
    }
  }
`

const createCatalog = gql`
  mutation createCatalog(
    $label: String!,
    $ref: String!,
  ) {
    createCatalog(input: {catalog: {
      label: $label,
      ref: $ref,
    }}) {
      catalog {
        id
      }
    }
  }
`

const getCatalogById = gql`
  query getCatalogById($id: UUID!) {
    catalog(id: $id) {
      label
      ref
      id
    }
  }
`

const updateCatalog = gql`
  mutation updateCatalog($label: String!, $ref: String!, $id: UUID!){
    updateCatalog(input: {patch: {label: $label, ref: $ref}, id: $id}) {
      clientMutationId
    }
  }
`

export default {
  fetchCatalogs,
  createCatalog,
  deleteCatalog,
  getCatalogById,
  updateCatalog,
}
