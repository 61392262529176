import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import i18n from 'i18next'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory, useParams } from 'react-router-dom'
import {
  Card, CardBody, CardHeader, Col, Form,
  FormGroup, ListGroup,
  ListGroupItem, Row,
} from 'shards-react'
import config from '../../../config'
import { Faq } from '../../../gql'
import UserProvider from '../../../gql/UserProvider'
import {
  couldSendForm, makeMyInput as makeMyInputLib,
  populateFormAfterApi, userCanDoAction,
} from '../../../lib'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import { customStyles } from './style'

const AutomatedCreate = (props) => {
  const { model, custom } = props
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [validField, setValidField] = useState({})
  const [formSubmit, setFormSubmit] = useState(false)
  const [itemData, setItemData] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState([])
  const [loadingExtra, setLoadingExtra] = useState(0)
  // used to popupate select, radio, checkbox when data is from api
  const [populatedForm, setPopulatedForm] = useState([])
  const formFromModel = model.create.form
  const [indexQuery, setIndexQuery] = useState(0)
  const [idCreated, setIdCreated] = useState()
  const [lastPos, setLastPos] = useState()
  const [roleIdStaff, setRoleIdStaff] = useState(null)

  const [fetchLastPos] = useLazyQuery(Faq.fetchLastPos, {
    fetchPolicy: config.fetchPolicy,
    onCompleted: (res) => {
      setLastPos(res?.faqs?.nodes[0] ? res?.faqs?.nodes[0].pos + 1 : 1)
    },
  })

  const { data: dataRole } = useQuery(UserProvider.fetchProviderRoleId, {
    onCompleted: () => {
      const array = dataRole.viewRoles.nodes.filter(
        (el) => el.rolname === 'logifim_service_provider',
      )
      const arrayExternal = dataRole.viewRoles.nodes.filter(
        (el) => el.rolname === 'logifim_external',
      )
      if (custom.roleName === 'logifim_service_provider') {
        setRoleIdStaff(array[0].id)
      }
      if (custom.roleName === 'logifim_external') {
        setRoleIdStaff(arrayExternal[0].id)
      }
    },
  })

  const params = useParams()
  const history = useHistory()

  useEffect(() => {
    if (model.name === 'faq') {
      fetchLastPos()
    }
  }, [])

  const loadDataAsync = async (dataFromGraphQl) => {
    const form = {
      ...populatedForm,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.model) {
            let dataToPopulate
            if (config.CONNECTOR === 'graphql' && field.model.graphql) {
              dataToPopulate = field.model.populateForm(dataFromGraphQl)
            } else {
              dataToPopulate = await field.model.populateForm(i18n.language)
            }
            form[field.id] = dataToPopulate
            setPopulatedForm(form)
          }
        })
      } else if (row.model) {
        let dataToPopulate
        if (config.CONNECTOR === 'graphql' && model.graphql) {
          dataToPopulate = row.model.populateForm(dataFromGraphQl)
        } else {
          dataToPopulate = await row.model.populateForm(i18n.language)
        }
        form[row.id] = dataToPopulate
        setPopulatedForm(form)
      }
    })
  }

  let fetchGrahQlItem = () => {}
  try {
    const fetchItem = useLazyQuery(model.create.query, {
      fetchPolicy: config.fetchPolicy,
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      variables: model.create.variable ? model.create.variable(params) : {},
      onError: (err) => {
        const request = model.create.query
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: model.create.variable ? model.create.variable(params) : {},
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (itemToUpdate) => {
        loadDataAsync(itemToUpdate)
      },
    })
    fetchGrahQlItem = fetchItem[0]
  } catch (e) {
    console.log(e)
  }

  const submitModel = (id) => {
    let loading = 0
    const err = [...error]
    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.needToSubmit) {
            try {
              loading += 1
              setLoadingExtra(loading)
              await field.needToSubmit(id, data[field.id])
            } catch (e) {
              console.log(e)
              err.push(`ui.custom.${field.id}`)
              setError(err)
            } finally {
              loading -= 1
              setLoadingExtra(loading)
            }
          }
        })
      } else if (row.needToSubmit) {
        try {
          loading += 1
          setLoadingExtra(loading)
          await row.needToSubmit(id, data[row.id])
        } catch (e) {
          console.log(e)
          err.push(`ui.custom.${row.id}`)
          setError(err)
        } finally {
          loading -= 1
          setLoadingExtra(loading)
        }
      }
    })

    if (config.CONNECTOR === 'graphql' && model.graphql) {
      setLoadingExtra(0)
    }
  }

  let submitGraphQl = () => {}
  let mutationLoading
  let mutationError
  try {
    const submitGQL = useMutation(
      model.create.submitQuery(
        {
          ...data,
          idRole: roleIdStaff || custom.roleName,
          role:
            custom.roleName === 'logifim_service_provider' && custom.roleName,
        },
        indexQuery,
      ),
      {
        variables: model.create.submit(
          {
            ...data,
            pos: lastPos,
            idRole: roleIdStaff || custom.roleId,
            role:
              custom.roleName === 'logifim_service_provider' && custom.roleName,
          },
          params,
          indexQuery,
          props,
        ),
        onError: (err) => {
          const request = model.create.submitQuery(
            {
              ...data,
              pos: lastPos,
              idRole:
                custom.roleName === 'logifim_service_provider'
                  ? roleIdStaff
                  : custom.roleId,
              role:
                custom.roleName === 'logifim_service_provider' &&
                custom.roleName,
            },
            indexQuery,
          )
          const event = {
            request: request?.definitions[0]?.name?.value,
            variables: model.create.submit(
              {
                ...data,
                pos: lastPos,
                idRole:
                  custom.roleName === 'logifim_service_provider'
                    ? roleIdStaff
                    : custom.roleId,
                role:
                  custom.roleName === 'logifim_service_provider' &&
                  custom.roleName,
              },
              params,
              indexQuery,
            ),
          }
          captureMessageWithAttachment(event, err)
        },
        onCompleted: (dt) => {
          if (
            model.create.submitQuery(
              {
                ...data,
                idRole:
                  custom.roleName === 'logifim_service_provider'
                    ? roleIdStaff
                    : custom.roleId,
                role:
                  custom.roleName === 'logifim_service_provider' &&
                  custom.roleName,
              },
              indexQuery + 1,
            )
          ) {
            if (model.create.transformSubmitQuery) {
              setData(model.create.transformSubmitQuery(data, dt, indexQuery))
            }
            setIndexQuery((prev) => prev + 1)
          } else {
            if (model.create.getIdAfterSubmit) {
              setItemData(model.create.getIdAfterSubmit(dt))
            }

            if (model.create.modalSuccess) {
              setIsOpen(true)
            }
            const createdId = model.create.getIdAfterSubmit(dt)
            setIdCreated(createdId)
            submitModel(createdId)
          }
        },
      },
    )
    submitGraphQl = submitGQL[0]
    mutationLoading = submitGQL[1].loading
    mutationError = submitGQL[1].error
  } catch (e) {
    console.log(e)
  }

  useEffect(() => {
    if (indexQuery !== 0) {
      submitGraphQl()
    }
  }, [indexQuery])

  const closeModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (formSubmit && loadingExtra === 0) {
      let pathname =
        model.url && typeof model.url === 'function'
          ? model.url(null, params, props)
          : model.url
      if (model.create.url) {
        pathname = model.create.url(idCreated)
      }

      history.push({
        pathname,
        state: { create: true },
      })
    }
  }, [loadingExtra])

  const loadItemAsync = async () => {
    if (config.CONNECTOR === 'graphql' && model.graphql) {
      if (model.create.query) {
        fetchGrahQlItem()
      }
    } else {
      const item = await model.create.getItem(
        params.id,
        i18n.language,
        params.subId,
      )
      populateFormAfterApi(item, data, formFromModel, setData)
    }
  }

  useEffect(() => {
    if (
      model.create.authorizedRole &&
      !userCanDoAction(model.create.authorizedRole)
    ) {
      history.push({
        pathname:
          model.url && typeof model.url === 'function'
            ? model.url(null, params)
            : model.url,
        state: { noAccess: true },
      })
    }

    if (config.CONNECTOR !== 'graphql' && !model.graphql) {
      loadDataAsync()
    }
    loadItemAsync()
  }, [])

  const submitForm = async (event) => {
    event.preventDefault()
    setFormSubmit(true)
    if (couldSendForm(validField, formFromModel, data, setValidField)) {
      if (config.CONNECTOR === 'graphql' && model.graphql) {
        setLoadingExtra(1)
        submitGraphQl()
      } else {
        await model.create.submit(
          params.id,
          data,
          i18n.language,
          history,
          params.subId,
        )

        let loading = 0
        let noExtra = true
        const err = [...error]
        formFromModel.forEach(async (row) => {
          if (row.type === 'row') {
            row.items.forEach(async (field) => {
              if (field.needToSubmit) {
                noExtra = false
                try {
                  loading += 1
                  setLoadingExtra(loading)
                  await field.needToSubmit(params.id, data[field.id])
                } catch (e) {
                  console.log(e)
                  err.push(`ui.custom.${field.id}`)
                  setError(err)
                } finally {
                  loading -= 1
                  setLoadingExtra(loading)
                }
              }
            })
          } else if (row.needToSubmit) {
            noExtra = false
            try {
              loading += 1
              setLoadingExtra(loading)
              await row.needToSubmit(params.id, data[row.id])
            } catch (e) {
              console.log(e)
              err.push(`ui.custom.${row.id}`)
              setError(err)
            } finally {
              loading -= 1
              setLoadingExtra(loading)
            }
          }

          if (noExtra) {
            history.push({
              pathname:
                model.url && typeof model.url === 'function'
                  ? model.url(params.id)
                  : model.url,
              state: { update: true },
            })
          }
        })
      }
    }
  }

  const makeMyInput = (field) =>
    makeMyInputLib(
      model,
      field,
      data,
      setData,
      validField,
      setValidField,
      formSubmit,
      submitForm,
      populatedForm,
      t,
      error,
      history,
      null,
      null,
      null,
      mutationLoading,
      null,
      mutationError,
      params,
      props,
    )

  return (
    <>
      <Row>
        <Col>
          <Card small className="mb-4">
            {model.create.title && (
              <CardHeader className="border-bottom">
                <h6 className="m-0">{t(model.create.title)}</h6>
              </CardHeader>
            )}

            <CardBody className="p-0 pb-3">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        {formFromModel.map((item, indexRow) => (
                          <div key={`row${indexRow}`} style={item.rowCustom}>
                            {item.type === 'row' ? (
                              <>
                                {item.right ? (
                                  <Row style={{ ...customStyles.divButtons }}>
                                    {item.items.map((field, index) => (
                                      <div className="p-1" key={`col${index}`}>
                                        {makeMyInput(field)}
                                      </div>
                                    ))}
                                  </Row>
                                ) : (
                                  <Row form>
                                    {item.items.map((field, index) => (
                                      <Col
                                        md={field.md}
                                        className="form-group"
                                        key={`col${index}`}
                                      >
                                        {makeMyInput(field)}
                                      </Col>
                                    ))}
                                  </Row>
                                )}
                              </>
                            ) : (
                              <FormGroup>{makeMyInput(item)}</FormGroup>
                            )}
                          </div>
                        ))}
                        {isOpen && (
                          <Modal
                            isOpen={isOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                          >
                            {model.create.modalSuccess({
                              item: itemData,
                              closeModal,
                            })}
                          </Modal>
                        )}
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

AutomatedCreate.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  custom: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AutomatedCreate
