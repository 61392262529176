import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormInput,
  Button,
} from 'shards-react'
import {
  Link,
  useLocation,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import backgroundImage from '../images/fgtPwd.svg'

import api from '../api'
import logo from '../images/logo.png'

const FgtPassword = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { emailProps } = location.state
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const mediaMatch = window.matchMedia('(max-width: 480px)')
  const [matches, setMatches] = useState(mediaMatch.matches)
  const [confirmSendMail, setConfirmSendMail] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(true)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addListener(handler)
    return () => mediaMatch.removeListener(handler)
  })

  useEffect(() => {
    if (emailProps !== '') {
      setEmail(emailProps)
    }
  })

  const onSubmit = async (event) => {
    setDisabledBtn(true)
    event.preventDefault()
    setError(false)
    if (email === '') {
      setError(true)
      return
    }

    try {
      await api.forgotPwd(email)
      setConfirmSendMail(true)
    } catch (e) {
      console.log(e)
      setConfirmSendMail(false)
    }
    setDisabledBtn(false)
  }

  useEffect(() => {
    setConfirmSendMail(false)
    if (email !== '') {
      setDisabledBtn(false)
    } else {
      setDisabledBtn(true)
    }
  }, [email])

  const styles = {
    title: {
      fontSize: matches ? '2.5em' : '4.5em',
      paddingLeft: matches ? '25vw' : '0',
      color: '#485156',
      fontFamily: 'Source Serif Pro',
    },
    subTitle: {
      fontSize: matches ? '1em' : '1.5em',
      paddingLeft: matches ? '25vw' : '0',
      color: '#485156',
      opacity: '0.6',
      fontFamily: 'Source Sans Pro',
      fontWeight: '700',
    },
  }

  return (
    <Container fluid className="main-content-container" style={{ padding: 0 }}>
      <Row
        style={{
          height: '100vh',
        }}
      >
        <Col
          style={{
            backgroundColor: 'rgba(110, 60, 165, 0.2)',
            position: 'relative',
          }}
        >
          <div className="p-5">
            <h4
              style={styles.title}
            >
              résid’
            </h4>
            <p className="pt-4" style={styles.subTitle}>Portail de connexion V-Services</p>
          </div>
          <img
            alt="background"
            src={backgroundImage}
            style={{
              position: 'absolute',
              bottom: '4vh',
              right: '-11vw',
              left: '0px',
              zIndex: 100,
              maxHeight: '70%',
              maxWidth: '45vw',
            }}
          />
        </Col>
        <Col sm={{ size: 8 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
          <div style={{ width: '70%', height: '60%', paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="mb-5">
              <img
                id="main-logo"
                style={{ width: '62px', height: '56px' }}
                className="mb-3"
                src={logo}
                alt="V-Services"
              />
              <h5 className="m-0 pb-1 mt-3" style={{ fontWeight: '700', fontSize: '2.25rem', color: '#485156', fontFamily: 'Source Sans Pro' }}>{t('forgotPwd.title')}</h5>
              <p className="mb-2" style={{ fontSize: '1.2rem', color: '#485156', fontWeight: '400', fontFamily: 'Source Sans Pro' }}>
                {t('forgotPwd.subTitle')}
              </p>
            </div>

            <Form>
              <FormGroup>
                <label style={{ fontSize: '0.875rem', fontWeight: '600', color: '#485156', fontFamily: 'Source Sans Pro' }} htmlFor="#username">{t('login.username')}</label>
                <FormInput
                  id="#username"
                  value={email}
                  placeholder={t('login.username')}
                  onChange={(event) => {
                        setEmail(event.target.value)
                      }}
                />
              </FormGroup>
              {error && (
              <FormFeedback
                invalid
                style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
              >
                {t('form.fieldInvalid')}
              </FormFeedback>
                )}
              <FormGroup style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="submit"
                  disabled={disabledBtn}
                  onClick={onSubmit}
                  style={{ backgroundColor: '#C6CF00', width: '100%', borderRadius: '5px', height: '50px', fontSize: '1.25rem', fontWeight: '700', fontFamily: 'Source Sans Pro', border: 'none' }}
                >
                  {t('forgotPwd.submit')}
                </Button>
              </FormGroup>
              <Link to="/login" exact className="pl-0" style={{ textDecoration: 'underline', color: '#00AFA0', fontSize: '1rem' }}>{t('login.title')}</Link>
            </Form>
            {confirmSendMail && (
              <FormFeedback
                valid
                style={{ display: 'block', marginBottom: '10px', marginTop: '5px', fontSize: '0.9rem' }}
              >
                {t('forgotPwd.valid')}
              </FormFeedback>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

FgtPassword.propTypes = {
  // model: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default FgtPassword
