import { gql } from '@apollo/client'

const deleteResource = gql`
  mutation deleteResource($id: UUID!) {
    deleteResource(input: {id: $id}) {
      clientMutationId
    }
}
`

const getRessource = gql`
  query getRessource($id: UUID!) {
    resource(id: $id) {
      description
      id
      name
    }
  }
`

const getRessourceWithCityAndRes = gql`
  query getRessourceWithCityAndRes($id: UUID!) {
    resource(id: $id) {
      description
      id
      name
      residenceByIdResidence {
        name
        id
        cityByIdCity {
          name
          id
        }
      }
    }
  }
`

const updateRessource = gql`
  mutation updateRessource($description: String, $name: String!, $id: UUID!) {
    updateResource(input: {patch: {description: $description, name: $name}, id: $id}) {
      clientMutationId
    }
  }
`

const createRessource = gql`
  mutation createRessource($description: String, $idResidence: UUID!, $name: String!) {
    createResource(
      input: {resource: {description: $description, name: $name, idResidence: $idResidence}}
    ) {
      resource {
        id
      }
    }
  }
`

const getRessourcePlanning = gql`
  query getRessourcePlanning($idResource: UUID!) {
    resourceBookings(condition: {idResource: $idResource}) {
      nodes {
        residenceServiceByIdResidenceService {
          residenceByIdResidence {
            id
            name
            cityByIdCity {
              id
              name
              departmentByIdDepartment {
                num
              }
            }
          }
        }
        idResource
        resourceByIdResource {
          id
          name
        }
        id
        startDatetime
        endDatetime
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const createRessourcePlanning = gql`
  mutation createRessourcePlanning($endDatetime: Datetime, $idResource: UUID!, $startDatetime: Datetime) {
    createResourceBooking(
      input: {resourceBooking: {idResource: $idResource, endDatetime: $endDatetime, startDatetime: $startDatetime}}
    ) {
      clientMutationId
      resourceBooking {
        id
      }
    }
  }
`

export default {
  deleteResource,
  getRessource,
  updateRessource,
  createRessource,
  getRessourceWithCityAndRes,
  getRessourcePlanning,
  createRessourcePlanning,
}
