import React, { useState, useEffect } from 'react'
import { FormInput, Col, Row } from 'shards-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import config from '../../../config'
import { captureMessageWithAttachment } from '../../../lib/sentry'

const SearchResidenceInput = ({ data, onChangeCustom, field }) => {
    const { t } = useTranslation()
    const [residence, setResidence] = useState(data[field.id] ? data[field.id] : [])
    const [results, setResults] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const [fetchNewResults, { data: dataResults }] = useLazyQuery(field.query, {
      fetchPolicy: config.fetchPolicy,
      onError: (err) => {
        const request = field.query
        const event = {
          request: request?.definitions[0]?.name?.value,
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: () => {
        setResults([])
        if (dataResults.residences.nodes) {
          setResults(dataResults.residences.nodes)
        }
      },
    })

    const styles = {
      border: '1px solid #e1e5eb',
      height: '40px',
      marginTop: 5,
      padding: '0 10px',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 400,
  }

    const addItem = (resid) => {
      setResidence([resid])
      setSearchValue('')
      setResults([])
      onChangeCustom(field.id, resid.id)
    }

    const changeResult = () => {
      if (searchValue !== '') {
        fetchNewResults({
          variables: {
            name: `%${searchValue}%`,
          },
        })
      } else if (searchValue === '') {
        setResults([])
      }
    }

    useEffect(() => {
      if (searchValue !== '') {
        changeResult(searchValue)
      }
    }, [searchValue])

  return (
    <Row>
      <Col>
        <i className="fas fa-search" style={{ position: 'absolute', left: '25px', zIndex: 2, top: '11px' }} />
        <FormInput
          list={field.id}
          placeholder={t(`${field.placeholder}`)}
          value={searchValue}
          style={{
            paddingLeft: 30,
          }}
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
        />
        {residence.length < 1 && searchValue && <span style={{ marginTop: '5px', color: 'red', fontSize: '12px' }}>{t('errorResidence')}</span>}
        {data.idResidence?.fromDb && (
          <div style={{ border: '1px solid rgba(196, 196, 196, 0.4)', height: '40px', display: 'flex', alignItems: 'center' }} className="mt-1 pl-3">
            <p className="p-0 m-0" style={{ minWidth: '15%' }}>
              {t('clients.update.actual')}
              {data.idResidence?.fromDb}
            </p>
          </div>
        )}
        {results && results[0] && (
          <div className="mb-3">
            {results.map((r) => (
              <div key={r.id} style={{ border: '1px solid rgba(196, 196, 196, 0.4)', height: '40px', display: 'flex', alignItems: 'center' }} className="mt-1 pl-3">
                <p className="p-0 m-0" style={{ minWidth: '15%' }}>{r.name}</p>
                <button type="button" onClick={() => { addItem(r) }} className="ml-3" style={{ border: 'none' }}>{t('ui.add')}</button>
              </div>
            ))}
          </div>
        )}
        {residence && residence[0] && residence.map((r) => (
          <div className="d-flex" style={{ ...styles }} key={r.id}>
            <div className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ margin: 0 }}>{r.name}</p>
            </div>
          </div>
        ))}
      </Col>
    </Row>
  )
}

SearchResidenceInput.propTypes = {
  data: PropTypes.shape(),
  onChangeCustom: PropTypes.func.isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
}

SearchResidenceInput.defaultProps = {
  data: {},
}

export default SearchResidenceInput
