import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import config from '../config'

const attachmentUrlFromDsn = (dsn, eventId) => {
  const { host, path, projectId, port, protocol, user } = dsn
  return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
    path !== '' ? `/${path}` : ''
  }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`
}

const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

export const initSentry = () => {
  let environment = 'development'
  if (config.REACT_APP_INFRA === 'uat') {
    environment = 'staging'
  } else if (config.REACT_APP_INFRA === 'prod') {
    environment = 'production'
  }

  if (!isLocal) {
    Sentry.init({
      dsn: 'https://079f0b8ac08b4a5c87d6d585e406be80@o530648.ingest.sentry.io/6140818',
      integrations: [new Integrations.BrowserTracing()],

      enableAutoSessionTracking: true,
      environment,
      // Sessions close after app is 10 seconds in the background.
      sessionTrackingIntervalMillis: 10000,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      ignoreErrors: [
        'Network Error',
        'Request failed with status code 404',
      ],
    })
    Sentry.setTag('plateforme', 'cms')
  }
}

export const captureMessageWithAttachment = (data, error) => {
  if (!isLocal) {
    try {
      Sentry.withScope((scope) => {
        scope.setTransactionName(`cms - ${data.request}`)
        const msgId = Sentry.captureMessage(
          `[GraphQL error]: ${error?.message}`,
        )
        const client = Sentry.getCurrentHub().getClient()
        const endpoint = attachmentUrlFromDsn(
          client.getDsn(),
          msgId,
        )
        const formData = new FormData()
        formData.append(
          'my-attachment',
          // eslint-disable-next-line no-undef
          new Blob([JSON.stringify({
            ...data,
            message: error?.message,
            url: window.location.href,
            accessToken: localStorage.getItem('accessToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          })], {
            type: 'application/json',
          }),
          'logs.json',
        )
        fetch(endpoint, {
          method: 'POST',
          body: formData,
        }).catch((ex) => {
          // we have to catch this otherwise it throws an infinite loop in Sentry
          console.error(ex)
        })
      })
    } catch (ex) {
      console.error(ex)
    }
  }
}
