/* eslint-disable react/display-name */
import { ResidenceService } from '../gql'

export const ServicesForResidence = {
  graphql: true,
  url: '/services-residence',
  list: {
    table: [
      {
        head: 'services.label.ref',
        key: 'ref',
        width: '20%',
      },
      {
        head: 'services.label.libelle',
        key: 'label',
        width: '40%',
      },
      {
        head: 'services.label.title',
        key: 'name',
        width: '40%',
      },
    ],
  },
  update: {
    authorizedRole: ['logifim_admin'],
    url: (parentId, id) => `/services-residence/edit/${id}|${parentId}`,
    getLink: (item) => item.id,
  },
  delete: {
    authorizedRole: ['logifim_admin'],
    text: 'residences.services.delete.confirm',
    query: ResidenceService.deleteResidenceService,
    getTitle: (item) => `${item.name}`,
    errorMessage: 'residences.services.delete.error',
  },
}
