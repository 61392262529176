import React from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import { UsersResident, Residences } from '../gql'
import AutomatedModalBatchDelete from '../components/automated/custom/AutomatedModalBatchDelete'
import { SearchResidenceInput, Photos } from '../components/automated/custom'
import { dataURItoBlob } from '../lib'
import config from '../config'

import { MaritalStatuses } from './MaritalStatuses'
import { Civility } from './Civility'

const { LIMIT } = config

const form = (create) => [
  {
    type: 'row',
    items: [
      {
        md: 6,
        id: 'residentNumber',
        type: 'text',
        placeholder: 'clients.residentNumber',
        label: 'clients.residentNumber',
        required: true,
      },
      {
        md: 6,
        id: 'door',
        type: 'text',
        placeholder: 'clients.label.door',
        label: 'clients.label.door',
        required: true,
      },
    ],
  },

  {
    id: 'civilities',
    type: 'radios',
    label: 'clients.label.civilities',
    required: true,
    model: Civility,
  },
  {
    type: 'row',
    items: [
      {
        md: 6,
        id: 'lastname',
        type: 'text',
        placeholder: 'clients.lastname',
        label: 'clients.lastname',
        required: true,
      },
      {
        md: 6,
        id: 'firstname',
        type: 'text',
        placeholder: 'clients.firstname',
        label: 'clients.firstname',
        required: true,
      },
    ],
  },
  {
    type: 'row',
    items: [
      {
        id: 'idMaritalStatus',
        type: 'select',
        placeholder: 'clients.placeholder.maritalStatus',
        label: 'clients.label.maritalStatus',
        defaultValue: 'clients.default.maritalStatus',
        model: MaritalStatuses,
        required: true,
      },
      {
        id: 'birthdate',
        type: 'datetimepicker',
        placeholder: 'clients.placeholder.birthdate',
        label: 'clients.label.birthdate',
        required: true,
      },
    ],
  },
  {
    type: 'row',
    items: [
      {
        id: 'mobilePhoneNumber',
        type: 'tel',
        placeholder: 'clients.placeholder.phoneNumber',
        label: 'clients.label.phoneNumber',
        regex: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/i,
        required: true,
      },
      {
        id: 'email',
        type: 'email',
        placeholder: 'clients.placeholder.email',
        label: 'clients.label.email',
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        required: true,
      },
    ],
  },
  {
    id: 'password',
    type: 'password',
    label: 'clients.label.password',
    placeholder: 'clients.placeholder.password',
    required: create,
  },
  {
    id: 'visuel',
    type: 'custom',
    component: (props) => (
      <Photos {...props} wording={create ? 'ui.addImage' : 'ui.updateImage'} />
    ),
    url: (params, withToken = true) => {
      let url = `${config.API_BASE_URL}residents/${params.id}/assets/visuel.png`
      if (withToken) {
        url += `?token=${localStorage.getItem('accessToken')}`
      }
      return url
    },
    label: 'clients.label.visuel',
    deletable: false,
    required: false,
    needToSubmit: async (id, data) => {
      if (data && data.img && data.type) {
        const token = localStorage.getItem('accessToken')
        const url = `${config.API_BASE_URL}residents/${id}/assets/visuel.png`
        const formdata = new FormData()
        formdata.append('asset', dataURItoBlob(data.img))
        return axios.post(url, formdata, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
      }
    },
  },
  {
    type: 'custom',
    label: 'clients.label.idResidence',
    placeholder: 'clients.placeholder.residence',
    id: 'idResidence',
    component: (props) => <SearchResidenceInput {...props} />,
    query: Residences.searchResidenceByName,
    required: create,
  },
  {
    type: 'row',
    right: true,
    items: [
      {
        type: 'back',
        label: 'ui.cancel',
        md: 2,
      },
      {
        type: 'submit',
        label: 'residences.label.submit',
        md: 2,
      },
    ],
  },
]

export const Residents = {
  graphql: true,
  title: 'clients.title.residents',
  query: UsersResident.getResidentById,
  transformQuery: (items) => items.resident.lastname,
  url: '/residents',
  subtitle: '',
  list: {
    table: [
      {
        head: 'clients.firstname',
        key: 'firstname',
        width: '20%',
      },
      {
        head: 'clients.lastname',
        key: 'lastname',
        width: '20%',
      },
      {
        head: 'clients.email',
        key: 'email',
        width: '20%',
      },
      {
        head: 'clients.residence',
        key: 'residence',
        width: '20%',
      },
      {
        head: 'clients.city',
        key: 'city',
        width: '20%',
      },
    ],
    batchActions: {
      title: 'ui.batchActions.title',
      items: [
        {
          label: 'ui.batchActions.delete',
          title: 'clients.delete.residents',
          component: (props) => <AutomatedModalBatchDelete {...props} />,
        },
      ],
    },
    query: () => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return UsersResident.fetchResidentForManager
        }
        if (usr?.role === 'logifim_coordinator') {
          return UsersResident.fetchResidentForCoordinator
        }
      }

      return UsersResident.fetchResidents
    },
    variables: (
      limit,
      offset,
      lang,
      sortType,
      sortDirection,
      props,
      params,
    ) => {
      const data = {
        first: limit,
        offset,
        idRole: params.role || props?.custom?.roleId,
      }

      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (
          ['logifim_manager', 'logifim_coordinator'].indexOf(usr?.role) !== -1
        ) {
          data.idRole = usr.id
        }
      }

      return data
    },
    transformQuery: (items) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          const usrs = []
          items?.residences?.nodes?.forEach((node) => {
            node?.residentsByIdResidence?.nodes?.forEach((user) => {
              if (!usrs.find((u) => u.id === user?.id)) {
                usrs.push({
                  ...user,
                  residence: user.residenceByIdResidence?.name,
                  city: user.residenceByIdResidence?.cityByIdCity?.name,
                })
              }
            })
          })
          return usrs
        }

        if (usr?.role === 'logifim_coordinator') {
          const usrs = []
          items?.residenceCoordinations?.nodes?.forEach((node) => {
            node?.residenceByIdResidence?.residentsByIdResidence?.nodes?.forEach(
              (user) => {
                if (!usrs.find((u) => u.id === user?.id)) {
                  usrs.push({
                    ...user,
                    residence: user.residenceByIdResidence?.name,
                    city: user.residenceByIdResidence?.cityByIdCity?.name,
                  })
                }
              },
            )
          })
          return usrs
        }
      }
      return items.residents.nodes.map((i) => ({
        ...i,
        residence: i.residenceByIdResidence?.name,
        city: i.residenceByIdResidence?.cityByIdCity?.name,
      }))
    },
    hasPreviousPage: (data) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return data.residences.hasPreviousPage
        }
        if (usr?.role === 'logifim_coordinator') {
          return data.residenceCoordinations.hasPreviousPage
        }
      }
      return data.residents.pageInfo.hasPreviousPage
    },
    hasNextPage: (data) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return data.residences.hasNextPage
        }
        if (usr?.role === 'logifim_coordinator') {
          return data.residenceCoordinations.hasNextPage
        }
      }
      return data.residents.pageInfo.hasNextPage
    },
    totalPage: (data) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (usr?.role === 'logifim_manager') {
          return Math.ceil(data.residences.totalCount / LIMIT)
        }
        if (usr?.role === 'logifim_coordinator') {
          return Math.ceil(data.residenceCoordinations.totalCount / LIMIT)
        }
      }
      return Math.ceil(data.residents.totalCount / LIMIT)
    },
    limit: LIMIT,
  },
  create: {
    form: form(true),
    query: UsersResident.getDataToCreateResident,
    getIdAfterSubmit: (data) => data.createResident.resident.id,
    transformQuery: (items) => items,
    submitQuery: (data, index) => {
      if (index === 0) {
        return UsersResident.createResident
      }
    },
    submit: (data) => ({
      idCivility: data.civilities,
      residentNumber: data.residentNumber,
      door: data.door,
      firstname: data.firstname,
      lastname: data.lastname,
      idMaritalStatus: data.idMaritalStatus,
      birthdate: dayjs(data.birthdate).format('YYYY-MM-DDTHH:mm:ssZ'),
      mobilePhoneNumber: data.mobilePhoneNumber,
      password: data.password,
      idResidence: data.idResidence,
      email: data.email,
    }),
  },
  update: {
    title: 'clients.update.resident',
    getLink: (item) => item.id,
    form: form(false),
    variables: (id) => ({
      id,
    }),
    query: UsersResident.getResidentById,
    transformQuery: (i) => {
      const item = {
        ...i.resident,
        birthdate: new Date(i.resident.birthdate),
        civilities: i.resident.civilityByIdCivility.id,
        idMaritalStatus: i.resident.maritalStatusByIdMaritalStatus.id,
        idResidence: { fromDb: i.resident.residenceByIdResidence.name },
      }
      return item
    },
    submitQuery: (data, index) => {
      if (index === 0) {
        return UsersResident.updateResident
      }
      return null
    },
    submit: (id, data) => ({
      id,
      idCivility: data.civilities,
      residentNumber: data.residentNumber,
      firstname: data.firstname,
      lastname: data.lastname,
      idMaritalStatus: data.idMaritalStatus,
      birthdate: data.birthdate,
      mobilePhoneNumber: data.mobilePhoneNumber,
      password: data.password,
      idResidence: data.idResidence?.fromDb ? undefined : data.idResidence,
      email: data.email,
      door: data.door,
    }),
  },
  read: {
    form: [
      {
        id: 'residentNumber',
        label: 'clients.residentNumber',
      },
      {
        id: 'civility',
        label: 'clients.label.civilities',
      },
      {
        type: 'row',
        items: [
          {
            md: 6,
            id: 'lastname',
            label: 'clients.lastname',
          },
          {
            md: 6,
            id: 'firstname',
            label: 'clients.firstname',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'maritalStatus',
            label: 'clients.label.maritalStatus',
          },
          {
            id: 'birthdate',
            label: 'clients.label.birthdate',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'mobilePhoneNumber',
            label: 'clients.label.phoneNumber',
          },
          {
            id: 'email',
            label: 'clients.label.email',
          },
        ],
      },
      {
        id: 'residence',
        label: 'clients.label.idResidence',
      },
    ],
    getLink: (item) => item.id,
    variables: (id) => ({
      id,
    }),
    query: UsersResident.getResidentById,
    transformQuery: (i) => {
      const item = {
        ...i.resident,
        civility: i.resident.civilityByIdCivility.name,
        maritalStatus: i.resident.maritalStatusByIdMaritalStatus.name,
        residence: i.resident.residenceByIdResidence.name,
        city: i.resident.residenceByIdResidence.cityByIdCity.name,
        birthdate: dayjs(i.resident.birthdate).format('DD/MM/YYYY'),
      }
      return item
    },
  },
  delete: {
    text: 'clients.delete.resident',
    getTitle: (item) => `${item.firstname} ${item.lastname}`,
    query: UsersResident.deleteResident,
    errorMessage: 'clients.delete.error',
  },
}
