/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/display-name */
import { useQuery } from '@apollo/client'
import config from '../config'
import { CatalogArticles, Catalogs } from '../gql'
import getRates from '../gql/Rate'
import { Rates } from './Rates'

const valueForInteger = 'fae5b323-a69e-4cfd-9137-04dc1209a57a'
const valueForNonInteger = 'aad40a4d-41ce-4c45-8e1d-6d5f956a62b8'
const { LIMIT } = config

const ArticlesStatusEnum = [
  { value: 'ENABLED', label: 'Actif' },
  { value: 'UNAVAILABLE', label: 'Indisponible' },
  { value: 'DISABLED', label: 'Inactif' },
]

const showStatusLabel = (val) => {
  const resultArray = ArticlesStatusEnum.filter((item) => item.value === val)
  if (resultArray[0]) {
    return resultArray[0].label
  }
  return ''
}

export const Status = {
  populateForm: () => ArticlesStatusEnum,
}

export const Articles = {
  graphql: true,
  title: 'articles.title',
  secondTitle: 'articles.secondTitle',
  query: Catalogs.getCatalogById,
  transformQuery: (items) => items?.catalog?.label,
  url: (id, params) => (params ? `/catalogues/${params.id}/articles` : `/catalogues/${id}/articles`),
  parentUrl: '/catalogues',
  // parentQuery: Catalogs.getCatalogById,
  // parentTitle: (data) => data.catalog.label,
  list: {
    title: 'articles.list',
    table: [
      {
        head: 'articles.label.label',
        key: 'label',
        width: '20%',
      },
      {
        head: 'articles.label.description',
        key: 'description',
        width: '40%',
      },
      {
        head: 'articles.label.status',
        key: 'status',
        width: '10%',
      },
      {
        head: 'articles.label.rates',
        key: 'rates',
        width: '10%',
      },
      {
        head: 'articles.label.unit_price',
        key: 'unit_price',
        width: '20%',
      },
    ],
    query: CatalogArticles.fetchCatalogArticles,
    variables: (
      limit,
      offset,
      filterDateBegin,
      filterDateEnd,
      orderBy,
      status,
      residenceSelected,
      lang,
      sortType,
      sortDirection,
      props,
      params,
    ) => ({
      first: limit,
      offset,
      idCatalog: params?.id,
    }),
    transformQuery: (items) =>
      items.catalogArticles.nodes.map((article) => {
        // eslint-disable-next-line max-len
        const is_integer =
          valueForInteger ===
          article.articleByIdArticle.unitPricesByIdArticle.nodes[0]
            ?.unitByIdUnit.id
        let valueUnitPrice
        if (is_integer) {
          valueUnitPrice =
            article.articleByIdArticle.unitPricesByIdArticle.nodes[0]?.value
        } else {
          valueUnitPrice =
            article.articleByIdArticle.unitPricesByIdArticle.nodes[0]?.value /
            100
        }
        const formatter = Intl.NumberFormat('fr-FR', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 2,
        })
        return {
          ...article.articleByIdArticle,
          unit_price: `${valueUnitPrice} €`,
          rates: formatter.format([...items.vatRates.nodes].filter(
            (item) => item.id === article.articleByIdArticle.idVatRate,
          )[0].rate),
          status: showStatusLabel(article.articleByIdArticle.status),
          is_integer:
            article.articleByIdArticle.unitPricesByIdArticle.nodes[0]
              ?.unitByIdUnit.name,
        }
      }),
    hasPreviousPage: (data) => data.catalogArticles.pageInfo.hasPreviousPage,
    hasNextPage: (data) => data.catalogArticles.pageInfo.hasNextPage,
    totalPage: (data) => Math.ceil(data.catalogArticles.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    title: 'articles.label.create',
    query: getRates,
    form: [
      {
        id: 'label',
        type: 'text',
        placeholder: 'articles.placeholder.label',
        label: 'articles.label.label',
        required: true,
      },
      {
        id: 'description',
        type: 'textarea',
        placeholder: 'articles.placeholder.description',
        label: 'articles.label.description',
        required: true,
      },
      {
        id: 'status',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        placeholder: 'planning.placeholder.status',
        label: 'planning.label.status',
        required: true,
        data: ArticlesStatusEnum,
      },
      {
        id: 'rates',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        model: Rates,
        placeholder: 'articles.label.rates',
        label: 'articles.label.rates',
        required: true,
      },
      {
        id: 'unit_price',
        type: 'text',
        placeholder: 'articles.placeholder.unit_price',
        label: 'articles.label.unit_price',
        required: true,
      },
      {
        id: 'is_integer',
        type: 'hidden',
        value: false,
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'articles.label.submit',
            md: 2,
          },
        ],
      },
      {
        id: 'unit_price_id',
        type: 'hidden',
      },
    ],
    submitQuery: (data, index) => {
      const rqst = [
        CatalogArticles.createArticle,
        CatalogArticles.createUnitPrice,
        CatalogArticles.createCatalogArticle,
      ]
      if (rqst.length > index) {
        return rqst[index]
      }
    },
    getIdAfterSubmit: (data) => data.createCatalogArticle.catalogByIdCatalog.id,
    transformSubmitQuery: (data, fromDb, indexQuery) => {
      if (indexQuery === 0) {
        return {
          ...data,
          idVatRate: data.rates,
          status: data.status,
          createdId: fromDb.createArticle.article.id,
        }
      }

      return { ...data, idVatRate: data.rates }
    },
    submit: (data, params, index) => {
      if (index === 0) {
        return {
          status: data.status,
          idVatRate: data.rates,
          label: data.label,
          description: data.description,
        }
      }

      if (index === 1) {
        return {
          value: parseInt(data.unit_price, 10),
          idUnit: valueForNonInteger,
          idArticle: data.createdId,
        }
      }

      if (index === 2) {
        return {
          idArticle: data.createdId,
          idCatalog: params.id,
        }
      }
    },
  },
  update: {
    title: 'articles.label.update',
    getLink: (item) => item.id,
    query: CatalogArticles.getArticleById,
    variable: (params) => ({ id: params.subId }),
    transformQuery: (item) => {
      // eslint-disable-next-line max-len
      const is_integer =
        valueForInteger ===
        item.article.unitPricesByIdArticle.nodes[0]?.unitByIdUnit.id
      return {
        ...item.article,
        status: item.article.status,
        rates: item.article.idVatRate,
        unit_price: item.article.unitPricesByIdArticle.nodes[0]?.value,
        unit_price_id: item.article.unitPricesByIdArticle.nodes[0]?.id,
        is_integer: !is_integer,
      }
    },
    submitQuery: (data, index) => {
      const rqst = [
        CatalogArticles.updateArticle,
        CatalogArticles.updateUnitPrice,
      ]
      if (rqst.length > index) {
        return rqst[index]
      }
    },
    submit: (id, data, index, params) => {
      if (index === 0) {
        return {
          label: data.label,
          status: data.status,
          idVatRate: data.rates,
          description: data.description,
          id: params.subId,
        }
      }

      if (index === 1) {
        return {
          value: parseInt(data.unit_price, 10),
          idUnit: valueForNonInteger,
          idArticle: params.subId,
          id: data.unit_price_id,
        }
      }
    },
  },
  delete: {
    text: 'articles.delete.confirm',
    query: CatalogArticles.deleteArticle,
    getTitle: (item) => `${item.label}`,
  },
}
