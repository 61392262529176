import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'shards-react'
import { useTranslation } from 'react-i18next'
import config from '../../../config'
import { shouldShowItem } from '../../../lib'

const SubSidebarNavItem = ({ item }) => {
  const { t } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(item.opened)

  const toggleDropdown = () => {
    if (!item.alwaysOpen) {
      setDropdownOpen(() => !dropdownOpen)
    }
  }

  if (!shouldShowItem(item)) {
    return (<></>)
  }

  return (
    <Dropdown
      open={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle nav caret>
        <div className="d-inline-block item-icon-wrapper">
          {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
          )}
        </div>
        {t(item.title)}
      </DropdownToggle>
      <DropdownMenu>
        {item.subItems.map((sub, index) => {
          if (!shouldShowItem(sub)) {
            return (<></>)
          }
          return (
            <DropdownItem
              key={`${item.title}${index}`}
              href={`${config.REACT_APP_BASENAME}${sub.to}`}
            >
              {sub.htmlBefore && (
              <div
                className="d-inline-block item-icon-wrapper"
                dangerouslySetInnerHTML={{ __html: sub.htmlBefore }}
              />
            )}
              {t(sub.title)}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

SubSidebarNavItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default SubSidebarNavItem
