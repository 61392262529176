/* eslint-disable react/style-prop-object */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const breadcrumb = {
  height: '30px',
  backgroundColor: 'transparent',
  padding: '0',
  margin: '0',
}

const Breadcrumb = ({ type, crumbs }) => {
  const { t } = useTranslation()
  const [breadcrumbs, setBreadcrumbs] = useState()
  const isLast = (index) => index === breadcrumbs.length - 1

useEffect(() => {
  const tempBreadcrumbs = []
  for (let i = 0; i < crumbs.length; i++) {
    if (crumbs[i].label) {
      tempBreadcrumbs.push(crumbs[i])
    }
  }
  if (type === 'create') {
    tempBreadcrumbs.push({ label: 'breadcrumb.create' })
  }
  setBreadcrumbs(tempBreadcrumbs)
}, [crumbs])

  return (
    <nav className="row" aria-label="breadcrumb" style={{ fontSize: '1.625rem', margin: '0' }}>
      {breadcrumbs && (
      <ol className="breadcrumb" style={breadcrumb}>
        {
        breadcrumbs.map((crumb, ci) => {
          const disabled = ((isLast(ci) || !crumb.link) && breadcrumbs.length > 1) ? 'disabled' : ''
          return (
            <li
              key={ci}
              className="breadcrumb-item align-items-center"
            >
              <a
                href={crumb.link}
                className={`btn btn-link ${ disabled }`}
                style={disabled ? { color: '#818ea3' } : { color: '#3d5170' }}
              >
                <p className="page-title">{ t(crumb.label) }</p>
              </a>
            </li>
          )
        })
      }
      </ol>
      )}

    </nav>
  )
}

Breadcrumb.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.string,
}

Breadcrumb.defaultProps = {
  type: null,
}

export default Breadcrumb
