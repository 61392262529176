import { gql } from '@apollo/client'

const fetchContactReasons = gql`
query fetchContactReasons($first: Int, $offset: Int) {
  internalReasons(first: $first, offset: $offset) {
    nodes {
      label
      id
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
`

const fetchContactReason = gql`
query fetchContactReason($id: UUID!) {
  internalReason(id: $id) {
    label
    id
  }
}
`

const createContactReason = gql`
mutation createContactReason($label: String!) {
  createInternalReason(input: {internalReason: {label: $label}}) {
    clientMutationId
    internalReason {
      id
    }
  }
}
`

const updateContactReason = gql`
mutation updateContactReason($label: String= "", $id: UUID!) {
  updateInternalReason(input: {patch: {label: $label}, id: $id}) {
    clientMutationId
  }
}
`

const deleteContactReason = gql`
mutation deleteContactReason($id: UUID!) {
  deleteInternalReason(input: {id: $id}) {
    clientMutationId
  }
}
`

const fetchContactMessages = gql`
query fetchContactMessages($offset: Int, $first: Int, $orderBy: [InternalMessagesOrderBy!]) {
  internalMessages(offset: $offset, first: $first, orderBy: $orderBy) {
    nodes {
      id
      content
      createdAt
      internalReasonByIdReason {
        label
      }
      staffByIdRecipient {
        firstname
        email
        id
        lastname
      }
      residentByIdSender {
        firstname
        email
        id
        lastname
        mobilePhoneNumber
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`

const fetchContactMessage = gql`
query fetchContactMessage($id: UUID!) {
  internalMessage(id: $id) {
    internalReasonByIdReason {
      label
    }
    content
    createdAt
    residentByIdSender {
      firstname
      lastname
      email
      mobilePhoneNumber
      residenceByIdResidence {
        name
        cityByIdCity {
          name
        }
      }
    }
    staffByIdRecipient {
      firstname
      lastname
      email
    }
  }
}
`

export default {
  fetchContactReasons,
  fetchContactReason,
  createContactReason,
  updateContactReason,
  deleteContactReason,
  fetchContactMessages,
  fetchContactMessage,
}
