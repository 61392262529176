import { gql } from '@apollo/client'

const fetchExternals = gql`
  query getClients($first: Int, $offset: Int, $id: BigFloat!) {
    externals(first: $first, offset: $offset, filter: {idRole: {equalTo: $id}}) {
      nodes {
        id
        firstname
        lastname
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const deleteExternal = gql`
  mutation deleteExternal($id: UUID!) {
    deleteExternal(input: { id: $id }) {
      clientMutationId
    }
  }
`

const createExternal = gql`
  mutation createExternal(
    $firstname: String!
    $lastname: String!
    $mobilePhoneNumber: MobilePhoneNumberFr
    $password: String!
    $idRole: BigFloat!
    $email: EmailAddress
  ) {
    createExternal(
      input: {
        external: {
          firstname: $firstname
          lastname: $lastname
          mobilePhoneNumber: $mobilePhoneNumber
          password: $password
          idRole: $idRole
          email: $email
        }
      }
    ) {
      external {
        id
      }
    }
  }
`

const getExternalById = gql`
  query getExternalById($id: UUID!) {
    external(id: $id) {
      lastname
      firstname
      email
      mobilePhoneNumber
      id
    }
  }
`

const updateExternal = gql`
  mutation updateExternal(
    $firstname: String!
    $lastname: String!
    $mobilePhoneNumber: MobilePhoneNumberFr
    $password: String
    $idRole: BigFloat!
    $email: EmailAddress
    $id: UUID!
  ) {
    updateExternal(
      input: {
        patch: {
          firstname: $firstname
          lastname: $lastname
          mobilePhoneNumber: $mobilePhoneNumber
          password: $password
          idRole: $idRole
          email: $email
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

export default {
  fetchExternals,
  deleteExternal,
  createExternal,
  getExternalById,
  updateExternal,
}
