import React from 'react'
import RessourcePlanningList from '../components/automated/custom/RessourcePlanningList'
import { Ressources } from '../gql'

export const RessourcePlanning = {
  graphql: true,
  title: 'ressources.planning.title',
  url: () => '/ressourcesList',
  query: Ressources.getRessourceWithCityAndRes,
  transformQuery: (items) => items?.resource?.name,
  update: {
    title: 'ressources.planning.list',
    getLink: (item) => item.id,
    form: [
      {
        id: 'residence',
        type: 'text',
        placeholder: 'ressources.residence',
        label: 'ressources.residence',
        readOnly: true,
      },
      {
        id: 'city',
        type: 'text',
        placeholder: 'ressources.city',
        label: 'ressources.city',
        readOnly: true,
      },
      {
        id: 'name',
        type: 'text',
        placeholder: 'ressources.planning.ressourcename',
        label: 'ressources.planning.ressourcename',
        readOnly: true,
      },
      {
        id: 'customlist',
        type: 'custom',
        component: (props) => <RessourcePlanningList {...props} />,
      },
    ],
    query: Ressources.getRessourceWithCityAndRes,
    transformQuery: (item) => ({
      ...item.resource,
      city: item?.resource?.residenceByIdResidence?.cityByIdCity?.name,
      residence: item?.resource?.residenceByIdResidence?.name,
    }),
  },
  create: {
    title: 'ressources.planning.reserve',
    form: [
      {
        id: 'startdate',
        type: 'datetimepicker',
        time: true,
        placeholder: 'ressources.planning.choosedate',
        label: 'ressources.planning.startdate',
      },
      {
        id: 'enddate',
        type: 'datetimepicker',
        time: true,
        placeholder: 'ressources.planning.choosedate',
        label: 'ressources.planning.enddate',
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'ressources.label.submit',
            md: 2,
          },
        ],
      },
    ],
    getIdAfterSubmit: (data) => data.createResourceBooking.resourceBooking.id,
    transformQuery: (item) => item,
    submitQuery: (data, index) => {
      if (index === 0) {
        return Ressources.createRessourcePlanning
      }
    },
    submit: (data, params) => (
      {
      idResource: params.resId,
      startDatetime: data.startdate ? new Date(data.startdate) : new Date(),
      endDatetime: data.enddate ? new Date(data.enddate) : 'infinity',
    }
    ),
  },
}
