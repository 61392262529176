import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import React from 'react'
import DatePicker from 'react-datepicker'
import ReactQuill from 'react-quill'
import {
  Button, FormCheckbox, FormFeedback, FormInput,
  FormRadio, FormSelect, FormTextarea,
} from 'shards-react'
import routes from '../routes'
import config from '../config'

export const shouldShowItem = (item) => {
  let roles = []
  routes?.forEach((route) => {
    if (route.path === item.pathForAuthorizedRole) {
      roles = route.authorizedRole
    }
  })

  if (item.authorizedRole) {
    roles = [
      ...roles,
      ...item.authorizedRole,
    ]
  } else if (!item.to) {
    return true
  }

  if (roles?.length > 0) {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      const usr = jwtDecode(accessToken)
      return roles.indexOf(usr.role) !== -1
    }
  }

  return false
}

export const userCanDoAction = (roles) => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    const usr = jwtDecode(accessToken)
    return roles?.indexOf(usr.role) !== -1
  }
}

export const isUserTokenExpired = (token) => {
  if (token) {
    const { exp } = jwtDecode(token)
    return !exp || (dayjs().isAfter(dayjs(exp * 1000)))
  }
  return true
}

export const dataURItoBlob = (dataURL) => {
  const BASE64_MARKER = ';base64,'
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(',')
    const contentType = parts[0].split(':')[1]
    const raw = parts[1]

    // eslint-disable-next-line no-undef
    return new Blob([raw], { type: contentType })
  }
  const parts = dataURL.split(BASE64_MARKER)
  const contentType = parts[0].split(':')[1]
  const raw = window.atob(parts[1])
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  // eslint-disable-next-line no-undef
  return new Blob([uInt8Array], { type: contentType })
}

export const populateFormAfterApi = (item, data, formFromModel, setData) => {
  const tData = {
    ...data,
  }

  if (item.initialData) {
    tData.initialData = item.initialData
  }

  formFromModel.forEach(async (row) => {
    if (row.type === 'row') {
      row.items.forEach((field) => {
        if (field.id && item[field.id]) {
          tData[field.id] = item[field.id]
          setData(tData)
        }
      })
    } else if (row.id && item[row.id]) {
      tData[row.id] = item[row.id]
      setData(tData)
    }
  })
}

export const isValidField = (field, value, data) => {
  if (!field.id) {
    return true
  }

  let valid = true
  if (field.isValid) {
    valid = field.isValid(data)
  } else if (field.required) {
    if (field.type === 'select') {
      valid = value !== field.defaultValue && !!value
    } else if (value === '') {
      valid = false
    } else {
      valid = !!value

      if (field.regex && valid) {
        valid = field.regex.test(value)
      }
    }
  } else if (field.regex && valid && value !== '' && value !== undefined) {
    valid = field.regex.test(value)
  }
  return valid
}

export const onChangeCustom = (id, value, data, setData) => {
  const tData = {
    ...data,
    [id]: value,
  }
  setData(tData)
}

export const couldSendForm = (validField, formFromModel, data, setValidField) => {
  let couldSendFormBool = true
  const tvalidField = {
    ...validField,
  }
  formFromModel.forEach((row) => {
    if (row.type === 'row') {
      row.items.forEach((field) => {
        tvalidField[field.id] = isValidField(field, data[field.id], data)
        if (!tvalidField[field.id]) {
          couldSendFormBool = false
        }
      })
    } else {
      tvalidField[row.id] = isValidField(row, data[row.id], data)
      if (!tvalidField[row.id]) {
        couldSendFormBool = false
      }
    }
  })

  setValidField(tvalidField)
  return couldSendFormBool
}

export const makeMyInput = (
  model,
  field,
  data,
  setData,
  validField,
  setValidField,
  formSubmit,
  submitForm,
  populatedForm,
  t,
  error,
  history,
  id,
  setIsOpen,
  setActionInModal,
  mutationLoading,
  loadingExtra,
  mutationError,
  params,
  props,
) => {
  if (!field) return
  switch (field.type) {
    case 'select':
      return (
        <>
          <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
            {t(field.label)}
            <span>&nbsp;</span>
            {field.label && field.required && (<span>*</span>)}
          </label>
          <FormSelect
            id={`fe${field.id}`}
            value={data[field.id]}
            valid={!!validField[field.id] && field.showOk && formSubmit}
            invalid={!validField[field.id] && formSubmit}
            onChange={((event) => {
              const tData = {
                ...data,
                [field.id]: event.target.value,
              }
              setData(tData)
              const tvalidField = {
                ...validField,
                [field.id]: isValidField(field, event.target.value),
              }
              setValidField(tvalidField)
            })}
          >
            <option value="">{t(field.defaultValue)}</option>
            {field.data && field.data.map((option, index) => (
              <option value={option.value} key={`option${index}`}>{option.label}</option>
            ))}
            {populatedForm[field.id] && populatedForm[field.id].map((option, index) => (
              <option value={option.value} key={`option${index}`}>{option.label}</option>
            ))}
          </FormSelect>
          <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
          <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
        </>
      )
    case 'wysiwyg':
      return (
        <>
          <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
            {t(field.label)}
            <span>&nbsp;</span>
            {field.label && field.required && (<span>*</span>)}
          </label>
          <ReactQuill
            id={`fe${field.id}`}
            value={data[field.id] ? data[field.id] : ''}
            className="add-new-post__editor mb-1"
            onChange={(value) => {
              const tData = {
                ...data,
                [field.id]: value,
              }
              setData(tData)
            }}
          />
        </>
      )
    case 'textarea':
      return (
        <>
          <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
            {t(field.label)}
            <span>&nbsp;</span>
            {field.label && field.required && (<span>*</span>)}
          </label>
          <FormTextarea
            id={`fe${field.id}`}
            valid={!!validField[field.id] && field.showOk && formSubmit}
            invalid={!validField[field.id] && formSubmit}
            value={data[field.id]}
            disabled={field.disabled}
            rows="5"
            onChange={(event) => {
              const tData = {
                ...data,
                [field.id]: event.target.value,
              }
              setData(tData)

              const tvalidField = {
                ...validField,
                [field.id]: isValidField(field, event.target.value),
              }
              setValidField(tvalidField)
            }}
          />
          <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
        </>
      )
    case 'checkbox':
      return (
        <>
          <FormCheckbox
            checked={field.initCheck ? !data[field.id] : data[field.id]}
            value={data[field.id]}
            valid={!!validField[field.id] && field.showOk && formSubmit}
            invalid={!validField[field.id] && formSubmit}
            onChange={(() => {
              const tData = {
                ...data,
                [field.id]: !data[field.id],
              }
              setData(tData)

              const tvalidField = {
                ...validField,
                [field.id]: isValidField(field, !data[field.id]),
              }
              setValidField(tvalidField)
            })}
          >
            {field.label && (<>{t(field.label)}</>)}
            {field.labelWithLink && (
            <span>
              {field.labelWithLink.map((label, index) => (
                <span key={`label${index}`}>
                  {label.link ? (
                    <a href={label.link} target={label.target} rel="noopener noreferrer">{t(label.text)}</a>
                  ) : (
                    <>{t(label.text)}</>
                  )}
                </span>
              ))}
            </span>
            )}
          </FormCheckbox>
          <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
          <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
        </>
      )
    case 'checkboxes':
      return (
        <>
          <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
            {t(field.label)}
            <span>&nbsp;</span>
            {field.label && field.required && (<span>*</span>)}
          </label>
          { !validField[field.id] && formSubmit && (
            <FormFeedback
              invalid
              style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
            >
              {t('form.fieldInvalid')}
            </FormFeedback>
          )}
          {field.data && field.data.map((box, index) => (
            <span key={`multi-checkbox${field.id}-${index}`}>
              { makeMyInput(
                model,
                {
                  type: 'multi-checkbox',
                  id: field.id,
                  label: box.label,
                  value: box.value,
                },
                data,
                setData,
                validField,
                setValidField,
                formSubmit,
                submitForm,
                populatedForm,
                t,
                error,
                history,
                id,
                setIsOpen,
                setActionInModal,
                mutationLoading,
                loadingExtra,
                mutationError,
              )}
            </span>
          ))}
          {populatedForm[field.id] && populatedForm[field.id].map((box, index) => (
            <span key={`multi-checkbox${field.id}-${index}`}>
              { makeMyInput(
                model,
                {
                  type: 'multi-checkbox',
                  id: field.id,
                  label: box.label,
                  value: box.value,
                },
                data,
                setData,
                validField,
                setValidField,
                formSubmit,
                submitForm,
                populatedForm,
                t,
                error,
                history,
                id,
                setIsOpen,
                setActionInModal,
                mutationLoading,
                loadingExtra,
                mutationError,
              )}
            </span>
          ))}
        </>
      )
    case 'multi-checkbox':
      return (
        <FormCheckbox
          checked={data[field.id] && data[field.id].indexOf(field.value) !== -1}
          onChange={(() => {
            const validatedFields = data[field.id] || []
            const pos = validatedFields.indexOf(field.value)
            if (pos === -1) {
              validatedFields.push(field.value)
            } else {
              validatedFields.splice(pos, 1)
            }

            const tData = {
              ...data,
              [field.id]: validatedFields,
            }
            setData(tData)
          })}
        >
          {field.label && (<>{t(field.label)}</>)}
        </FormCheckbox>
      )
    case 'radios':
      return (
        <>
          <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
            {t(field.label)}
            <span>&nbsp;</span>
            {field.label && field.required && (<span>*</span>)}
          </label>
          { !validField[field.id] && formSubmit && (
            <FormFeedback
              invalid
              style={{ display: 'block', marginBottom: '10px', marginTop: '-5px' }}
            >
              {t('form.fieldInvalid')}
            </FormFeedback>
          )}
          {field.data && field.data.map((box, index) => (
            <span key={`radio${field.id}-${index}`}>
              { makeMyInput(
                model,
                {
                  type: 'radio',
                  id: field.id,
                  label: box.label,
                  value: box.value,
                },
                data,
                setData,
                validField,
                setValidField,
                formSubmit,
                submitForm,
                populatedForm,
                t,
                error,
                history,
                id,
                setIsOpen,
                setActionInModal,
                mutationLoading,
                loadingExtra,
                mutationError,
              )}
            </span>
          ))}
          {populatedForm[field.id] && populatedForm[field.id].map((box, index) => (
            <span key={`radio${field.id}-${index}`}>
              { makeMyInput(
                model,
                {
                  type: 'radio',
                  id: field.id,
                  label: box.label,
                  value: box.value,
                },
                data,
                setData,
                validField,
                setValidField,
                formSubmit,
                submitForm,
                populatedForm,
                t,
                error,
                history,
                id,
                setIsOpen,
                setActionInModal,
                mutationLoading,
                loadingExtra,
                mutationError,
              )}
            </span>
          ))}
        </>
      )
    case 'radio':
      return (
        <FormRadio
          checked={data[field.id] === field.value}
          onChange={(() => {
            const tData = {
              ...data,
              [field.id]: field.value,
            }
            setData(tData)
          })}
        >
          {field.label && (<>{t(field.label)}</>)}
        </FormRadio>
      )
    case 'datetimepicker':
      return (
        <>
          <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
            {t(field.label)}
            <span>&nbsp;</span>
            {field.label && field.required && (<span>*</span>)}
          </label>
          <DatePicker
            selected={data[field.id]}
            timeInputLabel={t('ui.timeInputLabel')}
            dateFormat={field.time ? t('ui.dateTimeFormat') : t('ui.dateFormat')}
            showTimeInput={field.time}
            disabled={field.disabled}
            placeholderText={t(field.placeholder)}
            className={`form-control ${data[field.id] && data[field.id] === '' ? 'is-invalid' : ''}`}
            onChange={((value) => {
              const tData = {
                ...data,
                [field.id]: value,
              }
              setData(tData)

              const tvalidField = {
                ...validField,
                [field.id]: isValidField(field, value),
              }
              setValidField(tvalidField)
            })}
          />
        </>
      )
    case 'submit':
      return (
        <>
          <Button
            style={{ width: '175px', backgroundColor: '#32A0E6', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', ...field.overrideStyle }}
            onClick={submitForm}
          >
            <p className="p-0 m-0 font-weight-bold">{t(field.label)}</p>
          </Button>
          {(config.CONNECTOR === 'graphql' && model.graphql && (mutationLoading || loadingExtra > 0)) && (<p>{t('ui.mutationLoading')}</p>)}
          {config.CONNECTOR === 'graphql' && model.graphql && mutationError && (
            <p>
              {t('ui.mutationErrorUpdate')}
              <br />
              {mutationError?.message.indexOf('duplicate') !== -1 ? (
                t('ui.mutationErrorDuplicateKey')
              ) : (
                mutationError?.message
              )}
            </p>
          )}
          {error && error.length > 0 && error.map((err, index) => (
            <p key={index}>{t(err)}</p>
          ))}
        </>
      )
    case 'button':
      return (
        <Button
          type="button"
          className={field.className}
          onClick={() => {
            if (field.history) {
              history.push(field.history)
            } else if (field.withConfirmation) {
              setIsOpen(true)
              setActionInModal(field)
            } else {
              field.onClick(id)
            }
          }}
        >
          {t(field.label)}
        </Button>
      )
    case 'custom':
      return (
        <>
          <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
            {t(field.label)}
            <span>&nbsp;</span>
            {field.label && field.required && (<span>*</span>)}
          </label>
          {field.component({
            model,
            id,
            data,
            onChangeCustom: (fieldId, value) => {
              onChangeCustom(fieldId, value, data, setData)
            },
            field,
          })}
        </>
      )
    case 'back':
      return (
        <Button
          type="button"
          style={{ width: '175px', backgroundColor: '#FFFF', color: '#485156', border: 'none', boxShadow: '0 1px 3px rgba(0,0,0,.2)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => {
            history.push(model.url && typeof model.url === 'function' ? model.url(id, params, props) : model.url)
          }}
        >
          <p className="p-0 m-0 font-weight-bold">{t(field.label)}</p>
        </Button>
      )
    default:
      return (
        <>
          <label className="font-weight-bold" htmlFor={`fe${field.id}`}>
            {t(field.label)}
            <span>&nbsp;</span>
            {field.label && field.required && (<span>*</span>)}
          </label>
          <FormInput
            id={`fe${field.id}`}
            type={field.type}
            value={data[field.id]}
            valid={!!validField[field.id] && field.showOk && formSubmit}
            invalid={!validField[field.id] && formSubmit}
            readOnly={field.readOnly}
            placeholder={t(field.placeholder)}
            onChange={((event) => {
              const tData = {
                ...data,
                [field.id]: event.target.value,
              }
              setData(tData)

              const tvalidField = {
                ...validField,
                [field.id]: isValidField(field, event.target.value),
              }
              setValidField(tvalidField)
            })}
          />
          <FormFeedback valid>{t('form.fieldValid')}</FormFeedback>
          <FormFeedback invalid>{t('form.fieldInvalid')}</FormFeedback>
        </>
      )
  }
}
