const BASE_URL = {
  dev: 'https://logifim-dev.bettercalldave.io/ws/',
  uat: 'https://logifim-uat.bettercalldave.io/ws/',
  prod: 'https://app.v-services.org/ws/',
}

const BASE_GRAPHQL = {
  dev: 'https://logifim-dev.bettercalldave.io/graphql',
  uat: 'https://logifim-uat.bettercalldave.io/graphql',
  prod: 'https://app.v-services.org/graphql',
}

const ROLES = {
  dev: {
    logifim_admin: '37493',
    logifim_coordinator: '37494',
    logifim_manager: '37495',
    logifim_service_provider: '37496',
    logifim_resident: '37497',
    logifim_external: '37498',
  },
  uat: {
    logifim_admin: '37493',
    logifim_coordinator: '37494',
    logifim_manager: '37495',
    logifim_service_provider: '37496',
    logifim_resident: '37497',
    logifim_external: '37498',
  },
  prod: {
    logifim_admin: '16388',
    logifim_coordinator: '16389',
    logifim_manager: '16390',
    logifim_service_provider: '16391',
    logifim_resident: '16392',
    logifim_external: '16393',
  },
}

const BASE_ROLES = {
  dev: { admin: 37493, coordinator: 37494 },
  uat: { admin: 37493, coordinator: 37494 },
  prod: { admin: 16388, coordinator: 16389 },
}

const {
  REACT_APP_INFRA = 'dev',
  REACT_APP_PREFIX = '',
  REACT_APP_BASENAME = '/',
} = process.env

const API_BASE_URL = BASE_URL[REACT_APP_INFRA]
const GRAPHQL_URL = BASE_GRAPHQL[REACT_APP_INFRA]
const ID_ROLES = BASE_ROLES[REACT_APP_INFRA]
const ROLES_ID = ROLES[REACT_APP_INFRA]

export default {
  API_BASE_URL,
  REACT_APP_PREFIX,
  REACT_APP_INFRA,
  LOGIN_URL: '/token',
  CONNECTOR: 'graphql',
  GRAPHQL_URL,
  REACT_APP_BASENAME,
  fetchPolicy: 'network-only',
  notifyOnNetworkStatusChange: true,
  LIMIT: 20,
  ID_ROLES,
  authorizedRole: [
    'logifim_admin',
    'logifim_manager',
    'logifim_coordinator',
    'logifim_service_provider',
  ],
  ROLES_ID,
}
