import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Row,
} from 'shards-react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import PageTitle from '../components/common/PageTitle'
import { List } from '../components/automated/form'
import { RessourcesForResidence } from '../models'
import { Residences } from '../gql'
import config from '../config'
import { captureMessageWithAttachment } from '../lib/sentry'

const Ressources = () => {
  const { t } = useTranslation()
  const [city, setCity] = useState('')
  const [cities, setCities] = useState([])
  const [res, setRes] = useState('')
  const [residencesBackup, setResidenceBackup] = useState([])
  const [residencesInSelect, setResidenceInSelect] = useState([])
  const [resources, setResources] = useState([])
  const history = useHistory()

  const [fetchResidences, { data: dataResults }] = useLazyQuery(
    Residences.getResidencesWithRessources,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      onError: (err) => {
        const request = Residences.getResidencesWithRessources
        const event = {
          request: request?.definitions[0]?.name?.value,
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (data) => {
        setResidenceBackup([...data.residences.nodes])
        setResidenceInSelect([...data.residences.nodes])
        const ct = []
        data.residences.nodes.forEach((c) => {
          if (!ct.find((a) => a.name === c.cityByIdCity.name)) {
            ct.push(c.cityByIdCity)
          }
        })
        setCities(ct)
        if (res !== '') {
          const resData = data?.residences?.nodes?.find((a) => a.id === res)
          setResources([...resData?.resourcesByIdResidence.nodes])
        }
      },
    },
  )

  const setList = () => {
    const resData = dataResults?.residences?.nodes?.find((a) => a.id === res)
    setResources([...resData?.resourcesByIdResidence.nodes])
  }

  useEffect(() => {
    fetchResidences()
  }, [])

  useEffect(() => {
    if (city === '') {
      setResidenceInSelect(residencesBackup)
    } else {
      const rs = []
      residencesBackup.forEach((c) => {
        if (c.cityByIdCity.id === city) {
          rs.push(c)
        }
      })
      setResidenceInSelect(rs)
    }
  }, [city])

  useEffect(() => {
    if (res !== '') {
      setList()
    }
  }, [res])

  const redirectToAddRessource = () => {
    history.push(`ressource-management/${res}/create`)
  }

  return (
    <Container fluid className="main-content-container px-4 container-fluid">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="9"
          title={`${t('ressources.title')}`}
          className="text-sm-left"
          dynamicStyles="clickable"
          clickable={true}
          onClick={() => history.push('/ressourcesList')}
        />
      </Row>

      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col md={3} className="py-2">
                      <FormSelect
                        id="feCity"
                        value={city}
                        onChange={(event) => {
                          setCity(event.target.value)
                        }}
                      >
                        <option value="">
                          {t('residences.services.chooseCity')}
                        </option>
                        {cities.length > 0 &&
                          cities.map((option, index) => (
                            <option
                              value={option.id}
                              key={`optionCity${index}`}
                            >
                              {option.name}
                            </option>
                          ))}
                      </FormSelect>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} className="py-2">
                      <FormSelect
                        id="feRes"
                        value={res}
                        onChange={(event) => {
                          setRes(event.target.value)
                        }}
                      >
                        <option value="">
                          {t('residences.services.chooseResidence')}
                        </option>
                        {residencesInSelect.length > 0 &&
                          residencesInSelect.map((option, index) => (
                            <option value={option.id} key={`optionRes${index}`}>
                              {option.name}
                            </option>
                          ))}
                      </FormSelect>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7}>
                      {res !== '' && resources && resources.length !== 0 ? (
                        <List
                          {...{
                            model: RessourcesForResidence,
                            dataToShow: resources,
                            noHeader: true,
                            noFooter: true,
                            reload: () => fetchResidences(),
                            res,
                          }}
                        />
                      ) : (
                        <p>{t('residences.nothingtoshow')}</p>
                      )}
                      <Button
                        style={{
                          width: '175px',
                          backgroundColor: '#32A0E6',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: 'none',
                        }}
                        disabled={res === ''}
                        onClick={redirectToAddRessource}
                      >
                        <p className="p-0 m-0 font-weight-bold">
                          {t('ressources.addResource')}
                        </p>
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Ressources
