/* eslint-disable react/display-name */
import dayjs from 'dayjs'
import config from '../config'
import { Contact } from '../gql'

const { LIMIT } = config

export const Contacts = {
  graphql: true,
  title: 'contacts.title',
  query: Contact.fetchContactMessage,
  transformQuery: (items) => items.internalMessage?.residentByIdSender?.lastname,
  url: '/contact',
  list: {
    title: 'contacts.list',
    table: [
      {
        head: 'contacts.label.reason',
        key: 'reason',
        width: '20%',
      },
      {
        head: 'contacts.label.firstname',
        key: 'firstname',
        width: '20%',
      },
      {
        head: 'contacts.label.lastname',
        key: 'lastname',
        width: '20%',
      },
      {
        head: 'contacts.label.email',
        key: 'email',
        width: '20%',
      },
      {
        head: 'contacts.label.createdat',
        key: 'createdAt',
        width: '20%',
      },
    ],
    query: Contact.fetchContactMessages,
    variables: (limit, offset) => ({
      first: limit,
      offset,
      orderBy: 'CREATED_AT_DESC',
    }),
    transformQuery: (items) => items.internalMessages.nodes.map((i) => ({
        ...i,
        reason: i.internalReasonByIdReason.label,
        firstname: i.residentByIdSender.firstname,
        lastname: i.residentByIdSender.lastname,
        email: i.residentByIdSender.email,
        createdAt: dayjs(i.createdAt).format('DD/MM/YYYY HH:mm'),
      })),

    hasPreviousPage: data => data.internalMessages.pageInfo.hasPreviousPage,
    hasNextPage: data => data.internalMessages.pageInfo.hasNextPage,
    totalPage: data => Math.ceil(data.internalMessages.totalCount / LIMIT),
    limit: LIMIT,
  },
  read: {
    form: [
      {
        type: 'row',
        items: [
          {
            md: 6,
            id: 'reason',
            label: 'contacts.label.reason',
          },
          {
            md: 6,
            id: 'createdAt',
            label: 'contacts.label.createdat',
          },
        ],
      },
      {
        label: 'contacts.label.content',
        id: 'content',
        width: '25%',
      },
      {
        type: 'row',
        items: [
          {
            md: 6,
            id: 'lastname',
            label: 'contacts.label.lastname',
          },
          {
            md: 6,
            id: 'firstname',
            label: 'contacts.label.firstname',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'mobilePhoneNumber',
            label: 'contacts.label.phoneNumber',
          },
          {
            id: 'email',
            label: 'contacts.label.email',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'residence',
            label: 'contacts.label.residence',
          },
          {
            id: 'city',
            label: 'contacts.label.city',
          },
        ],
      },
    ],
    getLink: (item) => item.id,
    variables: (id) => ({
      id,
    }),
    query: Contact.fetchContactMessage,
    transformQuery: (i) => {
      const item = {
        ...i.internalMessage,
       reason: i.internalMessage.internalReasonByIdReason.label,
       firstname: i.internalMessage.residentByIdSender.firstname,
       lastname: i.internalMessage.residentByIdSender.lastname,
       mobilePhoneNumber: i.internalMessage.residentByIdSender.mobilePhoneNumber,
       email: i.internalMessage.residentByIdSender.email,
       city: i.internalMessage.residentByIdSender.residenceByIdResidence.cityByIdCity.name,
       residence: i.internalMessage.residentByIdSender.residenceByIdResidence.name,
       createdAt: dayjs(i.createdAt).format('DD/MM/YYYY HH:mm'),
      }
      return item
    },
  },
}
