import React from 'react'
import jwtDecode from 'jwt-decode'
import AutomatedModalBatchDelete from '../components/automated/custom/AutomatedModalBatchDelete'
import { UsersStaffs } from '../gql'
import config from '../config'
import UserProvider from '../gql/UserProvider'

const { LIMIT } = config

const form = (create) => [
  {
    type: 'row',
    items: [
      {
        md: 6,
        id: 'lastname',
        type: 'text',
        placeholder: 'clients.lastname',
        label: 'clients.lastname',
        required: true,
      },
      {
        md: 6,
        id: 'firstname',
        type: 'text',
        placeholder: 'clients.firstname',
        label: 'clients.firstname',
        required: true,
      },
    ],
  },
  {
    type: 'row',
    items: [
      {
        id: 'mobilePhoneNumber',
        type: 'tel',
        placeholder: 'clients.placeholder.phoneNumber',
        label: 'clients.label.phoneNumber',
        regex: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/i,
      },
      {
        id: 'email',
        type: 'email',
        placeholder: 'clients.placeholder.email',
        label: 'clients.label.email',
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    ],
  },
  {
    id: 'password',
    type: 'password',
    label: 'clients.label.password',
    placeholder: 'clients.placeholder.password',
    required: create,
  },
  {
    type: 'row',
    right: true,
    items: [
      {
        type: 'back',
        label: 'ui.cancel',
        md: 2,
      },
      {
        type: 'submit',
        label: 'residences.label.submit',
        md: 2,
      },
    ],
  },
]

export const Staffs = {
  graphql: true,
  title: 'clients.title.staffs',
  subtitle: '',
  query: UsersStaffs.getStaffById,
  transformQuery: (items) => items?.external?.lastname || items?.staff?.lastname,
  url: (id, params, props) => (props?.custom?.path ? props?.custom?.path : `/staffs/${params?.role}`),
  list: {
    title: 'clients.title.staff',
    table: [
      {
        head: 'clients.firstname',
        key: 'firstname',
        width: '50%',
      },
      {
        head: 'clients.lastname',
        key: 'lastname',
        width: '50%',
      },
    ],
    batchActions: {
      title: 'ui.batchActions.title',
      items: [
        {
          label: 'ui.batchActions.delete',
          title: 'clients.delete.staffs',
          component: (props) => <AutomatedModalBatchDelete {...props} />,
        },
      ],
    },
    query: (props) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (
          props?.custom?.roleId === 37494 &&
          usr?.role === 'logifim_manager'
        ) {
          return UsersStaffs.fetchCoordinatorForManager
        }
        if (
          props?.custom?.roleName &&
          props?.custom?.roleName === 'logifim_service_provider'
        ) {
          return UserProvider.fetchProvider
        }
      }

      return UsersStaffs.fetchStaffs
    },
    variables: (
      limit,
      offset,
      dateBegin,
      dateEnd,
      orderBy,
      status,
      residenceSelected,
      lang,
      sortType,
      sortDirection,
      props,
      params,
      role,
    ) => {
      const data = {
        first: limit,
        offset,
        idRole: params?.role || props?.custom?.roleId,
        id: role && role,
      }

      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (
          props?.custom?.roleId === 37494 &&
          usr?.role === 'logifim_manager'
        ) {
          data.idRole = usr.id
        }
      }

      return data
    },
    transformQuery: (items, props) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (
          props?.custom?.roleName &&
          props?.custom?.roleName === 'logifim_service_provider'
        ) {
          return items.externals.nodes
        }

        if (
          props?.custom?.roleId === 37494 &&
          usr?.role === 'logifim_manager'
        ) {
          const usrs = []
          items?.residences?.nodes.forEach((node) => {
            node?.residenceCoordinationsByIdResidence?.nodes?.forEach(
              (user) => {
                if (
                  !usrs.find((u) => u.id === user?.staffByIdCoordinator?.id)
                ) {
                  usrs.push(user.staffByIdCoordinator)
                }
              },
            )
          })
          return usrs
        }
      }
      return items.staffs.nodes
    },
    hasPreviousPage: (data, props) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (
          props?.custom?.roleName &&
          props?.custom?.roleName === 'logifim_service_provider'
        ) {
          return data.externals.pageInfo.hasPreviousPage
        }
        if (
          props?.custom?.roleId === 37494 &&
          usr?.role === 'logifim_manager'
        ) {
          return data.residences.hasPreviousPage
        }
      }
      return data.staffs.pageInfo.hasPreviousPage
    },
    hasNextPage: (data, props) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (
          props?.custom?.roleName &&
          props?.custom?.roleName === 'logifim_service_provider'
        ) {
          return data.externals.pageInfo.hasNextPage
        }
        if (
          props?.custom?.roleId === 37494 &&
          usr?.role === 'logifim_manager'
        ) {
          return data.residences.hasNextPage
        }
      }
      return data.staffs.pageInfo.hasNextPage
    },
    totalPage: (data, props) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        const usr = jwtDecode(accessToken)
        if (
          props?.custom?.roleName &&
          props?.custom?.roleName === 'logifim_service_provider'
        ) {
          return Math.ceil(data.externals.totalCount / LIMIT)
        }
        if (
          props?.custom?.roleId === 37494 &&
          usr?.role === 'logifim_manager'
        ) {
          return Math.ceil(data.residences.totalCount / LIMIT)
        }
      }
      return Math.ceil(data.staffs.totalCount / LIMIT)
    },
    limit: LIMIT,
  },
  create: {
    form: form(true),
    getIdAfterSubmit: (data) => (data.createExternal
        ? data.createExternal.external.id
        : data.createStaff.staff.id),
    submitQuery: (data, index) => {
      if (index === 0) {
        if (data.role) return UserProvider.createUserProvider
        return UsersStaffs.createStaff
      }
    },
    submit: (data, params, indexQuery, props) => ({
      firstname: data.firstname,
      lastname: data.lastname,
      mobilePhoneNumber:
        data.mobilePhoneNumber !== '' ? data.mobilePhoneNumber : null,
      password: data.password,
      idRole: params.role || props?.custom?.roleId || data?.idRole,
      email: data.email,
    }),
  },
  update: {
    title: 'clients.update.staffs',
    getLink: (item) => item.id,
    form: form(false),
    variables: (id) => ({
      id,
    }),
    query: UsersStaffs.getStaffById,
    transformQuery: (i) => i.staff,
    submitQuery: (data, index) => {
      if (index === 0) {
        return UsersStaffs.updateStaff
      }
      return null
    },
    submit: (id, data, indexQuery, params, props) => ({
      id,
      firstname: data.firstname,
      lastname: data.lastname,
      mobilePhoneNumber: data.mobilePhoneNumber,
      password: data.password,
      idRole: params.role || props?.custom?.roleId,
      email: data.email,
    }),
  },
  read: {
    form: [
      {
        type: 'row',
        items: [
          {
            md: 6,
            id: 'lastname',
            label: 'clients.lastname',
          },
          {
            md: 6,
            id: 'firstname',
            label: 'clients.firstname',
          },
        ],
      },
      {
        type: 'row',
        items: [
          {
            id: 'mobilePhoneNumber',
            label: 'clients.label.phoneNumber',
          },
          {
            id: 'email',
            label: 'clients.label.email',
          },
        ],
      },
    ],
    getLink: (item) => item.id,
    variables: (id) => ({
      id,
    }),
    query: UsersStaffs.getStaffById,
    transformQuery: (i) => i.staff,
  },
  delete: {
    text: 'clients.delete.external',
    getTitle: (item) => `${item.firstname} ${item.lastname}`,
    query: UsersStaffs.deleteStaff,
    errorMessage: 'clients.delete.errorStaff',
  },
}
