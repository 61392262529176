import { gql } from '@apollo/client'

const getResidenceServiceResources = gql`
  query getResidenceServiceResources($id: UUID!) {
    residenceServiceResources(
      filter: {idResidenceService: {equalTo: $id}}
    ) {
      nodes {
        resourceByIdResource {
          id
          name
          description
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const createResidenceServiceResource = gql`
mutation createResidenceServiceResource($idResidenceService: UUID!, $idResource: UUID!) {
  createResidenceServiceResource(
    input: {residenceServiceResource: {idResidenceService: $idResidenceService, idResource: $idResource}}
  ) {
    clientMutationId
    residenceServiceResource {
      idResidenceService
      idResource
    }
  }
}
`

const deleteResidenceServiceResource = gql`
mutation deleteResidenceServiceResource($idResidenceService: UUID!, $idResource: UUID!) {
  deleteResidenceServiceResource(
    input: {idResidenceService: $idResidenceService, idResource: $idResource}
  ) {
    clientMutationId
  }
}
`

export default {
  getResidenceServiceResources,
  createResidenceServiceResource,
  deleteResidenceServiceResource,
}
