// import React from 'react'
import React from 'react'
import { RessourceLinks } from '../components/automated/custom'
import { Residences, Ressources } from '../gql'

export const AddRessourceToResidence = {
  graphql: true,
  title: 'ressources.title',
  query: Ressources.getRessource,
  transformQuery: (items) => items?.resource?.name,
  url: '/ressourcesList',
  create: {
    getLink: (item) => item.id,
    title: 'ressources.add.formtitle',
    form: [
      {
        id: 'name',
        type: 'text',
        placeholder: 'ressources.update.nameholder',
        label: 'ressources.update.nameholder',
        required: true,
      },
      {
        id: 'description',
        type: 'text',
        placeholder: 'ressources.update.descriptionholder',
        label: 'ressources.update.description',
      },
      {
        type: 'submit',
        label: 'categories.label.submit',
      },
    ],
    query: Residences.getResidence,
    transformQuery: (item) => (
      {
        ...item.residence,
    }),
    submitQuery: (data, index) => {
      if (index === 0) {
        return Ressources.createRessource
      }
    },
    submit: (data, params) => ({
      idResidence: params.resId,
      name: data.name,
      description: data.description,
    }),
    getIdAfterSubmit: (data) => data.createResource.resource.id,
  },
  update: {
    getLink: (item) => item.id,
    title: 'ressources.update.formtitle',
    form: [
      {
        id: 'links',
        type: 'custom',
        component: (props) => (<RessourceLinks {...props} />),
      },
      {
        id: 'name',
        type: 'text',
        placeholder: 'ressources.update.nameholder',
        label: 'ressources.update.nameholder',
        required: true,
      },
      {
        id: 'description',
        type: 'text',
        placeholder: 'ressources.update.descriptionholder',
        label: 'ressources.update.description',
      },
      {
        type: 'submit',
        label: 'categories.label.submit',
      },
    ],
    query: Ressources.getRessource,
    transformQuery: (item) => ({
        ...item.resource,
      }
    ),
    submitQuery: (data, index) => {
      if (index === 0) {
       return Ressources.updateRessource
      }
    },
    submit: (id, data) => ({
      id,
      name: data.name,
      description: data.description,
    }),
  },
}
