import { gql } from '@apollo/client'

const getNews = gql`
  query getNews {
    newses {
      nodes {
        body
        id
        title
        idResidence
        idResident
        residenceByIdResidence {
          name
        }
        residentByIdResident {
          firstname
          lastname
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`
const createNews = gql`
  mutation createNews(
    $title: String!
    $body: String!
    $startDate: Datetime!
    $endDate: Datetime!
    $idResidence: UUID
    $idResident: UUID
  ) {
    createNews(
      input: {
        news: {
          title: $title
          body: $body
          publicationRange: {
            end: { value: $endDate, inclusive: false }
            start: { value: $startDate, inclusive: false }
          }
          idResidence: $idResidence
          idResident: $idResident
        }
      }
    ) {
      news {
        id
      }
    }
  }
`

const updateNews = gql`
mutation updateNews(
  $title: String
  $id: UUID!
  $body: String
  $startDate: Datetime!
  $endDate: Datetime!
  $idResidence: UUID
  $idResident: UUID
  ) {
  updateNews(
    input: {
      patch: {
        body: $body, title: $title, idResidence: $idResidence, idResident: $idResident, publicationRange: {end: {value: $endDate, inclusive: false }, start: {value: $startDate, inclusive: false }}}, id: $id}
    ) {
    clientMutationId
  }
}
`
const deleteNews = gql`
  mutation deleteNews($id: UUID!) {
    deleteNews(input: { id: $id }) {
      clientMutationId
    }
  }
`
const fetchNewsMessage = gql`
  query getMessage($id: UUID!) {
    news(id: $id) {
      body
      createdAt
      idResidence
      idResident
      isRead
      publicationRange {
        end {
          value
        }
        start {
          value
        }
      }
      residenceByIdResidence {
        name
      }
      residentByIdResident {
        firstname
        lastname
      }
      title
    }
  }
`

export default {
  getNews,
  createNews,
  updateNews,
  deleteNews,
  fetchNewsMessage,
}
