import { gql } from '@apollo/client'

const getServices = gql`
  query getServices($first: Int, $offset: Int, $idProvider: UUID) {
    services(
      first: $first
      offset: $offset
      filter: { idServiceProvider: { equalTo: $idProvider } }
    ) {
      nodes {
        id
        label
        name
        ref
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const fetchServicesForManager = gql`
  query fetchServicesForManager($first: Int, $offset: Int, $idRole: UUID!) {
    residences(
      first: $first
      offset: $offset
      condition: { idManager: $idRole }
    ) {
      nodes {
        residenceServicesByIdResidence {
          nodes {
            serviceByIdService {
              id
              label
              name
              ref
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const fetchServicesForCoordinator = gql`
  query fetchServicesForCoordinator($first: Int, $offset: Int, $idRole: UUID!) {
    residenceCoordinations(
      first: $first
      offset: $offset
      condition: { idCoordinator: $idRole }
    ) {
      nodes {
        residenceByIdResidence {
          residenceServicesByIdResidence {
            nodes {
              serviceByIdService {
                id
                label
                name
                ref
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const createService = gql`
  mutation createService(
    $name: String!
    $idServiceCategory: UUID!
    $idServiceProvider: UUID
    $description: String!
    $ref: String!
    $url: String
    $label: String
  ) {
    createService(
      input: {
        service: {
          name: $name
          idServiceCategory: $idServiceCategory
          ref: $ref
          idServiceProvider: $idServiceProvider
          description: $description
          url: $url
          label: $label
        }
      }
    ) {
      service {
        id
      }
    }
  }
`

const getServiceWithCategory = gql`
  query getServiceWithCategory($id: UUID!) {
    service(id: $id) {
      id
      label
      name
      ref
      url
      description
      idServiceCategory
      idServiceProvider
      serviceCategoryByIdServiceCategory {
        label
      }
    }
    serviceCategories {
      nodes {
        id
        label
      }
    }
  }
`

const updateService = gql`
  mutation updateService(
    $name: String!
    $idServiceCategory: UUID!
    $idServiceProvider: UUID
    $description: String!
    $ref: String!
    $url: String
    $label: String
    $id: UUID!
  ) {
    updateService(
      input: {
        patch: {
          name: $name
          idServiceCategory: $idServiceCategory
          ref: $ref
          idServiceProvider: $idServiceProvider
          description: $description
          url: $url
          label: $label
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const deleteService = gql`
  mutation deleteService($id: UUID!) {
    deleteService(input: { id: $id }) {
      clientMutationId
    }
  }
`
const searchServicesByName = gql`
  query searchServicesByName($name: String!) {
    services(filter: { name: { likeInsensitive: $name } }) {
      nodes {
        label
        name
        residenceServicesByIdService {
          nodes {
            idService
          }
        }
      }
    }
  }
`

export default {
  getServices,
  createService,
  fetchServicesForManager,
  fetchServicesForCoordinator,
  getServiceWithCategory,
  updateService,
  deleteService,
  searchServicesByName,
}
