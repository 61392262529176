import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import {
  Button, Card, CardBody,
  CardFooter, CardHeader, Col, Form, FormCheckbox, FormGroup,
  FormInput, FormSelect, Row,
} from 'shards-react'

import TextInputAutoCompleted from '../custom/TextInputAutoCompleted'

import config from '../../../config'
import { ResidenceServiceBookings } from '../../../gql'
import UserProvider from '../../../gql/UserProvider'
import { userCanDoAction } from '../../../lib'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import AutomatedModalDelete from './AutomatedModalDelete'
import { customStyles } from './style'

Modal.setAppElement('#root')

const AutomatedList = (props) => {
  const { model, addTo, closeButton, close, dataToShow,
     noHeader, noFooter, reload, overideNextButton,
      overidePrevButton, overrideNext, overridePrev, url } = props

  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { search, state } = location
  const query = new URLSearchParams(search)
  const page = parseInt(query.get('page'), 10) || 0
  const { limit, table } = model.list
  const params = useParams()
  const { id } = params
  const { pathname } = useLocation()

  let defaultSortType = ''
  table.forEach((th) => {
    if (th.isDefault) {
      defaultSortType = typeof th.sortable === 'string' ? th.sortable : th.key
    }
  })

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState(dataToShow || [])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState({})
  const [sortType, setSortType] = useState(defaultSortType)
  const [sortDirection, setSortDirection] = useState('asc')
  const [batchIds, setBatchIds] = useState([])
  const [batchAction, setBatchAction] = useState()
  const [showBatchModal, setShowBatchModal] = useState(false)
  const [pageHasPrev, setPageHasPrev] = useState(false)
  const [pageHasNext, setPageHasNext] = useState(false)
  const [offset, setOffset] = useState(0)
  const [noBatchIdSelected, setNoBatchIdSelected] = useState(false)
  const [allRowSelected, setAllRowSelected] = useState(false)
  const [batchDelete, setBatchDelete] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [isCreated, setIsCreated] = useState(state ? state.create : false)
  const [isUpdated, setIsUpdated] = useState(state ? state.update : false)
  const [hasNoAccess, setHasNoAccess] = useState(state ? state.noAccess : false)
  const [isDelete, setIsDelete] = useState(false)
  const [isDeletedError, setIsDeletedError] = useState(false)
  const [expended, setExpended] = useState()
  const [inputAutoCompleted, setInputAutoCompleted] = useState()
  const [listAutoCompleted, setListAutoCompleted] = useState()
  const [roleIdStaff, setRoleIdStaff] = useState(null)
  const [residences, setResidences] = useState()

  const [mutationError, setMutationError] = useState('')

  const [filterDate, setFilterDate] = useState({
    begin: '',
    end: '',
  })

  const [orderBy, setOrderBy] = useState('CREATED_AT_DESC')

  const [status, setStatus] = useState('NEW')

  const [residenceSelected, setResidenceSelected] = useState('')

  const [fetchResidences] = useLazyQuery(ResidenceServiceBookings.getResidences, {
    onCompleted: (data) => {
      setResidences(data.residences.nodes)
    },
  })

  useEffect(() => {
    if (model.url === '/ordered') {
      fetchResidences()
    }
  }, [])

  const { data: dataRole } = useQuery(UserProvider.fetchProviderRoleId, {
    onCompleted: () => {
      const array = dataRole.viewRoles.nodes.filter((el) => {
        if (model.url === '/externes') {
          return el.rolname === 'logifim_external'
        }
        return el.rolname === 'logifim_service_provider'
      })
      setRoleIdStaff(array[0].id)
    },
  })

  if (state) {
    delete state.create
    delete state.update
  }

  useEffect(() => {
    setItems(dataToShow)
  }, dataToShow)

  let fetchGrahQlItems = () => {}
  let queryToUse
  if (residenceSelected) {
    queryToUse = ResidenceServiceBookings.getOrderByResidence
  } else if (typeof model.list.query === 'function') {
    queryToUse = model.list.query(props)
  } else {
    queryToUse = model.list.query
  }

  try {
    const fetchItem = useLazyQuery(queryToUse, {
      fetchPolicy: config.fetchPolicy,
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      variables: model.list.variables(
        limit,
        offset,
        filterDate.begin && filterDate.begin,
        filterDate.end && filterDate.end,
        orderBy,
        status && status,
        residenceSelected && residenceSelected,
        i18n.language,
        sortType,
        sortDirection,
        props,
        params,
        roleIdStaff && roleIdStaff,
      ),
      onError: (err) => {
        const request = model.list.query
        const event = {
          request:
            request?.definitions &&
            request?.definitions[0] &&
            request?.definitions[0]?.name?.value,
          variables: model.list.variables(
            limit,
            offset,
            i18n.language,
            sortType,
            sortDirection,
            props,
            params,
          ),
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (data) => {
        const transformData = residenceSelected
          ? data?.residence
          : data
        setItems(model.list.transformQuery(transformData, props))
        setPageHasPrev(model.list.hasPreviousPage(data, props))
        setPageHasNext(model.list.hasNextPage(data, props))
        setTotalPage(model.list.totalPage(data, props))
        setLoading(false)
      },
    })
    fetchGrahQlItems = fetchItem[0]
} catch (e) {
  console.log(e)
}

  const loadData = async () => {
    if (dataToShow?.length > 0 && config.CONNECTOR === 'graphql' && model.graphql) {
      setLoading(false)
      return
    }
    setLoading(true)
    setBatchIds([])
    if (config.CONNECTOR === 'graphql' && model.graphql) {
      fetchGrahQlItems()
    } else {
      try {
        const itemsFromApi = await model.list.getItems(
          limit,
          offset,
          i18n.language,
          sortType,
          sortDirection,
          { ...props, id },
        )
        if (model.list.orderable) {
          const itms = [
            ...items,
            ...itemsFromApi,
          ]
          setItems(itms)
        } else {
          setItems(itemsFromApi)
        }
        setLoading(false)
        setPageHasPrev(page > 0)
        setPageHasNext(itemsFromApi?.length === limit)
        if (model.list.orderable && itemsFromApi?.length === limit) {
          setOffset(offset + limit)
        }
      } catch (e) {
        setLoading(false)
        if (!model.list.orderable) {
          setItems([])
        }
        setPageHasPrev(page > 0)
        setPageHasNext(false)
      }
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  let mutationLoading

  let deleteGrahQlItems = () => {}
  try {
    const variables = model.delete?.variables ?
      model.delete?.variables(currentItem, props, params) : { id: currentItem.id }
    const deleteItem = useMutation(url === '/provider' ? UserProvider.deleteProviderUser : model.delete.query, {
      variables,
      onError: (err) => {
        setMutationError(err.message)
        const request = model.delete.query
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables,
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: () => {
        setCurrentItem({})
        closeModal()
        loadData()
        setShowBatchModal(false)
        reload()
      },
    })
    deleteGrahQlItems = deleteItem[0]
  } catch (err) {
    console.log(err)
  }

  let mutationBatchLoading
  let mutationBatchError
  let deleteBatchGrahQlItems = () => {}
  try {
    const deleteItems = useMutation(model.delete.query, {
      variables: {
        id: batchIds[0],
      },
      onError: (err) => {
        const request = model.delete.query
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: { id: batchIds[0] },
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: () => {
        const ids = [
          ...batchIds,
        ]

        ids.shift()
        setBatchIds(ids)

        if (ids?.length === 0) {
          setShowBatchModal(false)
          setBatchDelete(false)
          loadData()
          closeModal()
        }
      },
    })
    deleteBatchGrahQlItems = deleteItems[0]
  } catch (err) {
    console.log(err)
  }

  let fetchGrahQlItemForAutoCompletion = () => {}
  try {
    const fetchItem = useLazyQuery(model.list.queryInputAutocompletion, {
      fetchPolicy: config.fetchPolicy,
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      variables: {
        id: params.resId.substr(0, 36),
      },
      onError: (err) => {
        const request = model.test
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: {
            id: params.resId.substr(0, 36),
          },
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (response) => {
        setListAutoCompleted(model.list.transformQueryInputAutocompletion(response))
      },
    })
    fetchGrahQlItemForAutoCompletion = fetchItem[0]
  } catch (e) {
    console.log(e)
  }

  useEffect(() => {
    if (batchDelete && batchIds?.length > 0) {
      deleteBatchGrahQlItems()
    }
  }, [batchIds, batchDelete])

  useEffect(() => {
    loadData()

    if (model.list.queryInputAutocompletion && model.list.transformQueryInputAutocompletion) {
      fetchGrahQlItemForAutoCompletion()
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [offset, sortType, sortDirection])

  useEffect(() => {
    let offst = page * limit
    if (page - 1 >= 0) {
      offst = page * limit
    }
    setOffset(offst)
    if (page !== 0) {
      setIsCreated(false)
      setIsUpdated(false)
      setHasNoAccess(false)
    }
  }, [page])

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none', // some basic styles to make the items look a bit nicer
    background: isDragging ? 'lightgreen' : 'white', // change background colour if dragging
    ...draggableStyle, // styles we need to apply on draggables
  })

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (!model.list.orderable) {
      return
    }

    const itms = reorder(
      items,
      result.source.index,
      result.destination.index,
    )

    setItems(itms)
    model.list.orderable(itms)
  }

  const addContentFromSearch = (newContent, direction) => {
    const itms = [
      ...items,
    ]

    if (direction === 'top') {
      itms.unshift(newContent)
    } else {
      itms.push(newContent)
    }

    setItems(itms)
    model.list.search.addContent(itms)
  }

  let batchAuthorizedRole = []
  if (model.list.batchActions && model.list.batchActions.items?.length > 0) {
    model.list.batchActions.items.forEach((ba) => {
      if (ba.authorizedRole) {
        batchAuthorizedRole = batchAuthorizedRole.concat(ba.authorizedRole)
      }
    })
  }

  return (
    <>
      <Row>
        <Col>
          <Card small className="mb-4">
            {!addTo && !noHeader && (
              <CardHeader className="border-bottom">
                {model.parentUrl && (
                  <p>
                    <a href={model.parentUrl}>
                      &larr;
                      &nbsp;
                      {t('ui.back')}
                    </a>
                  </p>
                )}
                <Row>
                  {false && model.create && userCanDoAction(model?.create?.authorizedRole) && (
                    <Col className="text-left">
                      <Button
                        style={{ ...customStyles.buttonSubmit }}
                        className="mb-2 mr-1"
                        title={t('ui.tooltip.create')}
                        onClick={() => {
                          history.push(`${pathname}/create`)
                        }}
                      >
                        <i className="material-icons mr-1">add</i>
                        {t('ui.add')}
                      </Button>
                    </Col>
                  )}
                  <Col>
                    {model.list.search && model.list.search.component({
                      model,
                      addContentFromSearch,
                    })}
                  </Col>
                </Row>

                {isCreated && (
                  <p className="btn-success btn-sm">{t('ui.create')}</p>
                )}

                {isUpdated && (
                  <p className="btn-success btn-sm">{t('ui.update')}</p>
                )}

                {isDelete && (
                  <p className="btn-success btn-sm">{t('ui.delete')}</p>
                )}

                {isDeletedError && (
                  <p className="btn-danger btn-sm">{t('ui.deleteError')}</p>
                )}

                {hasNoAccess && (
                  <p className="btn-danger btn-sm">{t('ui.noAccess')}</p>
                )}

                {model.url === '/ordered' && (
                  <Form inline={true}>
                    <FormGroup>
                      <label style={{ marginRight: '10px' }} htmlFor="#dateBegin">
                        {t('between')}
                      </label>
                      <FormInput
                        onChange={(e) =>
                          setFilterDate((prev) => ({
                            ...prev,
                            begin: dayjs(e.target.value).toISOString(),
                          }))}
                        id="dateBegin"
                        type="date"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        htmlFor="#dateEnd"
                      >
                        {t('and')}
                      </label>
                      <FormInput
                        onChange={(e) =>
                          setFilterDate((prev) => ({
                            ...prev,
                            end: dayjs(e.target.value).toISOString(),
                          }))}
                        id="dateEnd"
                        type="date"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        htmlFor="#dateEnd"
                      >
                        {t('sort')}
                      </label>
                      <FormSelect onChange={(e) => setOrderBy(e.target.value)}>
                        <option value="CREATED_AT_DESC">{t('sortDesc')}</option>
                        <option value="CREATED_AT_ASC">{t('sortAsc')}</option>
                      </FormSelect>
                    </FormGroup>
                    <FormGroup>
                      <label
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        htmlFor="#dateEnd"
                      >
                        {t('status')}
                      </label>
                      <FormSelect onChange={(e) => setStatus(e.target.value)}>
                        <option value="NEW">{t('new')}</option>
                        <option value="PENDING">{t('pending')}</option>
                        <option value="VALIDATED">{t('validated')}</option>
                        <option value="IN_PROGRESS">{t('inProgeress')}</option>
                        <option value="REFUSED">{t('refused')}</option>
                        <option value="ABONDONNED">{t('abondonned')}</option>
                        <option value="CLOSED">{t('closed')}</option>
                      </FormSelect>
                    </FormGroup>
                    <FormGroup>
                      <label
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        htmlFor="#dateEnd"
                      >
                        {t('residence')}
                      </label>
                      <FormSelect onChange={(e) => setResidenceSelected(e.target.value)}>
                        <option value="">{t('selectAll')}</option>
                        {residences && residences?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </FormSelect>
                    </FormGroup>
                  </Form>
                )}

                <Col>
                  {false && model.create && (
                    <Button
                      theme="primary"
                      className="mb-2 mr-1"
                      onClick={() => {
                          history.push(`${pathname}/create`)
                        }}
                    >
                      {t('ui.add')}
                    </Button>
                  )}
                  {model.list.batchActions &&
                    model.list.batchActions.items?.length === 1 &&
                    userCanDoAction(batchAuthorizedRole) && (
                    <>
                      <label htmlFor="feBatchActions">{t(model.list.batchActions.title)}</label>
                      <Row>
                        <Col>
                          <Button
                            theme="primary"
                            className="mb-2 mr-1"
                            title={t('ui.tooltip.batch')}
                            onClick={() => {
                              const action = model.list.batchActions.items[0]
                              if (action && batchIds?.length > 0) {
                                if (action.component) {
                                  setBatchAction(0)
                                  setShowBatchModal(true)
                                  setIsOpen(true)
                                } else {
                                  action.submit(batchIds)
                                }
                              } else {
                                setNoBatchIdSelected(true)
                              }
                            }}
                          >
                            {t(model.list.batchActions.items[0].label)}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  {noBatchIdSelected && (<p>{t('form.noBatchIdSelected')}</p>)}
                  {model.list.queryInputAutocompletion && (
                    <>
                      <label htmlFor="addWithAutoCompletion">{t('InputAutoCompleted.add')}</label>
                      <TextInputAutoCompleted
                        autoCompleteList={listAutoCompleted}
                        field={inputAutoCompleted}
                        onChangeCustom={(e) => setInputAutoCompleted(e.target.value)}
                      />
                    </>
                  )}
                </Col>
                {model.list.batchActions &&
                  model.list.batchActions.items?.length > 1 &&
                  userCanDoAction(batchAuthorizedRole) && (
                  <>
                    <label htmlFor="feBatchActions">{t(model.list.batchActions.title)}</label>
                    <Row>
                      <Col>
                        <FormSelect
                          id="feBatchActions"
                          onChange={((event) => {
                            setBatchAction(event.target.value)
                          })}
                        >
                          <option>{t(model.list.batchActions.defaultValue)}</option>
                          {model.list.batchActions.items?.map((option, index) => {
                            if (option.authorizedRole && !userCanDoAction(batchAuthorizedRole)) {
                              return (<></>)
                            }
                            return (
                              <option value={index} key={`option${index}`}>{option.label}</option>
                            )
})}
                        </FormSelect>
                      </Col>
                      <Col>
                        <Button
                          type="submit"
                          onClick={() => {
                            const action = model.list.batchActions.items[batchAction]
                            if (action && batchIds?.length > 0) {
                              if (action.component) {
                                setShowBatchModal(true)
                                setIsOpen(true)
                              } else {
                                action.submit(batchIds)
                              }
                            }
                          }}
                        >
                          {t(model.list.batchActions.submitButton)}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </CardHeader>
            )}
            <CardBody className="p-0 pb-3">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ background: 'transparent', width: '100%' }}
                    >
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        background: '#f9f9f9',
                        padding: '10px 0',
                        borderBottom: '2px solid #e8e8ea',
                      }}
                      >
                        {!addTo &&
                          model.list.batchActions &&
                          userCanDoAction(batchAuthorizedRole) && (
                          <div style={{ padding: '0 0 0 10px' }}>
                            <FormCheckbox
                              checked={allRowSelected}
                              onChange={(() => {
                                const ids = []
                                if (!allRowSelected) {
                                  items.forEach((item) => {
                                    ids.push(item.id)
                                  })
                                }
                                setBatchIds(ids)
                                setAllRowSelected(!allRowSelected)
                                setNoBatchIdSelected(false)
                              })}
                            />
                          </div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                          {model.list.table?.map((item, index) => (
                            <div className="border-0" key={`th${index}`} style={{ width: item.width, padding: '0 10px' }}>
                              {item.sortable && !model.list.orderable && !addTo ? (
                                <button
                                  type="button"
                                  style={{
                                  ...customStyles.button,
                                  ...((sortType === item.key || sortType === item.sortable) ? { color: '#007bff' } : {}),
                                }}
                                  onClick={() => {
                                  let direction = sortDirection
                                  if (sortType === item.key || sortType === item.sortable) {
                                    direction = sortDirection === 'asc' ? 'desc' : 'asc'
                                    setSortDirection(direction)
                                  }
                                  setSortType(typeof item.sortable === 'string' ? item.sortable : item.key)
                                  history.push(pathname)
                                }}
                                >
                                  {t(item.head)}
                                  {(sortType === item.key || sortType === item.sortable) && (
                                  <>
                                    {' '}
                                    { sortDirection === 'asc' ? (<>&uarr;</>) : (<>&darr;</>)}
                                  </>
                                )}
                                </button>
                            ) : (
                              <p className="m-0 font-weight-bold p-0" style={{ textAlign: item.center ? 'center' : '' }}>
                                {t(item.head)}
                              </p>
                            )}
                            </div>
                        ))}
                        </div>
                        {(model.delete || model.update || model.read) &&
                        (
                          userCanDoAction(model?.delete?.authorizedRole) ||
                          userCanDoAction(model?.update?.authorizedRole) ||
                          userCanDoAction(model?.read?.authorizedRole)
                        ) && (
                          <>
                            {model.list.icons ? (
                              <div style={{ width: '120px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <p className="font-weight-bold p-0 m-0" style={{ textAlign: 'center' }}>{t(model.list.icons)}</p>
                              </div>
                            ) : (
                              <div style={{ width: '120px' }} />
                            )}
                          </>
                        )}
                        {(model.list.icons && (
                          <div style={{ width: '120px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <p className="font-weight-bold p-0 m-0" style={{ textAlign: 'center' }}>{t(model.list.icons)}</p>
                          </div>
                        ))}
                        { (closeButton || addTo) && (
                          <div style={{ width: '120px', textAlign: 'right' }}>
                            <button
                              type="button"
                              style={{ background: 'none', border: '0 none' }}
                              onClick={() => {
                                close()
                              }}
                            >
                              x
                              {' '}
                              {t('ui.closeAddTo')}
                            </button>
                          </div>
                        )}
                      </div>
                      {!loading && dataToShow !== 'no data' && items?.map((row, rowIndex) => {
                        if (expended !== row.expendedParent && row.expendedParent) {
                          return (<span />)
                        }
                        return (
                          <Draggable
                            key={`tr${row.id}`}
                            draggableId={`row${row.id}`}
                            index={rowIndex}
                            isDragDisabled={!model.list.orderable}
                          >
                            {(provided1, snapshot1) => (
                              <div
                                ref={provided1.innerRef}
                                {...provided1.draggableProps}
                                {...provided1.dragHandleProps}
                                style={getItemStyle(
                                  snapshot1.isDragging,
                                    provided1.draggableProps.style,
                                  )}
                              >
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  padding: '10px 0',
                                  borderBottom: '1px solid #ccc',
                                }}
                                >
                                  {!addTo &&
                                    model.list.batchActions &&
                                    userCanDoAction(batchAuthorizedRole) && (
                                    <div style={{ padding: '0 0 0 10px' }}>
                                      <FormCheckbox
                                        checked={batchIds.indexOf(row.id) !== -1}
                                        onChange={(() => {
                                          const ids = [
                                            ...batchIds,
                                          ]
                                          const index = ids.indexOf(row.id)
                                          if (index !== -1) {
                                            ids.splice(index, 1)
                                          } else {
                                            ids.push(row.id)
                                          }
                                          setBatchIds(ids)
                                        })}
                                      />
                                    </div>
                                  )}
                                  <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                                    {model.list.table?.map((col, colIndex) => {
                                      if (col.type === 'more') {
                                        return (
                                          <div style={{ width: row.width, padding: '0 10px' }}>
                                            <a href={col.link(row.id)}>{t(col.text)}</a>
                                          </div>
                                        )
                                      }

                                      if (col.expendable) {
                                        if (row?.count > 0) {
                                          return (
                                            <button
                                              type="button"
                                              style={{
                                                background: 'transparent',
                                                border: '0 none',
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                              onClick={() => {
                                                setExpended(expended !== row.id ? row.id : null)
                                              }}
                                            >
                                              {expended !== row.id && row.expendedParent === null && (<span className="material-icons">expand_more</span>)}
                                              {expended === row.id && row.expendedParent === null && (<span className="material-icons">expand_less</span>)}
                                              <span
                                                style={{
                                                  fontWeight:
                                                    row.expendedParent === null ? 700 : 200,
                                                  marginLeft: row.expendedParent === null ? '0' : '25px',
                                                }}
                                              >
                                                {row[col.key]}
                                              </span>
                                            </button>
                                          )
                                        }

                                        return (
                                          <span
                                            style={{
                                                fontWeight:
                                                  row.expendedParent === null ? 700 : 200,
                                                marginLeft: '10px',
                                              }}
                                          >
                                            {row[col.key]}
                                          </span>
                                        )
                                      }

                                      let value = row[col.key]
                                      if (col.key.indexOf('.') !== -1) {
                                        const splitedKey = col.key.split('.')
                                        value = row[splitedKey[0]][splitedKey[1]]
                                      }
                                      return (
                                        <div key={`td${colIndex}`} style={{ width: col.width, padding: '0 10px' }}>
                                          <p className="p-0 m-0" style={{ textAlign: col.center ? 'center' : '', fontSize: '1rem' }}>{value}</p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                  {(model.delete || model.update || model.read) && !addTo &&
                                  (
                                    userCanDoAction(model?.delete?.authorizedRole) ||
                                    userCanDoAction(model?.update?.authorizedRole) ||
                                    userCanDoAction(model?.read?.authorizedRole)
                                  ) && (
                                    <div style={{ width: '120px', textAlign: 'center' }}>
                                      {model.read &&
                                        userCanDoAction(model?.read?.authorizedRole) && (
                                        <button
                                          type="button"
                                          title={t('ui.tooltip.read')}
                                          style={{ background: 'none', border: '0 none' }}
                                          onClick={() => {
                                            history.push(`${pathname}/read/${model.read.getLink(row)}`)
                                          }}
                                        >
                                          <i className="material-icons" style={{ width: '20px', textAlign: 'center' }}>visibility</i>
                                        </button>
                                      )}
                                      {model.update &&
                                        userCanDoAction(model?.update?.authorizedRole) && (
                                        <button
                                          type="button"
                                          title={t('ui.tooltip.update')}
                                          style={{ background: 'none', border: '0 none' }}
                                          onClick={() => {
                                            if (model.update.url) {
                                              history.push(
                                                model.update.url(
                                                  id,
                                                  model.update.getLink(row),
                                                  params,
                                                  props,
                                                ),
                                              )
                                              return
                                            }
                                            history.push(`${pathname}/edit/${model.update.getLink(row)}`)
                                          }}
                                        >
                                          <i className="material-icons" style={{ width: '20px', textAlign: 'center' }}>edit</i>
                                        </button>
                                      )}

                                      {model.delete &&
                                        (row?.isDeletable || row.isDeletable === undefined) &&
                                        userCanDoAction(model?.delete?.authorizedRole) && (
                                        <button
                                          type="button"
                                          title={t('ui.tooltip.delete')}
                                          style={{ background: 'none', border: '0 none' }}
                                          onClick={() => {
                                              setIsOpen(true)
                                              setCurrentItem(row)
                                            }}
                                        >
                                          <i className="material-icons" style={{ width: '20px', textAlign: 'center' }}>delete</i>
                                        </button>
                                      )}
                                    </div>
                                  )}
                                  {addTo && (
                                    <div style={{ marginRight: 10 }}>
                                      <button
                                        type="button"
                                        style={{ background: 'none', border: '0 none' }}
                                        onClick={() => {
                                          addTo(row, 'top')
                                        }}
                                      >
                                        &uarr;
                                        {' '}
                                        {t('ui.addToTop')}
                                      </button>
                                      <br />
                                      <button
                                        type="button"
                                        style={{ background: 'none', border: '0 none' }}
                                        onClick={() => {
                                          addTo(row, 'bottom')
                                        }}
                                      >
                                        &darr;
                                        {' '}
                                        {t('ui.addToBottom')}
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {provided.placeholder}
                              </div>
                              )}
                          </Draggable>
                        )
                      })}
                      {items?.length === 0 && !loading && (
                        <div style={{ padding: 10 }}>{t('ui.noData')}</div>
                      )}
                      {loading && (
                        <div style={{ padding: 10 }}>{t('ui.loadingData')}</div>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CardBody>
            {!model.list.orderable && !addTo && !noFooter && (
              <CardFooter className="border-top">
                <Row>
                  <Col className="">
                    {t('ui.page')}
                    {' '}
                    { page + 1 || 1 }
                    { totalPage > 0 && (
                      <span>
                        {' '}
                        /
                        {' '}
                        { totalPage }
                      </span>
                    )}
                  </Col>

                  <Col className="text-right">
                    {(pageHasPrev || overridePrev) && (
                      <Button
                        theme="primary"
                        className="mb-2 mr-1"
                        onClick={() => {
                          if (overridePrev) {
                            overidePrevButton()
                          } else {
                            history.push(`${pathname}?page=${page - 1}`)
                          }
                        }}
                      >
                        &larr;
                        {' '}
                        {t('ui.previous')}
                      </Button>
                    )}

                    {(pageHasNext || overrideNext) && (
                      <Button
                        theme="primary"
                        className="mb-2 mr-1"
                        onClick={() => {
                          if (overrideNext) {
                            overideNextButton()
                          } else {
                            history.push(`${pathname}?page=${page + 1}`)
                          }
                        }}
                      >
                        {t('ui.next')}
                        {'  '}
                        &rarr;
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
      {(model.delete || model.list.batchActions) && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
        >
          {model.delete && !showBatchModal && (
            <AutomatedModalDelete
              {...{ model, currentItem }}
              closeModal={(withDeletion = false) => {
                if (withDeletion) {
                  if (config.CONNECTOR === 'graphql' && model.graphql) {
                    deleteGrahQlItems()
                  } else if (model.delete) {
                    model.delete.submit({
                      ...currentItem,
                      parentId: id,
                    }).then((resp) => {
                      if (resp) {
                        setIsDelete(true)
                        setIsDeletedError(false)
                      } else {
                        setIsDelete(false)
                        setIsDeletedError(true)
                      }
                      closeModal()
                      loadData()
                    })
                  } else {
                    closeModal()
                    loadData()
                  }
                } else {
                  setCurrentItem({})
                  closeModal()
                }
              }}
              mutationLoading={mutationLoading}
              mutationError={mutationError}
              errorMessage={model.delete?.errorMessage && t(model.delete?.errorMessage)}
            />
          )}
          {model.list.batchActions
            && batchAction !== null
            && showBatchModal
            && model.list.batchActions.items[batchAction]
            && (
            <>
              {model.list.batchActions.items[batchAction].component({
                batchAction: model.list.batchActions.items[batchAction],
                items,
                batchIds,
                model,
                closeModal: (withDeletion = false) => {
                  if (withDeletion) {
                    setBatchDelete(true)
                  } else {
                    setShowBatchModal(false)
                    closeModal()
                  }
                },
                mutationBatchLoading,
                mutationBatchError,
              })}
            </>
          )}
        </Modal>
      )}
    </>
  )
}

AutomatedList.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  addTo: PropTypes.func,
  closeButton: PropTypes.bool,
  close: PropTypes.func,
  dataToShow: PropTypes.arrayOf(PropTypes.any),
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  reload: PropTypes.func,
  overideNextButton: PropTypes.func,
  overidePrevButton: PropTypes.func,
  overrideNext: PropTypes.bool,
  overridePrev: PropTypes.bool,
  url: PropTypes.string,

}

AutomatedList.defaultProps = {
  addTo: null,
  close: null,
  closeButton: false,
  dataToShow: [],
  noHeader: false,
  noFooter: false,
  reload: () => {},
  location: null,
  overideNextButton: null,
  overidePrevButton: null,
  overrideNext: false,
  overridePrev: false,
  url: '',
}

export default AutomatedList
