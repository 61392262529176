import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Col } from 'shards-react'

const PageTitle = ({
  title,
  subtitle,
  className,
  clickable,
  onClick,
  ...attrs
}) => {
  const classes = classNames(
    className,
    'text-center',
    'text-md-left',
    'mb-sm-0',
  )

  const [hovered, setHovered] = useState(false)

  return (
    <Col xs="12" sm="4" className={classes} {...attrs} onClick={onClick}>
      <span className="text-uppercase page-subtitle">{subtitle}</span>
      <h3
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        style={
          hovered && clickable
            ? { textDecoration: 'underLine', cursor: 'pointer' }
            : {}
        }
        className="page-title"
      >
        {title}
      </h3>
    </Col>
  )
}

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,

  className: PropTypes.string,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
}

PageTitle.defaultProps = {
  title: '',
  subtitle: '',
  className: '',
  clickable: false,
  onClick: () => {},
}

export default PageTitle
