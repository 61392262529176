/* eslint-disable react/display-name */
import config from '../config'
import { CatalogArticles } from '../gql'

const { LIMIT } = config

export const OrderArticles = {
  graphql: true,
  title: 'articles.title',
  secondTitle: 'articles.secondTitle',
  query: CatalogArticles.getArticleByOrderId,
  url: (id) => `/register/${id}/articles`,
  list: {
    title: 'articles.list',
    table: [
      {
        head: 'articles.label.quantity',
        key: 'quantity',
        width: '20%',
      },
      {
        head: 'articles.label.label',
        key: 'label',
        width: '50%',
      },
      {
        head: 'articles.label.unit_price',
        key: 'unit_price',
        width: '30%',
      },
    ],
    query: CatalogArticles.getArticleByOrderId,
    variables: (limit, offset, lang, sortType, sortDirection, props, params) => ({
      first: limit,
      offset,
      idOrder: params.id,
    }),
    hasPreviousPage: data => data.catalogArticles.pageInfo.hasPreviousPage,
    hasNextPage: data => data.catalogArticles.pageInfo.hasNextPage,
    totalPage: data => Math.ceil(data.catalogArticles.totalCount / LIMIT),
    limit: LIMIT,
  },
}
