import { gql } from '@apollo/client'

const getServiceCategories = gql`
  query getServiceCategories($first: Int, $offset: Int) {
    serviceCategories(first: $first, offset: $offset, filter: {idParentCategory: {isNull: true}}) {
      nodes {
        id
        label
        serviceCategoriesByIdParentCategory {
          nodes {
            id
            label
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const getAllServiceCategories = gql`
  query getAllServiceCategories {
    serviceCategories {
      nodes {
        id
        label
      }
    }
  }
`

const deleteServiceCategory = () => gql`
  mutation deleteServiceCategory($id: UUID!) {
    deleteServiceCategory(input: {id: $id}) {
      clientMutationId
    }
  }
`

const createServiceCategory = gql`
  mutation createServiceCategory($label: String!, $idParentCategory: UUID) {
    createServiceCategory(input: {serviceCategory: {label: $label, idParentCategory: $idParentCategory}}) {
      serviceCategory {
        id
      }
    }
  }
`

const updateServiceCategory = gql`
  mutation updateServiceCategory($label: String!, $id: UUID!){
    updateServiceCategory(input: {patch: {label: $label}, id: $id}) {
      clientMutationId
    }
  }
`

const getServiceCategory = gql`
  query MyQuery($id: UUID!){
    serviceCategory(id: $id) {
      id
      label
    }
    serviceCategories {
      nodes {
        id
        label
      }
    }
  }
`

export default {
  getServiceCategories,
  getAllServiceCategories,
  deleteServiceCategory,
  createServiceCategory,
  updateServiceCategory,
  getServiceCategory,
}
