/* eslint-disable no-nested-ternary */
import { useLazyQuery, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Button, Col, Row } from 'shards-react'
import config from '../../config'
import { captureMessageWithAttachment } from '../../lib/sentry'
import Breadcrumb from '../common/Breadcrumb'
import { userCanDoAction } from '../../lib'

import PageTitle from '../common/PageTitle'
import { List } from './form'
import UserProvider from '../../gql/UserProvider'

const AutomatedList = ({ model, url, custom }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = useLocation()
  const [parentTitle, setParentTitle] = useState('')
  const [itemLabel, setItemLabel] = useState('')
  const [secondItemLabel, setSecondItemLabel] = useState('')
  const [secondItemLabelText, setSecondItemLabelText] = useState('')
  const [mainId, setMainId] = useState('')
  const [, setSubId] = useState('')
  const [customUrl, setCustomUrl] = useState({
    url: '',
  })
  const [thirdId, setThirdId] = useState('')

  const { data: dataRole } = useQuery(UserProvider.fetchProviderRoleId)
  const roleId = config.ROLES_ID

  const params = useParams()
  const { id, resId, role } = params
  useEffect(() => {
    if (custom.roleId) {
      // const array = dataRole?.viewRoles?.nodes.filter((item) => item.id === custom.roleId)
      switch (custom.roleId) {
        case +roleId.logifim_admin:
          setParentTitle(t(`${model.list.title}.logifim_admin`))
          setCustomUrl({ url: '/admin' })
          break
        case +roleId.logifim_coordinator:
          setParentTitle(t(`${model.list.title}.logifim_coordinator`))
          setCustomUrl({ url: '/coordinator' })
          break
        case +roleId.logifim_service_provider:
          setParentTitle(t(`${model.list.title}.logifim_service_provider`))
          setCustomUrl({ url: '/provider' })
          break
        default:
          setParentTitle('')
      }
      // setParentTitle(array ? t(`${model.list.title}.${array[0].rolname}`) : '')
    } else if (custom.path === '/provider') {
      setParentTitle(t(`${model.list.title}.logifim_service_provider`))
      setCustomUrl({ url: '/provider' })
    } else if (model.list.title === 'clients.title.staff') {
      const array = dataRole?.viewRoles?.nodes.filter(
        (item) => item.rolname === 'logifim_service_provider',
      )
      setParentTitle(array ? t(`${model.list.title}.${array[0].rolname}`) : '')
    }
  }, [dataRole, role])
  let fetchGrahQlItem = () => {}
  try {
    const fetchItem = useLazyQuery(model.query, {
      fetchPolicy: config.fetchPolicy,
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      variables: {
        id: mainId,
      },
      onError: (err) => {
        const request = model.test
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: {
            id: mainId,
          },
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (response) => {
        setItemLabel(model.transformQuery(response))
      },
    })
    fetchGrahQlItem = fetchItem[0]
  } catch (e) {
    console.log(e)
  }

  let fetchSecondGrahQlItem = () => {}
  try {
    const fetchItem = useLazyQuery(model.secondQuery, {
      fetchPolicy: config.fetchPolicy,
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      variables: {
        id: mainId,
      },
      onError: (err) => {
        const request = model.test
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: {
            id: mainId,
          },
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (response) => {
        if (
          response?.residenceService?.serviceByIdService &&
          response?.residenceService?.serviceByIdService?.id
        ) {
          setThirdId(response?.residenceService?.serviceByIdService?.id)
        }
        setSecondItemLabel(model.transformSecondQuery(response))
      },
    })
    fetchSecondGrahQlItem = fetchItem[0]
  } catch (e) {
    console.log(e)
  }

  useEffect(() => {
    if (id > 0 && model.parentModel) {
      model.parentModel(id).then((parent) => {
        setParentTitle(parent)
      })
    } else if (
      config.CONNECTOR === 'graphql' &&
      model.graphql &&
      model.parentQuery
    ) {
      fetchGrahQlItem()
    }
  }, [id])

  useEffect(() => {
    if (resId?.includes('|')) {
      setMainId(resId.substr(0, 36))
      setSubId(resId.substr(37, 45))
    } else {
      setMainId(id)
    }
    if (model.query) {
      fetchGrahQlItem()
    }
    if (model.secondTitle) {
      setSecondItemLabelText(model.secondTitle)
    } else if (model.secondQuery) {
      fetchSecondGrahQlItem()
    }
  }, [])

  return (
    <>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        {model.subtitle && (
          <PageTitle
            sm="9"
            subtitle={t(model.subtitle)}
            className="text-sm-left"
          />
        )}
        <Breadcrumb
          crumbs={[
            {
              label: `${t(model.title)}${parentTitle}`,
              link: model.parentUrl
                ? model.parentUrl
                : customUrl.url
                ? customUrl.url
                : model.url && typeof model.url === 'function'
                ? model.url(resId)
                : model.url,
            },
            {
              label: itemLabel,
              link: model.secondParentUrl
                ? model.secondParentUrl &&
                  typeof model.secondParentUrl === 'function'
                  ? model.secondParentUrl(resId)
                  : model.secondParentUrl
                : model.url && typeof model.url === 'function'
                ? model.url(id)
                : model.url,
            },
            {
              label: secondItemLabel || t(secondItemLabelText),
              link: model.thirdUrl
                ? model.thirdUrl(thirdId)
                : model.parentUrl && model.secondParentUrl && model.url
                ? model.url && typeof model.url === 'function'
                  ? model.url(resId)
                  : model.url
                : null,
            },
            { label: t(model.thirdtitle) },
          ]}
        />
        {model.create && userCanDoAction(model.create.authorizedRole) && (
          <Col className="text-right">
            <Button
              theme="primary"
              title={t('ui.tooltip.create')}
              className="mb-2 mr-1"
              onClick={() => {
                history.push(`${pathname}/create`)
              }}
            >
              {t('ui.add')}
            </Button>
          </Col>
        )}
      </Row>

      <List {...{ model, url, custom }} />
    </>
  )
}

AutomatedList.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  url: PropTypes.string.isRequired,
  custom: PropTypes.objectOf(PropTypes.any),
}

AutomatedList.defaultProps = {
  custom: () => {},
}

export default AutomatedList
