/* eslint-disable react/display-name */
import dayjs from 'dayjs'
import React from 'react'
import {
  ResidenceService,
  ResidenceServiceBookings,
  UsersExternal,
} from '../gql'
import { BookingStatus } from '../data'
import config from '../config'
import OrderArticles from '../components/automated/custom/OrderArticles'

const idRole = config.ROLES_ID.logifim_external
const { LIMIT } = config

export const Registers = {
  graphql: true,
  title: 'registers.title',
  thirdtitle: 'registers.thirdtitle',
  // parentUrl: '/services',
  secondParentUrl: (id) => `/services-residence/edit/${id?.substr(0, 45)}`,
  url: (id) => `/services-residence/edit/${id}`,
  query: ResidenceService?.getResidenceServicesById,
  transformQuery: (items) =>
    items?.residenceService?.residenceByIdResidence?.name,
  secondQuery: ResidenceService?.getServiceNameAndResidenceName,
  transformSecondQuery: (items) =>
    items?.residenceService?.serviceByIdService?.name,
  // url: (id) => `/register/${id}`,
  redirectionAfterUpdate: false,
  list: {
    table: [
      {
        head: 'registers.label.start_date',
        key: 'startDatetime',
        width: '17%',
      },
      {
        head: 'registers.label.end_date',
        key: 'endDatetime',
        width: '17%',
      },
      {
        head: 'registers.label.lastname',
        key: 'lastname',
        width: '17%',
      },
      {
        head: 'registers.label.firstname',
        key: 'firstname',
        width: '17%',
      },
      {
        head: 'registers.label.tel',
        key: 'mobilePhoneNumber',
        width: '16%',
      },
      {
        head: 'registers.label.status',
        key: 'status',
        width: '16%',
      },
    ],
    query: ResidenceServiceBookings.getResidenceServiceBookings,
    variables: (
      limit,
      offset,
      filterDateBegin,
      filterDateEnd,
      orderBy,
      status,
      residenceSelected,
      lang,
      sortType,
      sortDirection,
      props,
      params,
    ) => {
      const ids = params.resId.split('|')
      return {
        first: limit,
        offset,
        id: ids[0],
      }
    },
    transformQuery: (items) =>
      items.residenceServiceBookings.nodes.map((node) => {
        const clients = node.externalByIdExternal || node.residentByIdResident

        return {
          ...node,
          startDatetime: dayjs(node.startDatetime).format('DD/MM/YYYY HH:mm'),
          endDatetime: dayjs(node.endDatetime).format('DD/MM/YYYY HH:mm'),
          lastname: clients.lastname,
          firstname: clients.firstname,
          mobilePhoneNumber: clients.mobilePhoneNumber,
        }
      }),
    hasPreviousPage: (data) =>
      data.residenceServiceBookings.pageInfo.hasPreviousPage,
    hasNextPage: (data) => data.residenceServiceBookings.pageInfo.hasNextPage,
    totalPage: (data) =>
      Math.ceil(data.residenceServiceBookings.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    title: 'registers.label.create',
    form: [
      {
        id: 'lastname',
        type: 'text',
        placeholder: 'registers.placeholder.lastname',
        label: 'registers.label.lastname',
        required: true,
      },
      {
        id: 'firstname',
        type: 'text',
        placeholder: 'registers.placeholder.firstname',
        label: 'registers.label.firstname',
        required: true,
      },
      {
        id: 'mobilePhoneNumber',
        type: 'text',
        placeholder: 'registers.placeholder.tel',
        label: 'registers.label.tel',
        regex: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/i,
        required: true,
      },
      {
        id: 'password',
        type: 'password',
        placeholder: 'registers.placeholder.password',
        label: 'registers.label.password',
        required: true,
      },
      {
        id: 'status',
        type: 'select',
        label: 'registers.label.status',
        defaultValue: 'ui.defaultValue',
        data: BookingStatus,
        required: true,
      },
      {
        id: 'comments',
        type: 'text',
        label: 'registers.label.comments',
        defaultValue: 'ui.defaultValue',
      },
      {
        id: 'startDatetime',
        type: 'datetimepicker',
        placeholder: 'registers.placeholder.start_date',
        label: 'registers.label.start_date',
        required: true,
      },
      {
        id: 'endDatetime',
        type: 'datetimepicker',
        placeholder: 'registers.placeholder.end_date',
        label: 'registers.label.end_date',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'registers.label.submit',
            md: 2,
          },
        ],
      },
    ],
    submitQuery: (data, index) => {
      const rqst = [
        UsersExternal.createExternal,
        ResidenceServiceBookings.createResidenceServiceBooking,
      ]

      if (rqst.length > index) {
        return rqst[index]
      }
    },
    getIdAfterSubmit: (data) => data.createdId,
    transformSubmitQuery: (data, fromDb, indexQuery) => {
      if (indexQuery === 0) {
        return {
          ...data,
          createdId: fromDb.createExternal.external.id,
        }
      }

      return data
    },
    submit: (data, params, index) => {
      if (index === 0) {
        return {
          firstname: data.firstname,
          lastname: data.lastname,
          mobilePhoneNumber: data.mobilePhoneNumber,
          password: data.password,
          idRole,
        }
      }

      const ids = params.resId.split('|')
      return {
        idResidenceService: ids[0],
        startDatetime: data.startDatetime,
        endDatetime: data.endDatetime,
        idExternal: data.createdId,
        status: data.status,
      }
    },
  },
  update: {
    title: 'registers.label.update',
    getLink: (item) => item.id,
    form: [
      {
        id: 'lastname',
        type: 'text',
        placeholder: 'registers.placeholder.lastname',
        label: 'registers.label.lastname',
        readOnly: true,
      },
      {
        id: 'firstname',
        type: 'text',
        placeholder: 'registers.placeholder.firstname',
        label: 'registers.label.firstname',
        readOnly: true,
      },
      {
        id: 'mobilePhoneNumber',
        type: 'text',
        placeholder: 'registers.placeholder.tel',
        label: 'registers.label.tel',
        regex: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/i,
        readOnly: true,
      },
      {
        id: 'startDatetime',
        type: 'text',
        placeholder: 'registers.placeholder.start_date',
        label: 'registers.label.start_date',
        readOnly: true,
      },
      {
        id: 'endDatetime',
        type: 'text',
        placeholder: 'registers.placeholder.end_date',
        label: 'registers.label.end_date',
        readOnly: true,
      },
      {
        id: 'comments',
        type: 'textarea',
        placeholder: 'registers.placeholder.comments',
        label: 'registers.label.comments',
        readOnly: true,
      },
      {
        id: 'status',
        type: 'select',
        label: 'registers.label.status',
        defaultValue: 'ui.defaultValue',
        data: BookingStatus,
        required: true,
      },
      {
        id: 'articles',
        type: 'custom',
        component: (props) => <OrderArticles {...props} />,
      },
      {
        type: 'submit',
        label: 'registers.label.submit',
      },
    ],
    query: ResidenceServiceBookings.getResidenceServiceBooking,
    transformQuery: (item) => {
      const clients =
        item.residenceServiceBooking.externalByIdExternal ||
        item.residenceServiceBooking.residentByIdResident

      return {
        ...item.residenceServiceBooking,
        lastname: clients.lastname,
        firstname: clients.firstname,
        mobilePhoneNumber: clients.mobilePhoneNumber,
      }
    },
    submitQuery: (data, index) => {
      if (index === 0) {
        return ResidenceServiceBookings.updateResidenceServiceBooking
      }
      return null
    },
    submit: (id, data, lang, params) => ({
      id: params.id,
      status: data.status,
    }),
  },
}
