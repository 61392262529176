import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import 'react-datepicker/dist/react-datepicker.css'

import {
  FormInput,
  FormGroup,
} from 'shards-react'

const PreparationDelay = ({ onChangeCustom, field, data }) => {
  const { t } = useTranslation()

  return (
    <div className="prepDelay-container">
      <p style={{ fontWeight: 700, marginBottom: '1rem' }}>
        {t('planning.label.delay')}
        {' '}
        <span>*</span>
      </p>
      <FormGroup>
        <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
          <FormInput
            id="day"
            type="text"
            value={data[field.id]?.day}
            placeholder={t('planning.placeholder.day')}
            valid={data[field.id] && data[field.id].day && data[field.id].day !== ''}
            invalid={data[field.id] && data[field.id]?.day === ''}
            onChange={((event) => {
                      onChangeCustom(field.id, {
                        ...data[field.id],
                        day: event.target.value,
                      })
                    })}
            style={{ marginRight: '10px', width: '90px', textAlign: 'right' }}
          />
          <label
            htmlFor="feTimeStartHour"
            style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '30px' }}
          >
            J
          </label>

          <FormInput
            id="hour"
            type="text"
            value={data[field.id]?.hour}
            valid={data[field.id] && data[field.id].hour && data[field.id].hour !== ''}
            invalid={data[field.id] && data[field.id]?.hour === ''}
            placeholder={t('planning.placeholder.hour')}
            onChange={((event) => {
                      onChangeCustom(field.id, {
                        ...data[field.id],
                        hour: event.target.value,
                      })
                    })}
            style={{ marginRight: '10px', width: '90px', textAlign: 'right' }}
          />
          <label
            htmlFor="feTimeStartHour"
            style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '30px' }}
          >
            H
          </label>

          <FormInput
            id="min"
            type="text"
            value={data[field.id]?.min}
            valid={data[field.id] && data[field.id].min && data[field.id].min !== ''}
            invalid={data[field.id] && data[field.id]?.min === ''}
            placeholder={t('planning.placeholder.min')}
            onChange={((event) => {
                      onChangeCustom(field.id, {
                        ...data[field.id],
                        min: event.target.value,
                      })
                    })}
            style={{ marginRight: '10px', width: '90px', textAlign: 'right' }}
          />
          <label
            htmlFor="feTimeStartHour"
            style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '30px' }}
          >
            M
          </label>

        </div>
      </FormGroup>
    </div>
  )
}

PreparationDelay.propTypes = {
  onChangeCustom: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
}

PreparationDelay.defaultProps = {
  onChangeCustom: () => {},
}

export default PreparationDelay
