/* eslint-disable space-infix-ops */
/* eslint-disable no-bitwise */
/* eslint-disable react/display-name */
import React from 'react'
import dayjs from 'dayjs'
import { Status } from './Status'
import { Planning as PlanningGql, ResidenceService } from '../gql'
import { PlanningTime, PreparationDelay } from '../components/automated/custom'

export const Planning = {
  graphql: true,
  title: 'planning.title',
  thirdtitle: 'planning.thirdtitle',
  // parentUrl: '/services',
  // secondParentUrl: (id) => `/residences/edit/${id.substr(37, 45)}`,
  url: (id) => `/services-residence/edit/${id}`,
  query: ResidenceService?.getResidenceServicesById,
  transformQuery: (items) => items.residenceService.residenceByIdResidence.name,
  secondQuery: ResidenceService.getServiceNameAndResidenceName,
  transformSecondQuery: (items) => items?.residenceService?.serviceByIdService?.name,
  redirectionAfterUpdate: false,
  update: {
    title: 'planning.label.update',
    getLink: (item) => item.id,
    form: [
      {
        id: 'service',
        type: 'text',
        label: 'planning.label.service',
        readOnly: true,
      },
      {
        id: 'residence',
        type: 'text',
        label: 'planning.label.residence',
        readOnly: true,
      },
      {
        id: 'status',
        type: 'select',
        defaultValue: 'ui.defaultValue',
        model: Status,
        placeholder: 'planning.placeholder.status',
        label: 'planning.label.status',
        required: true,
      },
      {
        id: 'cible',
        type: 'checkboxes',
        defaultValue: 'ui.defaultValue',
        data: [
          { value: 'resident', label: 'Residents' },
          { value: 'external', label: 'Non résidents' },
        ],
        placeholder: 'planning.placeholder.cible',
        label: 'planning.label.cible',
        required: true,
      },
      {
        id: 'availabilityMode',
        type: 'radios',
        defaultValue: 'ui.defaultValue',
        data: [
          { value: 'PERMANENT', label: 'Permanent' },
          { value: 'TIMERANGE', label: 'Ponctuel' },
          { value: 'PERIODICAL', label: 'Périodique' },
        ],
        placeholder: 'planning.placeholder.mode',
        label: 'planning.label.mode',
        required: true,
      },
      {
        id: 'times',
        type: 'custom',
        component: (props) => (<PlanningTime {...props} />),
      },
      {
        id: 'delay',
        type: 'custom',
        component: (props) => (<PreparationDelay {...props} />),
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'planning.label.submit',
            md: 2,
          },
        ],
      },
    ],
    query: PlanningGql.getPlanning,
    variable: (params) => {
      const ids = params.id.split('|')
      return {
        id: ids[0],
      }
    },
    transformQuery: (item) => {
      const { residenceService } = item
      const cible = []
      if (residenceService.isForExternal) {
        cible.push('external')
      }
      if (residenceService.isForResident) {
        cible.push('resident')
      }

      let { prepareDelay } = residenceService
      const day = Math.floor(prepareDelay / (24 * 60))
      prepareDelay -= day * 24 * 60
      const hour = Math.floor(prepareDelay / 60)
      const min = prepareDelay - hour * 60

      const timeSlot = []
      residenceService.residenceServiceAvailabilityRangesByIdResidenceService?.nodes.forEach(
        (slot) => {
          const repeat = []
          let { repeatParameterValue } = slot
          if (slot.serviceRepeatUnitByIdRepeatUnit?.name === 'week') {
            if (repeatParameterValue&64) {
              repeat.push('monday')
            }
            if (repeatParameterValue&32) {
              repeat.push('tuesday')
            }
            if (repeatParameterValue&16) {
              repeat.push('wednesday')
            }
            if (repeatParameterValue&8) {
              repeat.push('thursday')
            }
            if (repeatParameterValue&4) {
              repeat.push('friday')
            }
            if (repeatParameterValue&2) {
              repeat.push('saturday')
            }
            if (repeatParameterValue&1) {
              repeat.push('sunday')
            }
            repeatParameterValue = 0
          }
          timeSlot.push({
            start: slot.startDatetime,
            end: slot.endDatetime,
            id: slot.id,
            availabilityMode: residenceService.availabilityMode,
            repeat_value: slot.repeatValue,
            repeat_parameter_value: repeatParameterValue,
            repeat_unit: slot.serviceRepeatUnitByIdRepeatUnit?.name,
            repeat_parameter: slot.serviceRepeatParameterByIdRepeatParameter?.name,
            repeat,
          })
        },
      )
      return {
        ...residenceService,
        cible,
        service: residenceService.serviceByIdService.name,
        residence: residenceService.residenceByIdResidence.name,
        status: residenceService.serviceStatusByIdServiceStatus.id,
        delay: {
          day: `${day}`,
          hour: `${hour}`,
          min: `${min}`,
        },
        initialData: item,
        times: {
          start: residenceService.startDatetime ? new Date(residenceService.startDatetime) : null,
          end: residenceService.endDatetime ? new Date(residenceService.endDatetime) : null,
          timeStartH: residenceService.startDatetime ? dayjs(residenceService.startDatetime).format('HH') : '',
          timeStartM: residenceService.startDatetime ? dayjs(residenceService.startDatetime).format('mm') : '',
          timeEndH: residenceService.endDatetime ? dayjs(residenceService.endDatetime).subtract(1, 'h').format('HH') : '',
          timeEndM: residenceService.endDatetime ? dayjs(residenceService.endDatetime).format('mm') : '',
          timeSlot,
        },
      }
    },
    submitQuery: (data, index) => {
      const rqst = [ResidenceService.updateResidenceServiceForPlanning]

      const toDelete = []
      const toAdd = []
      data.times?.timeSlot?.forEach((slot) => {
        if (slot.type === 'added') {
          toAdd.push(slot)
        } else if ((slot.type === 'removed' || slot.availabilityMode !== data.availabilityMode) && slot.id) {
          toDelete.push(slot)
        }
      })

      toDelete.forEach(() => {
        rqst.push(PlanningGql.deleteResidenceServiceAvailabilityRange)
      })

      toAdd.forEach(() => {
        rqst.push(PlanningGql.createResidenceServiceAvailabilityRange)
      })

      if (rqst.length > index) {
        return rqst[index]
      }
    },
    submit: (id, data, indexQuery, params) => {
      const ids = params.id.split('|')
      const day = parseInt(data.delay?.day, 10)
      const hour = parseInt(data.delay?.hour, 10)
      const min = parseInt(data.delay?.min, 10)

      let startDatetime
      let endDatetime

      if (data.times?.noHour || !data.times?.timeStartM || !data.times?.timeEndM) {
        startDatetime = `${dayjs(data.times?.start).format('YYYY-MM-DD')}T00:00:00+01:00`
        endDatetime = `${dayjs(data.times?.end).format('YYYY-MM-DD')}T00:00:00+01:00`
      } else if (
        data.times?.timeStartH &&
        data.times?.timeStartM &&
        data.times?.timeEndH &&
        data.times?.timeEndM
      ) {
        startDatetime = `${dayjs(data.times?.start).format('YYYY-MM-DD')}T${data.times?.timeStartH}:${data.times?.timeStartM}:00+01:00`
        endDatetime = `${dayjs(data.times?.end).format('YYYY-MM-DD')}T${data.times?.timeEndH}:${data.times?.timeEndM}:00+01:00`
      } else {
        return
      }

      if (indexQuery === 0) {
        return {
          id: ids[0],
          idServiceStatus: data.status,
          availabilityMode: data.availabilityMode,
          isForExternal: data.cible?.indexOf('external') !== -1,
          isForResident: data.cible?.indexOf('resident') !== -1,
          prepareDelay: (day * 24 + hour) * 60 + min,
          startDatetime: data.availabilityMode === 'PERMANENT' ? startDatetime : null,
          endDatetime: data.availabilityMode === 'PERMANENT' ? endDatetime : null,
        }
      }

      const toDelete = []
      const toAdd = []
      data.times?.timeSlot?.forEach((slot) => {
        if (slot.type === 'added') {
          toAdd.push(slot)
        } else if ((slot.type === 'removed' || slot.availabilityMode !== data.availabilityMode) && slot.id) {
          toDelete.push(slot)
        }
      })

      if (indexQuery < toDelete.length + 1) {
        return ({
          id: toDelete[indexQuery - 1].id,
        })
      }

      if (indexQuery < toDelete.length + toAdd.length + 1) {
        const slot = toAdd[indexQuery - 1 - toDelete.length]
        let repeatParameterValue = parseInt(slot.repeat_parameter_value, 10)
        if (slot.repeat_unit === 'week') {
          repeatParameterValue = 0
          slot.repeat.forEach((sem) => {
            switch (sem) {
              case 'monday':
                repeatParameterValue += 64
                break
              case 'tuesday':
                repeatParameterValue += 32
                break
              case 'wednesday':
                repeatParameterValue += 16
              break
              case 'thursday':
                repeatParameterValue += 8
                break
              case 'friday':
                repeatParameterValue += 4
                break
              case 'saturday':
                repeatParameterValue += 2
                break
              case 'sunday':
                repeatParameterValue += 1
                break
              default:
                break
            }
          })
        } else if (slot.repeat_unit === 'year') {
          repeatParameterValue = null
        }

        const valueToReturn = {
          idResidenceService: ids[0],
          startDatetime: slot.start,
          endDatetime: slot.end,
        }

        if (data.availabilityMode === 'PERIODICAL') {
          valueToReturn.repeatValue = parseInt(slot.repeat_value, 10)
          valueToReturn.idRepeatUnit = data.initialData.serviceRepeatUnits.nodes.find(
            (unit) => unit.name === slot.repeat_unit,
          ).id
          if (slot.repeat_unit === 'month') {
            valueToReturn.idRepeatParameter = data.initialData.serviceRepeatParameters.nodes.find(
              (param) => param.name === slot.repeat_parameter,
            ).id
          }
          valueToReturn.repeatParameterValue = repeatParameterValue
        }
        return valueToReturn
      }
    },
  },
}
