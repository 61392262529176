import config from '../config'
import { Faq as FaqGraphql } from '../gql'

const { LIMIT } = config

export const Faq = {
  graphql: true,
  title: 'faq.title',
  url: '/faq',
  name: 'faq',
  list: {
    title: 'faq.title',
    table: [
      {
        head: 'faq.label.question',
        key: 'question',
        width: '40%',
      },
      {
        head: 'faq.label.answer',
        key: 'answer',
        width: '40%',
      },
    ],
    query: FaqGraphql.fetchFaqs,
    variables: (limit, offset) => ({
      first: limit,
      offset,
    }),
    // search: {
    //   component: (props) => <SearchOrderedByResidence {...props} />,
    //   placeholder: 'clients.placeholder.residence',
    //   submitLabel: 'residences.label.submit',
    //   query: FaqGraphql.fetchFaqs,
    //   id: 'idResidence',
    // },
    transformQuery: (items) =>
      items?.faqs?.nodes.map((node) => ({
        ...node,
        question: node.question,
        answer: node.answer,
      })),
    hasPreviousPage: (data) => data?.faqs?.pageInfo?.hasPreviousPage,
    hasNextPage: (data) => data?.faqs?.pageInfo?.hasNextPage,
    totalPage: (data) => Math.ceil(data?.faqs?.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    title: 'faq.create.title',
    form: [
      {
        id: 'question',
        type: 'text',
        placeholder: 'faq.label.questionLabel',
        label: 'faq.label.questionLabel',
        defaultValue: 'faq.label.questionLabel',
        required: true,
      },
      {
        id: 'answer',
        type: 'text',
        placeholder: 'faq.label.answerLabel',
        label: 'faq.label.answerLabel',
        defaultValue: 'faq.label.answerLabel',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'faq.create.submit',
            md: 2,
          },
        ],
      },
    ],
    submitQuery(data, index) {
      if (index === 0) {
        return FaqGraphql.createFaq
      }
    },
    getIdAfterSubmit: (data) => data.createdId,
    submit: (data, params, index) => {
      if (index === 0) {
        return {
          question: data.question,
          answer: data.answer,
          pos: data.pos,
        }
      }
    },
  },
  update: {
    getLink: (item) => item.id,
    form: [
      {
        id: 'question',
        type: 'text',
        placeholder: 'faq.label.questionLabel',
        label: 'faq.label.questionLabel',
        defaultValue: 'faq.label.questionLabel',
        required: true,
      },
      {
        id: 'answer',
        type: 'text',
        placeholder: 'faq.label.answerLabel',
        label: 'faq.label.answerLabel',
        defaultValue: 'faq.label.answerLabel',
        required: true,
      },
      {
        type: 'row',
        right: true,
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'faq.create.submit',
            md: 2,
          },
        ],
      },
    ],
    variables: (id) => ({
      id,
    }),
    query: FaqGraphql.fetchOneFaq,
    transformQuery: (item) => ({
      question: item.faq.question,
      answer: item.faq.answer,
    }),

    submitQuery: (data, index) => {
      if (index === 0) {
        return FaqGraphql.updateFaq
      }
      return null
    },
    submit: (id, data) => ({
      id,
      question: data.question,
      answer: data.answer,
    }),
  },
  read: {
    form: [
      {
        id: 'question',
        type: 'text',
        placeholder: 'faq.label.questionLabel',
        label: 'faq.label.questionLabel',
        readOnly: true,
      },
      {
        id: 'answer',
        type: 'text',
        placeholder: 'faq.label.answerLabel',
        label: 'faq.label.answerLabel',
        readOnly: true,
      },
      {
        id: 'pos',
        type: 'text',
        placeholder: 'faq.label.posLabel',
        label: 'faq.label.posLabel',
        readOnly: true,
      },
    ],
    getLink: (item) => item.id,
    variables: (id) => ({
      id,
    }),
    query: FaqGraphql.fetchOneFaq,
    transformQuery: (item) => ({
      question: item.faq.question,
      answer: item.faq.answer,
      pos: item.faq.pos,
    }),
  },
  delete: {
    text: 'faq.delete.label',
    getTitle: (item) => item.question,
    query: FaqGraphql.deleteFaq,
  },
}
