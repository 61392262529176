/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Row } from 'shards-react'
import { useParams } from 'react-router-dom'

import PageTitle from '../common/PageTitle'
import Breadcrumb from '../common/Breadcrumb'
import config from '../../config'
import { captureMessageWithAttachment } from '../../lib/sentry'
import { Read } from './form'
import UserProvider from '../../gql/UserProvider'

const AutomatedUpdate = ({ model, custom }) => {
  const { t } = useTranslation()
  const { id, role } = useParams()
  const [parentTitle, setParentTitle] = useState('')
  const [itemLabel, setItemLabel] = useState('')
  const [secondItemLabel, setSecondItemLabel] = useState('')
  const [secondItemLabelText, setSecondItemLabelText] = useState('')
  const [mainId, setMainId] = useState('')
  const [, setSubId] = useState('')
  const [customUrl, setCustomUrl] = useState({
    url: '',
  })

  const roleId = config.ROLES_ID

  useEffect(() => {
    if (id > 0 && model.parentModel) {
      model.parentModel(id).then((parent) => {
        setParentTitle(parent)
      })
    } else if (role > 0) {
      setParentTitle(t(`${model.list.title}.${role}`))
    } else if (custom.roleId > 0) {
      switch (custom.roleId) {
        case +roleId.logifim_admin:
          setParentTitle(t(`${model.list.title}.logifim_admin`))
          setCustomUrl({ url: '/admin' })
          break
        case +roleId.logifim_coordinator:
          setParentTitle(t(`${model.list.title}.logifim_coordinator`))
          setCustomUrl({ url: '/coordinator' })
          break
        case +roleId.logifim_service_provider:
          setParentTitle(t(`${model.list.title}.logifim_service_provider`))
          setCustomUrl({ url: '/provider' })
          break
        default:
          setParentTitle('')
      }
    } else if (custom.path === '/provider') {
      setParentTitle(t(`${model.list.title}.logifim_service_provider`))
      setCustomUrl({ url: '/provider' })
    }
  }, [id])

  let fetchGrahQlItem = () => {}
  try {
    const fetchItem = useLazyQuery(custom.path === '/provider' ? UserProvider.getUserProviderById : model.query, {
      fetchPolicy: config.fetchPolicy,
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      variables: {
        id: mainId,
      },
      onError: (err) => {
        const request = model.test
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: {
            id: mainId,
          },
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (response) => {
        setItemLabel(model.transformQuery(response))
      },
    })
    fetchGrahQlItem = fetchItem[0]
  } catch (e) {
    console.log(e)
  }

  let fetchSecondGrahQlItem = () => {}
  try {
    const fetchItem = useLazyQuery(model.secondQuery, {
      fetchPolicy: config.fetchPolicy,
      notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
      variables: {
        id: mainId,
      },
      onError: (err) => {
        const request = model.test
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables: {
            id: mainId,
          },
        }
        captureMessageWithAttachment(event, err)
      },
      onCompleted: (response) => {
        setSecondItemLabel(model.transformSecondQuery(response))
      },
    })
    fetchSecondGrahQlItem = fetchItem[0]
  } catch (e) {
    console.log(e)
  }

  useEffect(() => {
    if (id.includes('|')) {
      setMainId(id.substr(0, 36))
      setSubId(id.substr(37, 45))
    } else {
      setMainId(id)
    }
    if (model.query) {
      fetchGrahQlItem()
    }
    if (model.secondTitle) {
      setSecondItemLabelText(model.secondTitle)
    } else if (model.secondQuery) {
      fetchSecondGrahQlItem()
    }
  }, [])

  return (
    <>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        {model.subtitle && (
          <PageTitle
            sm="6"
            subtitle={t(model.subtitle)}
            className="text-sm-left"
          />
        )}
        <Breadcrumb
          crumbs={[
            {
              label: `${t(model.title)}${parentTitle}`,
              link: model.parentUrl
                ? model.parentUrl
                : customUrl.url
                ? customUrl.url
                : model.url && typeof model.url === 'function'
                ? model.url(id)
                : model.url,
            },
            {
              label: itemLabel,
              link: model.secondParentUrl
                ? model.secondParentUrl &&
                  typeof model.secondParentUrl === 'function'
                  ? model.secondParentUrl(id)
                  : model.secondParentUrl
                : model.url && typeof model.url === 'function'
                ? model.url(id)
                : model.url,
            },
            {
              label: secondItemLabel || t(secondItemLabelText),
              link:
                model.parentUrl && model.secondParentUrl && model.url
                  ? model.url && typeof model.url === 'function'
                    ? model.url(id)
                    : model.url
                  : null,
            },
            { label: t(model.thirdtitle) },
          ]}
        />
      </Row>

      <Read {...{ model, id, custom }} />
    </>
  )
}

AutomatedUpdate.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  custom: PropTypes.objectOf(PropTypes.any),
}

AutomatedUpdate.defaultProps = {
  custom: () => {},
}

export default AutomatedUpdate
