import React, { useEffect, useState } from 'react'
import {
  Button,
  Card, CardBody,
  Col, Row,
} from 'shards-react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'
import { List } from '../form'
import config from '../../../config'
import { Ressources } from '../../../gql'
import { ListPlanningForRessources } from '../../../models'
import { captureMessageWithAttachment } from '../../../lib/sentry'

const RessourcePlanningList = () => {
  const { t } = useTranslation()
  const [planningData, setPlanningData] = useState([])
  const history = useHistory()
  const { id } = useParams()

  const [fetchRessourcePlanning] = useLazyQuery(Ressources.getRessourcePlanning, {
    variables: {
      idResource: id,
    },
    onError: (err) => {
      const request = Ressources.getRessourcePlanning
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          idResource: id,
        },
      }
      captureMessageWithAttachment(event, err)
    },
    fetchPolicy: config.fetchPolicy,
    notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
    onCompleted: (data) => {
      setPlanningData(data.resourceBookings.nodes)
    },
  })

  useEffect(() => {
    fetchRessourcePlanning()
  }, [])

  const redirectToAddRessourcePlanning = () => {
    history.push(`/ressource-planning/${id}/create`)
  }

  return (
    <Card small className="mb-4">
      <CardBody className="p-0 pb-3">
        <Row>
          <Col md={7}>
            {planningData ? (
              <List {...{
                  model: ListPlanningForRessources,
                  dataToShow: planningData,
                  noHeader: true,
                  noFooter: true,
                }}
              />
              ) : (
                <p>{t('ressources.nothingtoshow')}</p>
              )}
            <Button
              style={{ width: '175px', backgroundColor: '#32A0E6', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none' }}
              onClick={redirectToAddRessourcePlanning}
            >
              <p className="p-0 m-0 font-weight-bold">{t('ressources.addRessourcePlanning')}</p>
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>

  )
}

export default RessourcePlanningList
