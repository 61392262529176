import { gql } from '@apollo/client'

const fetchFaqs = gql`
  query faqs($offset: Int, $first: Int) {
    faqs(offset: $offset, first: $first) {
      nodes {
        id
        question
        answer
        pos
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const createFaq = gql`
  mutation createFAQ($question: String!, $answer: String!, $pos: Int!) {
    createFaq(
      input: { faq: { question: $question, pos: $pos, answer: $answer } }
    ) {
      clientMutationId
    }
  }
`

const fetchOneFaq = gql`
  query faq($id: UUID!) {
    faq(id: $id) {
      question
      answer
      id
      pos
    }
  }
`

const updateFaq = gql`
  mutation updateFAQ(
    $id: UUID!
    $answer: String
    $question: String
    $pos: Int
  ) {
    updateFaq(
      input: {
        patch: { answer: $answer, question: $question, pos: $pos }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const deleteFaq = gql`
  mutation deleteFAQ($id: UUID!) {
    deleteFaq(input: { id: $id }) {
      clientMutationId
    }
  }
`

const fetchLastPos = gql`
  query fetchLastPos {
    faqs(first: 1, orderBy: POS_DESC) {
      nodes {
        id
        pos
      }
    }
  }
`

export default {
  fetchFaqs,
  createFaq,
  fetchOneFaq,
  updateFaq,
  deleteFaq,
  fetchLastPos,
}
