/* eslint-disable react/display-name */
import config from '../config'
import { Catalogs } from '../gql'
import { Articles } from './Articles'

const { LIMIT } = config

export const Catalogues = {
  graphql: true,
  title: 'catalogues.title',
  query: Catalogs.getCatalogById,
  transformQuery: (items) => items.catalog.label,
  url: '/catalogues',
  populateForm: (data) => {
    const cat = []
    data.catalogs.nodes.forEach((category) => {
      cat.push({ value: category.id, label: category.label })
    })
    return cat
  },
  submodules: [
    {
      url: '/catalogues/:id/articles',
      model: Articles,
    },
  ],
  list: {
    title: 'catalogues.list',
    table: [
      {
        head: 'catalogues.label.label',
        key: 'label',
        width: '25%',
      },
      {
        head: 'catalogues.label.ref',
        key: 'ref',
        width: '25%',
      },
      {
        head: 'catalogues.label.articles',
        key: 'episodes',
        type: 'more',
        link: (id) => `${config.REACT_APP_BASENAME}catalogues/${id}/articles`,
        text: 'catalogues.seeArticles',
        width: '25%',
      },
    ],
    query: Catalogs.fetchCatalogs,
    variables: (limit, offset) => ({
      first: limit,
      offset,
    }),
    transformQuery: (items) => items.catalogs.nodes,
    hasPreviousPage: (data) => data.catalogs.pageInfo.hasPreviousPage,
    hasNextPage: (data) => data.catalogs.pageInfo.hasNextPage,
    totalPage: (data) => Math.ceil(data.catalogs.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    title: 'catalogues.label.create',
    url: (id) => `/catalogues/${id}/articles`,
    form: [
      {
        id: 'label',
        type: 'text',
        placeholder: 'catalogues.placeholder.title',
        label: 'catalogues.label.title',
        required: true,
      },
      {
        id: 'ref',
        type: 'text',
        placeholder: 'catalogues.placeholder.ref',
        label: 'catalogues.label.ref',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'catalogues.label.submit',
            md: 2,
          },
        ],
      },
    ],
    getIdAfterSubmit: (data) => data.createCatalog.catalog.id,
    submitQuery: (data, index) => {
      if (index === 0) {
        return Catalogs.createCatalog
      }
    },
    submit: (data) => ({
      label: data.label,
      ref: data.ref,
    }),
  },
  update: {
    title: 'catalogues.label.update',
    getLink: (item) => item.id,
    query: Catalogs.getCatalogById,
    transformQuery: (item) => item.catalog,
    submitQuery: (data, index) => {
      if (index === 0) {
        return Catalogs.updateCatalog
      }
      return null
    },
    submit: (id, data) => ({
      id,
      label: data.label,
      ref: data.ref,
    }),
  },
  read: {
    form: [
      {
        id: 'label',
        type: 'text',
        placeholder: 'catalogues.placeholder.title',
        label: 'catalogues.label.title',
      },
      {
        id: 'ref',
        type: 'text',
        placeholder: 'catalogues.placeholder.ref',
        label: 'catalogues.label.ref',
      },
    ],
    getLink: (item) => item.id,
    query: Catalogs.getCatalogById,
    transformQuery: (i) => ({
      ...i.serviceCategory,
      label: i.catalog.label,
      ref: i.catalog.ref,
    }),
  },
  delete: {
    text: 'catalogues.delete.confirm',
    query: Catalogs.deleteCatalog,
    getTitle: (item) => `${item.label}`,
  },
}
