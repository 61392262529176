import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { CardSubtitle } from 'shards-react'
import { CatalogArticles } from '../../../gql'
import { List } from '../form'
import { OrderArticles as ModelOrderArticles } from '../../../models'
import config from '../../../config'

const OrderArticles = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [articles, setArticles] = useState([])
  const [amount, setAmount] = useState(0)

  const [fetchArticles] = useLazyQuery(CatalogArticles.getArticleByOrderId, {
    fetchPolicy: config.fetchPolicy,
    notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
    variables: {
      id,
    },
    onCompleted: (data) => {
      const items = [...data.residenceServiceBooking.orderArticlesByIdOrder.nodes]
      setArticles(items.map((item) => ({
        ...item,
        label: item.articleByIdArticle.label,
        unit_price: `${item.articleByIdArticle.unitPricesByIdArticle.nodes[0]?.value} €`,
      })))
      setAmount(data.residenceServiceBooking.totalAmount)
    },
  })

  useEffect(() => {
    fetchArticles()
  }, [])

  return (
    <div className="category-container">
      {articles.length > 0 && (
        <>
          <CardSubtitle>{t('services.label.orders')}</CardSubtitle>
          <List
            {...{
            model: ModelOrderArticles,
            dataToShow: articles,
            noHeader: true,
            noFooter: true,
            id,
            reload: () => {
              fetchArticles()
            },
          }}
          />
          <p className="font-weight-bold">{`${t('services.label.total')}: ${amount} €`}</p>
        </>
      )}
    </div>
  )
}

export default OrderArticles
