/* eslint-disable react/display-name */
import { ResidenceService } from '../gql'

export const PlanningManagement = {
  graphql: true,
  title: 'planningManagement.title',
  thirdtitle: 'planningManagement.thirdtitle',
  // parentUrl: '/services',
  // secondParentUrl: (id) => `/residences/edit/${id.substr(37, 45)}`,
  url: (id) => `/services-residence/edit/${id}`,
  query: ResidenceService?.getResidenceServicesById,
  transformQuery: (items) => items.residenceService.residenceByIdResidence.name,
  secondQuery: ResidenceService.getServiceNameAndResidenceName,
  transformSecondQuery: (items) => items?.residenceService?.serviceByIdService?.name,
  redirectionAfterUpdate: false,
  update: {
    title: 'planningManagement.label.update',
    form: [
      {
        id: 'bookingLimit',
        type: 'text',
        placeholder: 'planningManagement.placeholder.limit',
        label: 'planningManagement.label.limit',
        required: true,
      },
      {
        id: 'bookingType',
        type: 'radios',
        defaultValue: 'ui.defaultValue',
        data: [
          { value: 'EVENT', label: 'Evenement' },
          { value: 'TIMERANGE', label: 'Crénau' },
        ],
        label: 'planningManagement.label.type',
        required: true,
      },
      {
        id: 'slotdelaylabel',
        type: 'hidden',
        label: 'planningManagement.label.delay',
      },
      {
        type: 'row',
        items: [
          {
            id: 'day',
            md: 'md-1',
            type: 'text',
            placeholder: 'planningManagement.placeholder.day',
            label: 'planningManagement.label.days',
            required: true,
          },
          {
            id: 'hour',
            md: 'md-3',
            type: 'text',
            placeholder: 'planningManagement.placeholder.hour',
            label: 'planningManagement.label.hours',
            required: true,
          },
          {
            id: 'min',
            md: 'md-3',
            type: 'text',
            placeholder: 'planningManagement.placeholder.min',
            label: 'planningManagement.label.minutes',
            required: true,
          },
        ],
      },
      {
        id: 'isAutomatedBooking',
        type: 'checkbox',
        defaultValue: 'ui.defaultValue',
        label: 'planningManagement.label.validation',
      },
      {
        id: 'isCommentedBooking',
        type: 'checkbox',
        defaultValue: 'ui.defaultValue',
        label: 'planningManagement.label.comment',
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'planningManagement.label.submit',
            md: 2,
          },
        ],
      },
    ],
    getLink: (item) => item.id,
    query: ResidenceService.getResidenceServicesForSetup,
    variable: (params) => {
      const ids = params.id.split('|')
      return {
        id: ids[0],
      }
    },
    transformQuery: (item) => {
      let { bookingLength } = item.residenceService
      const day = Math.floor(bookingLength / (24 * 60))
      bookingLength -= day * 24 * 60
      const hour = Math.floor(bookingLength / 60)
      const min = bookingLength - hour * 60

      return {
        ...item.residenceService,
        day: `${day}`,
        hour: `${hour}`,
        min: `${min}`,
        bookingLimit: item.residenceService.bookingLimit ? item.residenceService.bookingLimit : '0',
      }
    },
    submitQuery: (data, index) => {
      if (index === 0) {
        return ResidenceService.updateResidenceServiceForSetup
      }
    },
    submit: (id, data, indexQuery, params) => {
      const ids = params.id.split('|')
      if (indexQuery === 0) {
        const day = parseInt(data.day, 10)
        const hour = parseInt(data.hour, 10)
        const min = parseInt(data.min, 10)
        return ({
          id: ids[0],
          bookingLimit: parseInt(data.bookingLimit, 10),
          bookingType: data.bookingType,
          bookingLength: (day * 24 + hour) * 60 + min,
          isAutomatedBooking: data.isAutomatedBooking,
          isBookable: data.bookingLimit !== '0',
          isCommentedBooking: data.isCommentedBooking,
        })
      }
    },
  },
}
