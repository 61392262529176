/* eslint-disable react/display-name */
import config from '../config'
import { Contact } from '../gql'

const { LIMIT } = config

export const ContactReasons = {
  graphql: true,
  title: 'contactReasons.title',
  query: Contact.fetchContactReason,
  transformQuery: (items) => items.internalReason.label,
  url: '/contact-reasons',
  list: {
    title: 'contactReasons.list',
    table: [
      {
        head: 'contactReasons.label.label',
        key: 'label',
        width: '25%',
      },
    ],
    query: Contact.fetchContactReasons,
    variables: (limit, offset) => ({
      first: limit,
      offset,
    }),
    transformQuery: (items) => items.internalReasons.nodes,
    hasPreviousPage: data => data.internalReasons.pageInfo.hasPreviousPage,
    hasNextPage: data => data.internalReasons.pageInfo.hasNextPage,
    totalPage: data => Math.ceil(data.internalReasons.totalCount / LIMIT),
    limit: LIMIT,
  },
  create: {
    title: 'contactReasons.label.create',
    form: [
      {
        id: 'label',
        type: 'text',
        placeholder: 'contactReasons.placeholder.title',
        label: 'contactReasons.label.title',
        required: true,
      },
      {
        type: 'row',
        items: [
          {
            type: 'back',
            label: 'ui.cancel',
            md: 2,
          },
          {
            type: 'submit',
            label: 'contactReasons.label.submit',
            md: 2,
          },
        ],
      },
    ],
    getIdAfterSubmit: (data) => data.createInternalReason.internalReason.id,
    submitQuery: (data, index) => {
      if (index === 0) {
        return Contact.createContactReason
      }
    },
    submit: (data) => ({
      label: data.label,
    }),
  },
  update: {
    title: 'contactReasons.label.update',
    getLink: (item) => item.id,
    query: Contact.fetchContactReason,
    transformQuery: (item) => item.internalReason,
    submitQuery: (data, index) => {
      if (index === 0) {
        return Contact.updateContactReason
      }
      return null
    },
    submit: (id, data) => ({
      id,
      label: data.label,
    }),
  },
  delete: {
    text: 'catalogues.delete.confirm',
    query: Contact.deleteContactReason,
    getTitle: (item) => `${item.label}`,
    errorMessage: 'catalogues.delete.error',
  },
}
