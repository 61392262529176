import { useLazyQuery, useMutation } from '@apollo/client'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useHistory, useParams } from 'react-router-dom'
import {
  Card, CardBody, CardHeader, Col, Form,
  FormGroup, ListGroup,
  ListGroupItem, Row,
} from 'shards-react'

import config from '../../../config'
import UserProvider from '../../../gql/UserProvider'
import {
  couldSendForm, makeMyInput as makeMyInputLib, populateFormAfterApi,
  userCanDoAction,
} from '../../../lib'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import AutomatedModalConfirmation from '../custom/AutomatedModalConfirmation'
import { customStyles } from './style'

const AutomatedUpdate = (props) => {
  const { model, custom } = props
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [validField, setValidField] = useState({})
  const [formSubmit, setFormSubmit] = useState(false)
  const [error, setError] = useState([])
  const [loadingExtra, setLoadingExtra] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [actionInModal, setActionInModal] = useState()
  const [indexQuery, setIndexQuery] = useState(0)
  const [submitedQuery, setSubmitedQuery] = useState(false)

  const params = useParams()
  const { id, subId } = params

  // used to popupate select, radio, checkbox when data is from api
  const [populatedForm, setPopulatedForm] = useState([])
  const formFromModel = model.update.form || model.create.form

  const history = useHistory()

  const loadDataAsync = async (dataFromGraphQl) => {
    const form = {
      ...populatedForm,
    }

    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.model) {
            let dataToPopulate
            if (config.CONNECTOR === 'graphql' && field.model.graphql) {
              dataToPopulate = field.model.populateForm(dataFromGraphQl)
            } else {
              dataToPopulate = await field.model.populateForm(i18n.language)
            }
            form[field.id] = dataToPopulate
            setPopulatedForm(form)
          }
        })
      } else if (row.model) {
        let dataToPopulate
        if (config.CONNECTOR === 'graphql' && model.graphql) {
          dataToPopulate = row.model.populateForm(dataFromGraphQl)
        } else {
          dataToPopulate = await row.model.populateForm(i18n.language)
        }
        form[row.id] = dataToPopulate
        setPopulatedForm(form)
      }
    })
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const submitModel = () => {
    let loading = 0
    const err = [...error]
    formFromModel.forEach(async (row) => {
      if (row.type === 'row') {
        row.items.forEach(async (field) => {
          if (field.needToSubmit) {
            try {
              loading += 1
              setLoadingExtra(loading)
              await field.needToSubmit(id, data[field.id], subId)
            } catch (e) {
              console.log(e)
              err.push(`ui.custom.${field.id}`)
              setError(err)
            } finally {
              loading -= 1
              setLoadingExtra(loading)
            }
          }
        })
      } else if (row.needToSubmit) {
        try {
          loading += 1
          setLoadingExtra(loading)
          await row.needToSubmit(id, data[row.id], subId)
        } catch (e) {
          console.log(e)
          err.push(`ui.custom.${row.id}`)
          setError(err)
        } finally {
          loading -= 1
          setLoadingExtra(loading)
        }
      }
    })
    if (model.graphql && config.CONNECTOR === 'graphql') {
      setLoadingExtra(0)
    }
  }

  useEffect(() => {
    let redirect
    if (model.redirectionAfterUpdate === true) {
      redirect = true
    } else if (model.redirectionAfterUpdate === false) {
      redirect = false
    } else {
      redirect = true
    }
    if (formSubmit && model.title === 'planningManagement.title') {
      history.push({
        pathname: model.url(id, params, props),
        state: { update: true },
      })
    }

    if (submitedQuery && model.title === 'planning.title') {
      history.goBack()
    }

    if (formSubmit && loadingExtra === 0 && redirect) {
      history.push({
        pathname:
          model.url && typeof model.url === 'function'
            ? model.url(id, params, props)
            : model.url,
        state: { update: true },
      })
    }
  }, [loadingExtra, model])

  let submitGraphQl = () => {}
  let mutationLoading
  let mutationError

  try {
    const submitGQL = useMutation(
      custom?.path === '/provider'
        ? UserProvider.updateExternal
        : model.update.submitQuery(data, indexQuery),
      {
        variables: model.update.submit(id, data, indexQuery, params, props),
        onError: (err) => {
          const request = model.update.submitQuery(data, indexQuery)
          const event = {
            request: request?.definitions[0]?.name?.value,
            variables: model.update.submit(id, data, indexQuery, params),
          }
          captureMessageWithAttachment(event, err)
        },
        onCompleted: () => {
          if (custom?.path === '/provider') {
            setIndexQuery((prev) => prev + 1)
          }
          if (model.update.submitQuery(data, indexQuery + 1)) {
            setIndexQuery((prev) => prev + 1)
          } else {
            submitModel()
          }
          setSubmitedQuery(true)
        },
      },
    )
    submitGraphQl = submitGQL[0]
    mutationLoading = submitGQL[1].loading
    mutationError = submitGQL[1].error
  } catch (e) {
    console.log({ error: e })
  }

  useEffect(() => {
    if (indexQuery !== 0) {
      submitGraphQl()
    }
  }, [indexQuery])

  let fetchGrahQlItem = () => {}
  try {
    const fetchItem = useLazyQuery(
      custom?.path === '/provider'
        ? UserProvider.getUserProviderById
        : model.update.query,
      {
        fetchPolicy: config.fetchPolicy,
        notifyOnNetworkStatusChange: config.notifyOnNetworkStatusChange,
        variables: model.update.variable
          ? model.update.variable(params)
          : {
              id,
            },
        onError: (err) => {
          const request = model.update.query
          const event = {
            request: request?.definitions[0]?.name?.value,
            variables: model.update.variable
              ? model.update.variable(params)
              : {
                  id,
                },
          }
          captureMessageWithAttachment(event, err)
        },
        onCompleted: (itemToUpdate) => {
          populateFormAfterApi(
            custom?.path === '/provider'
              ? itemToUpdate.external
              : model.update.transformQuery(itemToUpdate),
            data,
            formFromModel,
            setData,
          )
          loadDataAsync(itemToUpdate)
        },
      },
    )
    fetchGrahQlItem = fetchItem[0]
  } catch (e) {
    console.log(e)
  }

  const loadItemAsync = async () => {
    if (config.CONNECTOR === 'graphql' && model.graphql) {
      fetchGrahQlItem()
    } else {
      const item = await model.update.getItem(id, i18n.language, subId)
      populateFormAfterApi(item, data, formFromModel, setData)
    }
  }

  useEffect(() => {
    if (
      model.update.authorizedRole &&
      !userCanDoAction(model.update.authorizedRole)
    ) {
      history.push({
        pathname:
          model.url && typeof model.url === 'function'
            ? model.url(id, params)
            : model.url,
        state: { noAccess: true },
      })
    }

    if (config.CONNECTOR !== 'graphql' && !model.graphql) {
      loadDataAsync()
    }
    loadItemAsync()
  }, [])

  const submitForm = async (event) => {
    event.preventDefault()
    setFormSubmit(true)
    if (couldSendForm(validField, formFromModel, data, setValidField)) {
      if (config.CONNECTOR === 'graphql') {
        setLoadingExtra(1)
        submitGraphQl()
      } else {
        await model.update.submit(id, data, i18n.language, history, subId)

        let loading = 0
        let noExtra = true
        const err = [...error]
        formFromModel.forEach(async (row) => {
          if (row.type === 'row') {
            row.items.forEach(async (field) => {
              if (field.needToSubmit) {
                noExtra = false
                try {
                  loading += 1
                  setLoadingExtra(loading)
                  await field.needToSubmit(id, data[field.id], subId)
                } catch (e) {
                  console.log(e)
                  err.push(`ui.custom.${field.id}`)
                  setError(err)
                } finally {
                  loading -= 1
                  setLoadingExtra(loading)
                }
              }
            })
          } else if (row.needToSubmit) {
            noExtra = false
            try {
              loading += 1
              setLoadingExtra(loading)
              await row.needToSubmit(id, data[row.id], subId)
            } catch (e) {
              console.log(e)
              err.push(`ui.custom.${row.id}`)
              setError(err)
            } finally {
              loading -= 1
              setLoadingExtra(loading)
            }
          }

          if (noExtra) {
            history.push({
              pathname:
                model.url && typeof model.url === 'function'
                  ? model.url(id, params)
                  : model.url,
              state: { update: true },
            })
          }
        })
      }
    }
  }

  const makeMyInput = (field) =>
    makeMyInputLib(
      model,
      field,
      data,
      setData,
      validField,
      setValidField,
      formSubmit,
      submitForm,
      populatedForm,
      t,
      error,
      history,
      id,
      setIsOpen,
      setActionInModal,
      mutationLoading,
      loadingExtra,
      mutationError,
      params,
      props,
    )

  return (
    <>
      <Row>
        <Col>
          <Card small className="mb-4" style={{ boxShadow: 'none' }}>
            {model.update.title && (
              <CardHeader className="border-bottom">
                <h6 className="m-0">{t(model.update.title)}</h6>
              </CardHeader>
            )}
            <CardBody className="p-0 pb-3">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        {formFromModel.map((item, indexRow) => (
                          <div key={`row${indexRow}`} style={item.rowCustom}>
                            {item.type === 'row' ? (
                              <>
                                {item.right ? (
                                  <Row
                                    style={{ ...customStyles.divButtons }}
                                    className="mr-1"
                                  >
                                    {item.items.map((field, index) => (
                                      <div className="p-1" key={`col${index}`}>
                                        {makeMyInput(field)}
                                      </div>
                                    ))}
                                  </Row>
                                ) : (
                                  <Row form>
                                    {item.items.map((field, index) => (
                                      <Col
                                        md={field.md}
                                        className="form-group"
                                        key={`col${index}`}
                                      >
                                        {makeMyInput(field)}
                                      </Col>
                                    ))}
                                  </Row>
                                )}
                              </>
                            ) : (
                              <FormGroup>{makeMyInput(item)}</FormGroup>
                            )}
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isOpen && (
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
          <AutomatedModalConfirmation
            {...{ item: actionInModal, id, data }}
            closeModal={closeModal}
          />
        </Modal>
      )}
    </>
  )
}

AutomatedUpdate.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  custom: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AutomatedUpdate
