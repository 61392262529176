import { gql } from '@apollo/client'

const fetchStaffs = gql`
  query getClients($first: Int, $offset: Int, $idRole: BigFloat) {
    staffs(first: $first, offset: $offset, filter: {idRole: {equalTo: $idRole}}) {
      nodes {
        id
        firstname
        lastname
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const fetchCoordinatorForManager = gql`
  query fetchCoordinatorForManager($first: Int, $offset: Int, $idRole: UUID!) {
    residences(first: $first, offset: $offset, condition: {idManager: $idRole}) {
      nodes {
        residenceCoordinationsByIdResidence {
          nodes {
            staffByIdCoordinator {
              id
              firstname
              lastname
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

const deleteStaff = gql`
  mutation deleteStaff($id: UUID!) {
    deleteStaff(
      input: {id: $id}
    ) {
      clientMutationId
    }
  }
`

const createStaff = gql`
  mutation createStaff(
    $firstname: String!,
    $lastname: String!,
    $mobilePhoneNumber: MobilePhoneNumberFr,
    $password: String!,
    $idRole: BigFloat!,
    $email: EmailAddress,
  ) {
    createStaff(input: {staff: {
      firstname: $firstname,
      lastname: $lastname,
      mobilePhoneNumber: $mobilePhoneNumber,
      password: $password,
      idRole: $idRole,
      email: $email,
    }}) {
      staff {
        id
      }
    }
  }
`

const getStaffById = gql`
  query getStaffById($id: UUID!) {
    staff(id: $id) {
      lastname
      firstname
      mobilePhoneNumber
      email
      id
    }
  }
`

const updateStaff = gql`
  mutation updateStaff(
    $firstname: String!,
    $lastname: String!,
    $email: EmailAddress,
    $mobilePhoneNumber: MobilePhoneNumberFr,
    $password: String,
    $id: UUID!,
  ) {
    updateStaff(input: {patch: {
      firstname: $firstname,
      lastname: $lastname,
      mobilePhoneNumber: $mobilePhoneNumber,
      email: $email,
      password: $password,
    }, id: $id}) {
      clientMutationId
    }
  }
`

export default {
  fetchStaffs,
  fetchCoordinatorForManager,
  deleteStaff,
  createStaff,
  getStaffById,
  updateStaff,
}
