import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import {
  Button,
  FormGroup,
} from 'shards-react'

const ResidenceServiceLinks = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()
  const style = {
    width: '300px',
    backgroundColor: '#32A0E6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    marginRight: '10px',
  }
  return (
    <div>
      <FormGroup>
        <Button
          style={style}
          onClick={() => {
            history.push({
              pathname: `/services-ressources/${id}`,
            })
          }}
        >
          <p className="p-0 m-0 font-weight-bold">{t('residences.links.resources')}</p>
        </Button>
      </FormGroup>
      <FormGroup style={{ display: 'flex' }}>
        <Button
          style={style}
          onClick={() => {
            history.push({
              pathname: `/planning/edit/${id}`,
            })
          }}
        >
          <p className="p-0 m-0 font-weight-bold">{t('residences.links.dispos')}</p>
        </Button>
        <Button
          style={style}
          onClick={() => {
            history.push({
              pathname: `/planning-management/edit/${id}`,
            })
          }}
        >
          <p className="p-0 m-0 font-weight-bold">{t('residences.links.manageDispo')}</p>
        </Button>
      </FormGroup>
      <FormGroup>
        <Button
          style={style}
          onClick={() => {
            history.push({
              pathname: `/register/${id}`,
            })
          }}
        >
          <p className="p-0 m-0 font-weight-bold">{t('residences.links.registers')}</p>
        </Button>
      </FormGroup>
    </div>
  )
}

export default ResidenceServiceLinks
